<template>
  <main class="view home" :class="{ '-loading': !isDataLoaded }"> <!-- :class="{ '-loading': !isDataLoaded }" -->

    <view-header :heading="_(title)">
      <template v-slot:title-column-content>
        <p class="lead" v-if="lead">{{ _(lead) | capitalise }}</p>
        <div class="language-selector">
          <label class="sr-only" for="appLanguageSetting">{{ _('language') | capitalise }}</label>

          <template v-for="(lang) in locales" >
            <button class="language-selector-btn"
            type="button"
            name="setLocaleButton"
            @click="setLocale"
            :value="lang.id"
            :class="{ '-selected': lang.id === locale.id }"
            :key="getItemKey('language_ ', lang)">
              {{ lang.id }}
            </button>

            <span class="separator" v-if="notLast(lang.id, locales)" :key="getItemKey('separator_ ', lang)">/</span>
          </template>

        </div>
      </template>

      <template v-slot:image-column-content><!-- v-if="image" -->
        <image-component :url="image.url" :altAttr="image.alt" v-on:image_loaded="resourceLoaded"></image-component>
      </template>
    </view-header>

    <div class="view-inner">
      <products></products>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import ModalMixin from '@/mixins/modal.js'
import TranslationMixin from '@/mixins/translation.js'
import Products from '@/components/Products.vue'
import ViewHeader from '@/components/ViewHeader.vue'

export default {
  name: 'Home',
  data: function() {
    return {
      // dataHasLoaded: false,
    }
  },
  mixins: [ModalMixin, TranslationMixin],
  components: {
    Products,
    ViewHeader,
  },
  computed: {
    token: function() {
      return this.$store.getters.getToken
    },
    frontpage_content: function() {
      var ret = this.$store.getters.getFrontpageContent;
      if (ret) {
        return ret
      } else {
        return false
      }
    },
    title: function() {
      if (this.frontpage_content && this.frontpage_content.title) {
        return this.frontpage_content.title
      } else {
        return ' ';
      }
    },
    lead: function() {
      if (this.frontpage_content && this.frontpage_content.lead) {
        return this.frontpage_content.lead
      } else {
        return ' ';
      }
    },
    image: function() {
      if (this.frontpage_content && this.frontpage_content.image) {
        return this.frontpage_content.image
      } else {
        return { url: '', alt: '' };
      }
    },
    spinner: function() {
      return this.$store.getters.getSpinner
    },
    isDataLoaded: function() {
      return this.$store.getters.getDataLodaded
    },
  },
  methods: {
    getItemKey(prefix = 'item_opt_', item) {
      return prefix + item.id
    },
    notLast(id, oTheItems) {
      var aTheItems = Object.keys(oTheItems);
      if (aTheItems.indexOf(id) < (aTheItems.length - 1)) {
        return true
      }
      return false
    },
    resourceLoaded: function(url) {
      console.log('IMAGE resource loaded', url);
    }
  },
  created() {

    // console.log('created. spinner: ', this.spinner);
    // console.log('HOME created');
    // this.$store.dispatch('setSpinner', this.spinner + 1);
  },
  mounted() {
    this.$nextTick(() => {
      // setTimeout(() => {
      // this.dataHasLoaded = true;
      // console.log('HOME mounted', this.frontpage_content);
      // }, 600)
    })
  },
  beforeUpdate() {
    // console.log('beforeUpdate. spinner: ', this.spinner);
    // this.$store.dispatch('setSpinner', this.spinner + 1);
    // console.log('HOME before update');
  },
  updated() {
    // console.log('HOME updated');
    this.$nextTick(() => {
      // console.log('HOME mounted', this.frontpage_content);
      // console.log('updated. spinner: ', this.spinner);
      // this.$store.dispatch('setSpinner', this.spinner - 1);
      // console.log('HOME updated nexttick');
    })
  }
  // watch: {
  //   frontpage_content: function(val) {
  //     console.log('frontpage_content', val);
  //     this.dataHasLoaded = true;
  //     this.$nextTick(() => {
  //
  //     });
  //   }
  // }
}
</script>

<style lang="scss">
.view.home {
  .view-header {
    .inner {
      .image-column {
        img {
          object-fit: cover;
        }
      }
    }
  }
  opacity: 1;
  &.-loading {
    opacity: 0;
  }
}
.language-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .language-selector-btn {
    appearance: none;
    border: 0px none;
    background-color: transparent;
    text-transform: uppercase;
    color: inherit;
    &.-selected {
      text-decoration: underline;
      color: var(--color-bright-green-1);
    }
  }
}
</style>

export default {
  data: function() {
    return {}
  },
  computed: {
    locale: function() {
      return this.$store.getters.getLocale
    },
    locales: function() {
      return this.$store.getters.getLocales
    }
  },
  methods: {
    _: function(xTheInput, oTheVars = null) {
      if (xTheInput instanceof Object) { // all translatable text coming from WP should be objects
        if (xTheInput[this.locale.id]) {
          return xTheInput[this.locale.id]
        }
      } else if (xTheInput instanceof String || typeof xTheInput === 'string') { // strings are used in template files, with translations residing in /store/i18n.js
        var sTheTranslation = null;
        if (oTheVars instanceof Object) {
          var fTheTranslation = this.$store.getters.getTranslatedString(xTheInput.toLowerCase());
          // sTheTranslation = this.$store.getters.getTranslatedString(xTheInput.toLowerCase(), oTheVars);
          if (typeof fTheTranslation === 'function') {
            sTheTranslation = fTheTranslation(...Object.values(oTheVars));
          }
        } else {
          sTheTranslation = this.$store.getters.getTranslatedString(xTheInput.toLowerCase());
        }

        if (sTheTranslation) {
          if (xTheInput[0] === xTheInput[0].toUpperCase()) {
            // capitalise first letter
            sTheTranslation.replace(/^\w/, (c) => c.toUpperCase());
          }

          return sTheTranslation;
        } else {
          return xTheInput;
        }
      }
    },
    setLocale: function(event) {
      this.$store.dispatch('setLocale', event.target.value)
    },
  },
  filters: {
    capitalise: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}

export default {
  data: function() {
    return {}
  },
  methods: {
    openModal: function(id, timing = 300) {
      this.$store.dispatch('openModal', { id: id, timing: timing });
      if ('activeElement' in document) {
        setTimeout(() => {
          console.log(document.activeElement);
        }, 200);
      }
    },
    closeModal: function(id, timing = 300) {
      this.$store.dispatch('closeModal', { id: id, timing: timing });
      // var r = this.getModalRef(id);
      // this.$refs[r].classList.add('-closing');
      // console.log('modal: ', this.$refs[r]);
      // setTimeout(() => {
      //   window.MicroModal.close(id);
      //   this.$refs[r].classList.remove('-closing');
      // }, timing)
    },
    registerModal: function(id) {
      this.$store.dispatch('registerModal', id);
    },
    getModalRef: function(id) {
      return 'modal_' + id;
    }
  }
}

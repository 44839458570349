<template>
  <div id="app" class="app" :class="{ '-has-sunbavbar': $route.name === 'Troubleshooting' || $route.name === 'Parts' }">
    <app-header></app-header>
    <!-- <main :class="{ '-loading': !dataHasLoaded }"> -->
    <transition :name="transitionName">
      <router-view/>
    </transition>
    <!-- </main> -->
    <footer class="app-footer">
      {{ _('version') | capitalise }}: {{ appVersion }}
    </footer>

    <modal modalID="modalPart" modalClass="standard" :allowClose="true">
      <template v-slot:content v-if="activePart">
        <div class="image-container">
          <image-component v-if="activePart.image.url" :url="activePart.image.url" :alt-attr="activePart.image.alt"></image-component>
        </div>
        <div class="inner">
          <h3 class="title">{{ _(activePart.title) }}</h3>
          <p class="part-nr" v-if="activePart.number">{{ _('part') | capitalise }} # {{ activePart.number }}</p>
          <p v-html="_(activePart.description)" v-if="activePart.description && activePart.description != 0"></p>
        </div>
      </template>
    </modal>

    <modal modalID="modalTool" modalClass="standard" :allowClose="true">
      <template v-slot:content v-if="activeTool">
        <div class="image-container">
          <image-component v-if="activeTool.image.url" :url="activeTool.image.url" :alt-attr="activeTool.image.alt"></image-component>
        </div>
        <div class="inner">
          <h3 class="title">{{ _(activeTool.title) }}</h3>
          <p v-html="_(activeTool.description)" v-if="activeTool.description && activeTool.description != 0"></p>
        </div>
      </template>
    </modal>

    <modal modalID="modalLogout" :modalTitle="_('log_out')" modalClass="standard" class="-backdrop-blur -popover" :allowClose="true">
      <template v-slot:content>
        <p>Are you sure you want to log out? This will erase locally saved user data.</p>
        <button class="modal-button" @click="logOut"><span>Log out</span></button>
      </template>
    </modal>

    <modal modalID="modalGetStarted" :modalTitle="_('get_started')" modalClass="standard" class="-backdrop-blur -popover" :allowClose="authenticated && disclaimerConsentChecked">
      <template v-slot:content>
        <template v-if="authenticated">
          <progress-indicator v-if="dataIsLoading"></progress-indicator>
          <p class="current-file" v-if="dataIsLoading && progressFilename">{{ progressFilename }}</p>

          <template v-if="token && !noData">
            <p>All done. Hit reload to finish up.</p>

            <button class="modal-button" @click="reload">
              <span>Reload</span>
            </button>

          </template>

          <template v-if="token && missingMediaManifest.length && !dataIsLoading">
            <p>{{ missingMediaManifest.length }} {{ _('missing_files_retry_prompt') }}</p>
            <button class="modal-button" @click="fireDownloadMissingMedia"><span>Download {{ missingMediaManifest.length }} files</span></button>
          </template>
        </template>

        <template v-else>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

          <button class="modal-button" type="button" id="submitGetStarted" @click="getStarted">
            <span class="btn-text">{{ _('get_started') }}</span>
          </button>
        </template>
      </template>
    </modal>

    <modal modalID="modalLogin" :modalTitle="_('log_in')" modalClass="standard" class="-backdrop-blur -popover" :allowClose="authenticated && disclaimerConsentChecked">
      <template v-slot:content>

        <template v-if="authenticated">
          <progress-indicator v-if="dataIsLoading"></progress-indicator>
          <p class="current-file" v-if="dataIsLoading && progressFilename">{{ progressFilename }}</p>

          <template v-if="token && !noData">
            <p>All done. Hit reload to finish up.</p>

            <button class="modal-button" @click="reload">
              <span>Reload</span>
            </button>

          </template>

          <template v-if="token && missingMediaManifest.length && !dataIsLoading">
            <p>{{ missingMediaManifest.length }} {{ _('missing_files_retry_prompt') }}</p>
            <button class="modal-button" @click="fireDownloadMissingMedia"><span>Download {{ missingMediaManifest.length }} files</span></button>
          </template>
        </template>

        <template v-else>
          <div class="input-block">
            <label for="inputUsername">Username</label>
            <input id="inputUsername" v-model="username" type="text" value="username" autocorrect="off" autocapitalize="none">
          </div>
          <div class="input-block">
            <label for="inputPassword">Password</label>
            <input id="inputPassword" v-model="password" type="text" value="password" autocorrect="off" autocapitalize="none">
          </div>

          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

          <button class="modal-button" type="button" id="submitLogin" @click="login">
            <span class="btn-text">{{ _('log_in') }}</span>
          </button>
        </template>

      </template>
    </modal>

    <modal modalID="modalDisclaimer" modalTitle="Health disclaimer" modalClass="standard" class="-backdrop-blur -popover -wide" :allowClose="disclaimerConsentChecked">
      <template v-slot:content>
        <h2>{{ _(disclaimer_heading) }}</h2>
        <p v-html="_(disclaimer_content)"></p>

        <label for="checkTerms">
          <input id="checkTerms" type="checkbox" @change="doDisclaimerConsent($event)" :checked="disclaimerConsentChecked"> I Agree to the terms
        </label>

        <div>
          <button class="modal-button" type="button" @click="closeModal('modalDisclaimer')">
            <span class="btn-text">{{ _('close') }}</span>
          </button>
        </div>
      </template>
    </modal>

    <modal modalID="dosAndDonts" modalTitle="Safety Manual" modalClass="standard" class="-backdrop-blur -image-unconstrained -popover -wide" :allowClose="true">
      <template v-slot:content>
        <h2>{{ _('dos_and_donts') | capitalise }}</h2>
        <template v-if="dosAndDontsContent.length">
          <div class="image-container" v-for="image in dosAndDontsContent" :key="'img_' + image.url">
            <image-component v-if="image.url" :url="image.url" :alt-attr="image.alt"></image-component>
          </div>
        </template>

      </template>
    </modal>

    <modal modalID="modalInstall" modalTitle="Last ned appen" modalClass="standard" class="-popover" :allowClose="true">
      <template v-slot:content>
        <p>{{ _('install_prompt') }}</p>
      </template>
    </modal>

    <!-- <modal modalID="modalRetry" modalTitle="Prøv igjen" modalClass="standard" class="-backdrop-blur -popover" :allowClose="true">
      <template v-slot:content>
        <progress-indicator v-if="dataIsLoading"></progress-indicator>

        <p class="current-file" v-if="dataIsLoading && progressFilename">{{ progressFilename }}</p>

        <template v-if="missingMediaManifest.length && !tryingAgain">
          <p>{{ missingMediaManifest.length }} downloads failed/missing. Would you like to try them again?</p>
          <button class="modal-button" @click="fireDownloadMissingMedia"><span>Retry download</span></button>
        </template>

        <template v-if="!missingMediaManifest.length && !tryingAgain">
          <p>All done. Hit reload to finish up.</p>
          <button class="modal-button" @click="reload">
            <span>Reload</span>
          </button>
        </template>

      </template>
    </modal> -->

    <modal modalID="modalUpdate" modalTitle="Oppdatering" modalClass="standard" class="-backdrop-blur -popover" :allowClose="true">
      <template v-slot:content>
        <progress-indicator v-if="dataIsLoading"></progress-indicator>
        <p class="current-file" v-if="dataIsLoading && progressFilename">{{ progressFilename }}</p>

        <p v-if="updateExists">New content available, please refresh</p>
        <p v-if="!updateExists && !noData && !tryingAgain">Downloading data requires reliable internet access</p>
        <p v-if="finishedDownload">Finished downloading</p>

        <template v-if="missingMediaManifest.length && !dataIsLoading">
          <p>{{ missingMediaManifest.length }} downloads failed/missing. Would you like to try them again?</p>
          <button class="modal-button" @click="fireDownloadMissingMedia"><span>Download {{ missingMediaManifest.length }} files</span></button>
        </template>

        <button class="modal-button" v-if="!tryingAgain && updateExists" @click="refreshApp"><span>Refresh app</span></button>

        <button class="modal-button" v-if="!tryingAgain && !updateExists" @click="reload"><span>Reload</span></button>

        <button class="modal-button" v-if="!tryingAgain && !updateExists" @click="fireDownloadData">
          <span>Download all data</span>
        </button>
      </template>
    </modal>

    <div class="spinner-container" v-if="!isDataLoaded && !dataIsLoading">
      <spinner></spinner>
    </div>
    <!-- <div class="swipe-tester">testing gestures {{ getSwipeGesture }}</div> -->
    <!-- <div v-if="dataIsLoading" class="loading-indicator"></div> -->

  </div>
</template>

<style lang="scss">

#app {
  position: relative;
  // font-family: CalibreRegular, Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  &.-has-sunbavbar {
    --subnavbar-height: 4rem;
    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      min-height: calc(var(--navbar-height) + var(--subnavbar-height));
      background-color: var(--color-blue-3);
    }
  }

  .spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2rem);
    // z-index: 290;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-indicator {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    z-index: 999;
    // background-color: rgba(black, .3);
  }

  .app-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: .8em;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .modal.-popover {
    .modal__container {
      max-width: 30rem;
      border-radius: 1rem;
      transition: max-width .3s ease 0s;
    }

    &.-wide {
      .modal__container {
        max-width: 70rem;
      }
    }

    .modal__content {
      padding: 3rem var(--gutter-width);
      display: grid;
      grid-gap: 1rem;

      p {
        overflow: hidden;
        text-overflow: ellipsis;

        &.current-file {
          font-size: .7em;
        }
      }

      .input-block {
        > label,
        > input {
          display: block;
          width: 100%;
        }
      }
    }
  }
  .swipe-tester {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    text-align: right;
    z-index: 999;
  }
}
</style>

<script>

import platform from '../node_modules/platform-detect/index.mjs'
// mixins
import UpdateMixin from './mixins/update.js'
import ModalMixin from './mixins/modal.js'
import TranslationMixin from './mixins/translation.js'
import SwipeMixin from './mixins/swipe.js'
// components
import Modal from './components/Modal.vue'
import AppHeader from './components/Header.vue'
import ProgressIndicator from './components/ProgressIndicator.vue'
import Spinner from './components/Spinner.vue'
// import Products from './components/Products.vue'

const DEFAULT_TRANSITION = 'slide';

export default {
  name: 'bright-app',
  data: function() {
    return {
      dataHasLoaded: false,
      showUpdateModal: false,
      finishedDownload: false,
      transitionName: DEFAULT_TRANSITION,
      tryingAgain: false,
      username: 'ab9933',
      password: '123',
      swipeGesture: 'none',
    }
  },
  mixins: [UpdateMixin, ModalMixin, TranslationMixin, SwipeMixin],
  components: {
    modal: Modal,
    appHeader: AppHeader,
    progressIndicator: ProgressIndicator,
    spinner: Spinner
  },
  methods: {
    init: function() {

      this.$store.dispatch('init');

      this.loadFonts();
      this.initSwipe();

      document.addEventListener('visibilitychange', this.visibilityChange, false);

      this.$nextTick(() => {
        // window.MicroModal.init({
        //   disableFocus: true,
        //   openClass: 'is-open fleur'
        // });
        // this.$store.dispatch('registerModal', 'mainMenu');
      });

      this.$router.beforeEach((to, from, next) => {
        if (this.$store.getters.getModalState('mainMenu') === 'open') {
          this.closeModal('mainMenu');
        }

        var transitionName = to.meta.transitionName || from.meta.transitionName;

        if (transitionName === 'slide') {
          const toDepth = to.path.split('/').length;
          const fromDepth = from.path.split('/').length;
          transitionName = toDepth <= fromDepth ? 'slide-right' : 'slide-left';
        }

        if (from.name === 'Profile' || to.name === 'Home') {
          transitionName = 'slide-right';
        }

        if (this.getSwipeGesture !== 'none') {
          transitionName = 'none';
        }

        this.transitionName = transitionName || DEFAULT_TRANSITION;

        next();
      })

      this.$router.afterEach((to, from) => {
        setTimeout(() => {
          // commit('SET_SWIPE_GESTURE', 'none');
          this.$store.dispatch('setSwipeGesture', 'none');
        }, 250);
      })
    },
    login: function() {
      if (this.username.length && this.password.length) {
        this.$store.dispatch('login', { username: this.username, password: this.password }).then(result => {
          console.log(result);
          if (result.success) {
            this.$store.dispatch('setLoginMessage', null);
          } else {
            this.$store.dispatch('setLoginMessage', result.message);
          }
        }).catch(err => {
          this.$store.dispatch('setLoginMessage', err);
          // this.errorMessage = err;
        })
      }
    },
    getStarted: function() {
      this.$store.dispatch('login', { username: this.username, password: this.password }).then(result => {
        console.log(result);
        if (result.success) {
          this.$store.dispatch('setLoginMessage', null);
        } else {
          this.$store.dispatch('setLoginMessage', result.message);
        }
      }).catch(err => {
        this.$store.dispatch('setLoginMessage', err);
        // this.errorMessage = err;
      })
    },
    logOut: function() {
      this.$store.dispatch('deleteUserData').then(result => {
        this.$router.push({ name: 'Home' });
        this.$store.dispatch('setToken', null);
        this.closeModal('modalLogout');
        this.openModal('modalLogin');
      });
    },
    loadFonts: function() {
      var loc = window.location;
      console.log(loc);
      var calibreRegular = new FontFace('CalibreRegular', 'url(https://repair.bright-products.com/fonts/Calibre/Calibre-Regular.ttf)');
      var calibreMedium = new FontFace('CalibreMedium', 'url(https://repair.bright-products.com/fonts/Calibre/Calibre-Medium.ttf)');
      var calibreSemibold = new FontFace('CalibreSemibold', 'url(https://repair.bright-products.com/fonts/Calibre/Calibre-Semibold.ttf)');

      calibreRegular.load().then(theFont => {
        document.fonts.add(theFont);
        // console.log(document.fonts);
      })
      calibreMedium.load().then(theFont => {
        document.fonts.add(theFont);
        // console.log(document.fonts);
      })
      calibreSemibold.load().then(theFont => {
        document.fonts.add(theFont);
        // console.log(document.fonts);
      })
    },
    nukeData: function() {
      // this.deleteAllData().then(result => {
      //   console.log('data empty');
      // })
    },
    fireDownloadMissingMedia: function() {
      this.tryingAgain = true;
      this.downloadMissingMedia().then(res => {
        console.log('fireDownloadData', res);
        if (res) {
          if (res === 'token_expired') {
            this.$store.dispatch('setToken', null);
            this.closeModal('modalUpdate');
            this.openModal('modalLogin');
            this.tryingAgain = false;
          } else if (res === 'media_missing') {
            this.tryingAgain = false;
            // this.closeModal('modalUpdate');
            // this.closeModal('modalLogin');
            // this.openModal('modalRetry');
          } else {
            this.finishedDownload = true;
            this.tryingAgain = false;
            this.$store.dispatch('setInitialDataStatus', true);
            console.log('app.vue: fireDownloadData', res);
            setTimeout(() => {
              // this.closeModal('modalUpdate');
              setTimeout(() => {
                this.finishedDownload = false;
              }, 1000);
            }, 1500)
          }
        } else {
          this.closeModal('modalUpdate');
          this.openModal('modalLogin');
        }
      });
    },
    fireDownloadData: function() {
      console.log('App.vue fireDownloadData');
      this.downloadData().then(res => {
        console.log('fireDownloadData', res);
        if (res) {
          if (res === 'token_expired') {
            this.$store.dispatch('setToken', null);
            this.closeModal('modalUpdate');
            this.openModal('modalLogin');
          } else {
            this.finishedDownload = true;
            console.log('app.vue: fireDownloadData', res);
            setTimeout(() => {
              // this.closeModal('modalUpdate');
              setTimeout(() => {
                this.finishedDownload = false;
              }, 1000);
            }, 1500)
          }
        } else {
          this.closeModal('modalUpdate');
          this.openModal('modalLogin');
        }
      });
    },
    visibilityChange: function(e) {
      console.log('visibility change', document.hidden);
      this.$store.dispatch('onVisibilityChange', document.hidden);
    },
    doFetchRequest: function() {
      this.$store.dispatch('fetchMedia', this.mediaManifest)
    },
    doDisclaimerConsent: function(event) {
      event.preventDefault();
      var val;

      if (this.disclaimerConsent === 0) {
        val = 1;
      } else {
        val = 0;
      }

      this.$store.dispatch('updateUserData', { key: 'disclaimer_consent', value: val, send: true });
    },
    openModalDisclaimer: function(event) {
      event.preventDefault();
      this.openModal('modalDisclaimer');
    },
  },
  computed: {
    getSwipeGesture: function() {
      return this.$store.getters.getSwipeGesture
    },
    mediaManifest: function() {
      return this.$store.getters.getMediaManifest
    },
    missingMediaManifest: function() {
      var ret = this.$store.getters.getMissingMediaManifest;
      if (ret.length) {
        return ret
      }
      return false
    },
    spinner: function() {
      return this.$store.getters.getSpinner
    },
    token: function() {
      return this.$store.getters.getToken
    },
    errorMessage: function() {
      return this.$store.getters.getLoginMessage
    },
    isDataLoaded: function() {
      return this.$store.getters.getDataLodaded
    },
    authenticated: function() {
      if (this.token) {
        return true
      }
      return false
    },
    disclaimerConsent: function() {
      return this.$store.getters.getDisclaimerConsent
    },
    disclaimerConsentChecked: function() {
      if (this.disclaimerConsent === 1) {
        return true
      } else {
        return false
      }
    },
    dosAndDontsContent: function() {
      if (this.frontpageContent && this.frontpageContent.dos_and_donts) {
        return this.frontpageContent.dos_and_donts
      }
      return false
    },
    loginState: function() {
      return this.$store.getters.getLoginState
    },
    appVersion: function() {
      return this.$store.getters.getAppVersion
    },
    isInstalled: function() {
      return platform.pwa
      // return this.$store.getters.getInstalledState
    },
    noData: function() {
      if (!this.$store.getters.getInitialDataStatus) {
        return true
      }
      return false
    },
    progress: function() {
      return this.$store.getters.getProgress
    },
    loadingPercentage: function() {
      if (this.progress) {
        return Math.floor(100 - ((this.progress.current / this.progress.total) * 100))
      }
      return false;
    },
    progressCurrent: function() {
      return this.progress.current
    },
    progressFilename: function() {
      if (this.dataIsLoading) {
        return this.progress.current_file
      }
      return false
    },
    dataIsLoading: function() {
      // return this.$store.getters.getLoadingState;
      if (this.progressCurrent > 0) {
        return true
      }
      return false
    },
    activePart: function() {
      return this.$store.getters.getActivePart;
    },
    activeTool: function() {
      return this.$store.getters.getActiveTool;
    },
    frontpageContent: function() {
      return this.$store.getters.getFrontpageContent;
    },
    disclaimer_heading: function() {
      if (this.frontpageContent && this.frontpageContent.health_disclaimer_heading) {
        return this.frontpageContent.health_disclaimer_heading
      } else {
        return false
      }
    },
    disclaimer_content: function() {
      if (this.frontpageContent && this.frontpageContent.health_disclaimer) {
        return this.frontpageContent.health_disclaimer
      } else {
        return false
      }
    },
  },
  watch: {
    updateExists: {
      handler: function() {
        console.log('open update modal');
        this.openModal('modalUpdate');
      },
      // immediate: true,
    }
  },
  mounted: function() {
    this.init();
    setTimeout(() => {
      console.log('install-timeout ended');
      if (!platform.pwa && (platform.ios || platform.android)) {
        console.log('open install modal');
        this.openModal('modalInstall');
      }
    }, 20000);

    if (this.updateExists) {
      setTimeout(() => {
        console.log('open update modal now');
        this.openModal('modalUpdate');
      }, 3000);
    }

    this.$nextTick(() => {
      setTimeout(() => {
        this.dataHasLoaded = true;
      }, 600)
    })
  },
  beforeCreate: function() {

  },
  created: function() {
    // this.init();
    // document.addEventListener('swiped', function(e) {
    //   console.log(e.target); // the element that was swiped
    //   console.log(e.detail.dir); // swiped direction
    //   // this.swipeGesture = e.detail.dir
    // });
  }
}

</script>

// var count = 'count';
// var total = 'total';

export default {
  translations: {
    en: {
      answer_correct: 'this answer is correct',
      answer_incorrect: 'this answer is wrong',
      answer_selected: 'You selected this option',
      back: 'back',
      back_to: 'back to',
      close: 'close',
      country: 'country',
      course: 'course',
      course_content: 'course content',
      course_plural: 'courses',
      description: 'description',
      done: 'done',
      dos_and_donts: 'dos_and_donts',
      firstname: 'first name',
      get_started: 'Get started',
      home: 'home',
      install_prompt: 'Install this application on your homescreen for a better experience',
      lastname: 'last name',
      language: 'language',
      language_plural: 'languages',
      lesson: 'lesson',
      lesson_plural: 'lessons',
      log_in: 'log in',
      log_out: 'log out',
      missing_files_retry_prompt: 'downloads failed/missing. Would you like to try them again?',
      next: 'next',
      part: 'part',
      part_plural: 'parts',
      password: 'password',
      previous: 'previous',
      product: 'product',
      product_plural: 'products',
      quiz: 'quiz',
      quiz_excellent: 'excellent!',
      quiz_fail: 'fail',
      quiz_passed: 'very good',
      quiz_plural: 'quizes',
      quiz_results: 'quiz results',
      restart: 'restart',
      review_quiz: 'review quiz',
      select_country: 'select country',
      start_course: 'Start course',
      start_quiz: 'Start quiz',
      task: 'question',
      task_plural: 'tasks',
      task_of_total: function(count, total) {
        return `question ${count} of ${total}`
      },
      tool: 'tool',
      tool_plural: 'tools',
      troubleshooting: 'troubleshooting',
      troubleshooting_description: 'How to find problems and repair them',
      user_courses: 'my courses',
      variant: 'variant',
      variant_plural: 'variants',
      version: 'version',
      warning_plural: 'warnings',
      year_of_birth: 'year of birth',
    },
    // de: {
    //   answer_correct: 'riktig svar (tysk)',
    //   answer_incorrect: 'feil svar (tysk)',
    //   answer_selected: 'valgt svar (tysk)',
    //   back: 'zurück',
    //   back_to: 'zurück zu',
    //   country: 'Land',
    //   course: 'Kurs',
    //   course_content: 'Kursinhalt',
    //   course_plural: 'Kurse',
    //   description: 'description',
    //   done: 'fertig',
    //   firstname: 'Vorname',
    //   install_prompt: 'Install this application on your homescreen for a better experience',
    //   lastname: 'Nachname',
    //   language: 'Sprache',
    //   language_plural: 'Sprachen',
    //   lesson: 'Lektion',
    //   lesson_plural: 'Lektionen',
    //   next: 'nächster',
    //   part: 'Teil',
    //   part_plural: 'Teile',
    //   previous: 'früher',
    //   product: 'Produkt',
    //   product_plural: 'Produkte',
    //   quiz: 'Prüfung',
    //   quiz_excellent: 'ausgezeichnet!',
    //   quiz_fail: 'nicht bestanden',
    //   quiz_passed: 'sehr gut',
    //   quiz_plural: 'Prüfungen',
    //   quiz_results: 'Prüfung resultaten',
    //   restart: 'wieder aufnehmen',
    //   review_quiz: 'überprüfen die Prüfung',
    //   select_country: 'select country på tysk',
    //   start_course: 'starte den Kurs',
    //   start_quiz: 'starte den Prüfung',
    //   task: 'Aufgabe',
    //   task_plural: 'Aufgaben',
    //   task_of_total: function(count, total) {
    //     return `Aufgabe ${count} aus ${total}`
    //   },
    //   tool: 'Werkzeug',
    //   tool_plural: 'Werkzeuge',
    //   troubleshooting: 'troubleshooting på tysk',
    //   user_courses: 'my courses',
    //   variant: 'variant',
    //   variant_plural: 'variants',
    //   year_of_birth: 'geburtsjahr',
    // },
    // fr: {
    //   answer_correct: 'riktig svar (fransk)',
    //   answer_incorrect: 'feil svar (fransk)',
    //   answer_selected: 'valgt svar (fransk)',
    //   back: 'retourner',
    //   back_to: 'retourner à',
    //   country: 'état',
    //   course: 'cours',
    //   course_content: 'le contenu des cours',
    //   course_plural: 'cours',
    //   done: 'finir',
    //   firstname: 'prénom',
    //   install_prompt: 'Install this application on your homescreen for a better experience',
    //   lastname: 'nom de famille',
    //   language: 'langue',
    //   language_plural: 'langues',
    //   lesson: 'leçon',
    //   next: 'suivant',
    //   lesson_plural: 'leçon',
    //   part: 'pièce',
    //   part_plural: 'pièces',
    //   previous: 'antérieur',
    //   product: 'produit',
    //   product_plural: 'produits',
    //   quiz: 'épreuve',
    //   quiz_excellent: 'excellent!',
    //   quiz_fail: 'raté',
    //   quiz_passed: 'très bien',
    //   quiz_plural: 'épreuves',
    //   quiz_results: 'épreuve renault',
    //   restart: 'recommencer',
    //   review_quiz: 'review l\'épreuve',
    //   select_country: 'fransk for select country',
    //   start_course: 'commencer le cours',
    //   start_quiz: 'commencer le épreuve',
    //   task: 'tâche',
    //   task_plural: 'tâches',
    //   task_of_total: function(count, total) {
    //     return `tâche ${count} de ${total}`
    //   },
    //   tool: 'outil',
    //   tool_plural: 'outils',
    //   troubleshooting: 'troubleshooting på fransk',
    //   user_courses: 'my courses',
    //   variant: 'variant',
    //   variant_plural: 'variants',
    //   year_of_birth: 'année de naissance',
    // },
  },
  // stringarray: [
  //   {
  //     key: 'courses',
  //     en: 'courses',
  //     de: 'Kurse',
  //     fr: 'cours',
  //   },
  //   {
  //     key: 'language',
  //     en: 'language',
  //     de: 'Sprache',
  //     fr: 'langue',
  //   },
  //   {
  //     key: 'products',
  //     en: 'products',
  //     de: 'Produkte',
  //     fr: 'produits',
  //   },
  //   {
  //     key: 'quiz',
  //     en: 'quiz',
  //     de: 'Prüfung',
  //     fr: 'épreuve',
  //   },
  //   {
  //     key: 'quizes',
  //     en: 'quizes',
  //     de: 'Prüfungen',
  //     fr: 'épreuves',
  //   },
  // ]
}

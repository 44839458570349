import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import MicroModal from 'micromodal'
import store from './store'
import localforage from 'localforage'
// import swipedEvents from './lib/swiped-events.js'
// import translationObjects from './store/i18n.js'
// import { createI18n } from 'vue-i18n'

// import Localbase from 'localbase'

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

import ImageComponent from './components/ImageComponent.vue'
import VideoComponent from './components/VideoComponent.vue'
import Icon from './components/Icon.vue'

// localforage.config({
//   name: 'bright_localforage'
// })

Vue.component('ImageComponent', ImageComponent)
Vue.component('VideoComponent', VideoComponent)
Vue.component('Icon', Icon)

// import VueVideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'

Vue.use(VuePlyr, {
  plyr: {
    iconUrl: 'img/plyr-sprite.svg',
    // iconUrl: require('./assets/images/plyr-sprite.svg'),
  }
})

// const i18n = createI18n({
//   // legacy: false,
//   locale: 'en',
//   fallbackLocale: 'en',
//   messages: translationObjects.translations
// })
// Vue.use(i18n)

// var db = new Localbase('localbase_db');

// Vue.use(VueVideoPlayer, /* {
//   options: global default options,
//   events: global videojs events
// } */)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <header class="view-header">
    <div class="inner">
      <div class="title-column">
        <h1 class="title">{{ heading }}</h1>
        <slot name="title-column-content"></slot>
      </div>

      <div class="image-column">
        <slot name="image-column-content"></slot>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'ViewHeader',
  props: {
    heading: String,
  }
}
</script>

<style lang="scss">
.view-header {
  --gutter-width: 3rem;
  // hack/fix weird layout bug from here
  overflow: hidden;
  > .inner {
    margin-bottom: -1px;
  }
  // to here
  .dates {
    color: var(--color-blue-4);
    display: flex;
    .icon {
      .svg-stroke {
        stroke: var(--color-blue-4);
      }
      & + span {
        margin-left: .5em;
      }
    }
  }
  .inner {
    display: grid;
    @include media-sm {
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    }
    @include media-md {
      // grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    }
    .image-column {
      display: flex;
      // min-height: 35rem;
      // max-height: 40vh;
      padding: var(--gutter-width);
      order: -1;
      @include media-sm {
        order: 2;
      }
      @include media-md {
        height: auto;
        max-height: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .title-column {
      padding: var(--gutter-width);
    }
    .title-column,
    .image-column {

    }
  }
}
.home,
.product {
  >.view-header {
    width: 100%;
    background-color: var(--color-blue-3);
    color: var(--color-white);
  }
}
.home {
  >.view-header {
    .inner {
      .image-column {
        max-height: 70vh;
        height: 100%;
        min-height: 35rem;
        padding: 0;
      }
      @include media-sm {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      }
      // @include media-md {
      //   grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      // }
    }
  }
}
.product {
  .view-header {
    .image-column {
      @include media-sm {
        // align-items: flex-end;
      }
      .image-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 70%;
        @include media-sm {
          padding-bottom: 100%;
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: contain;
        }
      }
    }
  }
}
.troubleshooting {
  .view-header {
    padding-right: var(--gutter-width);
    padding-left: var(--gutter-width);
  }
}
</style>

<template lang="html">
  <div class="spinner">
    <!-- <i class="spinner-graphic"></i>
    <i class="spinner-graphic"></i>
    <i class="spinner-graphic"></i>
    <i class="spinner-graphic"></i> -->
    <svg class="circle__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle class="circle__svg-circle" cx="50" cy="50" r="45"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
}
</script>

<style lang="scss" scoped>
.spinner {
  --border-width: .8rem;
  $circle-size: 10rem;
  // $circle-stroke-color: #2f3d4c;

  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;

  > .spinner-graphic {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 6.4rem;
    height: 6.4rem;
    margin: var(--border-width);
    border: var(--border-width) solid var(--color-blue-3);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color-blue-3) transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

.spinner div {

}
// .spinner div:nth-child(1) {
//   animation-delay: -0.45s;
// }
// .spinner div:nth-child(2) {
//   animation-delay: -0.3s;
// }
// .spinner div:nth-child(3) {
//   animation-delay: -0.15s;
// }
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circle__svg {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
}

.circle__svg-circle {
  animation: 1.4s ease-in-out infinite both circle__svg-circle;
  fill: transparent;
  stroke: var(--color-blue-3);
  stroke-dasharray: 285;
  stroke-linecap: round;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

.circle__svg {
  animation: 2s linear infinite both circle__svg;
}

@keyframes circle__svg-circle {
  0%, 25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%, 75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
</style>

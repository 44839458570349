<template lang="html">
  <div class="progress-indicator">
    <p>Loading … </p>
    <i class="loader -item">
      <span class="bar" :style="loadingItemStyle"></span>
    </i>
    <i class="loader">
      <span class="bar" :style="loadingBarStyle"></span>
      <span class="sr-only">Progress: {{ loadingPercentage }}%</span>
    </i>
    <!-- <div>{{ progress }}</div> -->
    <!-- <p>Progress: {{ loadingPercentage }}% </p> -->
  </div>
</template>

<script>
export default {
  name: 'progressIndicator',
  props: {

  },
  computed: {
    progress: function() {
      return this.$store.getters.getProgress
    },
    loadingPercentage: function() {
      if (this.progress) {
        var iTheCurrent = Math.floor(100 - ((this.progress.current / this.progress.total) * 100));
        console.log('percentage', this.progress, iTheCurrent);
        if (typeof iTheCurrent === 'number' && isFinite(iTheCurrent)) {
          if (iTheCurrent < 0) {
            return 0
          } else if (iTheCurrent > 100) {
            return 100
          } else {
            return iTheCurrent
          }
        }
        return 0;
      }
      return 0;
    },
    loadingItemPercentage: function() {
      if (this.progress) {
        return Math.floor(this.progress.item)
      }
      return 0;
    },
    progressCurrent: function() {
      return this.progress.current
    },
    loadingBarStyle: function() {
      return {
        '--progress-bar-width': this.loadingPercentage
      }
    },
    loadingItemStyle: function() {
      return {
        '--progress-bar-width': this.loadingItemPercentage
      }
    },
  }
}
</script>

<style lang="scss">
.progress-indicator {
  --progress-bar-width: 0;
  --progress-bar-height: .6rem;

  .loader {
    display: block;
    flex: 1 0 100%;
    width: 100%;
    height: var(--progress-bar-height);
    background-color: var(--color-light-grey-1);
    position: relative;
    border-radius: .5rem;
    overflow: hidden;

    & + .loader {
      margin-top: .2rem;
    }

    .bar {
      position: absolute;
      background-color: var(--color-blue-3);
      width: 100%;
      max-width: calc((100% / 100) * var(--progress-bar-width));
      height: 100%;
      left: 0;
      transition: max-width .3s ease 0s;
    }

    &.-item {
      height: .3rem;
      opacity: .5;
    }
  }
}
</style>

export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      console.log('actually reloading');
      window.location.reload(true);
    });
  },
  computed: {
    token: function() {
      return this.$store.getters.getToken
    }
  },
  methods: {
    updateAvailable(event) {
      console.log('triggered update available', event);
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshApp() {
      console.log('refresh triggered');
      // this.updateExists = false;
      // this.downloadData().then(result => {
      //
      // })
      if (this.updateExists) {
        if (!this.registration || !this.registration.waiting) return;
        console.log('posting update message to SW');
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      } else {
        this.downloadData().then(result => {
          if (result === 'failure') {

          }
          if (!result || result === null) {
            console.log('no results');
            this.$store.dispatch('openModal', { id: 'modalLogin', timing: 300 });
          } else if (result) {
            window.location.reload(true);
          }
        }).catch(err => {
          console.log(err)
          this.$store.dispatch('openModal', { id: 'modalLogin', timing: 300 });
        })
      }
    },
    reload() {
      window.location.reload(true);
    },
    downloadData() {
      console.log('update.js: downloadData');
      return new Promise((resolve, reject) => {
        if (this.token) {
          console.log('update.js: found token');
          this.$store.dispatch('fetchData', this.token).then(result => {
            console.log('finished data download ', result);
            return resolve(result);
          }).catch(err => console.log(err))
        } else {
          console.log('no token');
          return resolve(null);
          // reject(new Error('no token'));
        }
      })
    },
    downloadMissingMedia() {
      return new Promise((resolve, reject) => {
        if (this.token) {
          this.$store.dispatch('fetchMedia', this.$store.getters.getMissingMediaManifest).then(response => {
            console.log('downloadMissingMedia', response);
            if (response === 'media_missing') {
              console.log('update.js, downloadMissingMedia', response);
              return resolve('media_missing');
            } else {
              return resolve('missing_media_loaded');
            }
            // this.$store.dispatch('updateMissingMedia', null);

          }).catch(err => {
            reject(new Error(err));
          })
        } else {
          console.log('no token');
          return resolve(null);
        }
      })
      // dispatch('fetchMedia', getters.getMediaManifest).then(response => {
      //   return resolve('media_loaded');
      // }).catch(err => {
      //   reject(new Error(err));
      // })
    },
    deleteAllData() {
      return new Promise((resolve, reject) => {
        // this.$store.dispatch('deleteAllData').then(result => {
        //   return resolve(result);
        // })
      })
    }
  }
}

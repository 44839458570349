<template>
  <div class="view tekst">
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nec varius mauris, et molestie ante. Duis porttitor velit vitae magna pulvinar, ut ornare neque semper. Vivamus risus dolor, luctus eu tincidunt at, rhoncus nec turpis. Integer nulla massa, sodales sed mauris et, blandit sagittis sem.
    </p>
    <h2>Et bilde</h2>

    <!-- <image-component alt="Them were the glory days" url="https://stage.repair.bright-products.com/wp-content/uploads/sites/2/2021/05/Capture-of-Blackbeard.jpg"></image-component> -->

    <p>
      Integer vitae libero nunc. Donec condimentum semper ligula eu blandit. Praesent vulputate tortor nec enim iaculis, vitae facilisis velit porttitor. Quisque vulputate, elit in sodales molestie, libero quam tempor ligula, at imperdiet mauris justo ultrices lacus. Mauris id aliquam nunc. Nulla gravida egestas nibh sit amet mollis. Nullam non lobortis purus. Donec ut urna condimentum, vulputate ligula scelerisque, cursus tortor. Curabitur et porta diam, nec euismod leo. Nullam quis lobortis sapien. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus nec ipsum dapibus, gravida orci sed, pellentesque sem.
    </p>

    <p>Prøver noe her nå</p>
    <pre>
      {{ testJSON }}
    </pre>
  </div>
</template>

<script>

// import MediaMixin from '../mixins/media.js'

export default {
  name: 'Tekst',
  data: function() {
    return {
      // src: require('../assets/videos/milk.mp4'),
      testJSON: null,
      src: require('../assets/logo.png'),
    }
  },
  methods: {
    getTestImage: function() {
      fetch('https://stage.repair.bright-products.com/wp-json/wp/v2/media/7')
        .then(response => response.text())
        .then(data => {
          console.log('fetched some data');
          console.log(data);
          this.testJSON = data;
        })
    },
    getTestJSON: function() {
      fetch('https://stage.repair.bright-products.com/wp-json/wp/v2/media/7')
        .then(response => response.json())
        .then(data => {
          console.log('fetched some data');
          console.log(data);
          this.testJSON = data;
        })
    }
  },
  computed: {},
  created() {
    this.getTestJSON();
  }
}

</script>

<style lang="scss">
.tekst {
  // max-width: 600px;
  // margin: 0 auto;
  // text-align: left;
  img {
    width: 100%;
    height: auto;
  }
}
</style>

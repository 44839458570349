import Vue from 'vue'
import Vuex from 'vuex'
import platform from '../../node_modules/platform-detect/index.mjs'
// import dummyData from './dummyData.js'
import i18n from './i18n.js'
import cachingFunctions from './caching.js'
// import helperFunctions from '../../public/helperFunctions.js'
import MicroModal from 'micromodal'
// import Localbase from 'localbase'
// import { URLSearchParams } from 'url'
import localforage from 'localforage'
import ky from 'ky'
// import { extendPrototype } from 'localforage-startswith';
import { extendPrototype } from 'localforage-getitems';
extendPrototype(localforage);

Vue.use(Vuex)

// var coursesStore = localforage.createInstance({ name: 'bright_store', storeName: 'courses' });
// var lessonsStore = localforage.createInstance({ name: 'bright_store', storeName: 'lessons' });
// var localesStore = localforage.createInstance({ name: 'bright_store', storeName: 'locales' });
// var partsStore = localforage.createInstance({ name: 'bright_store', storeName: 'parts' });
// var productsStore = localforage.createInstance({ name: 'bright_store', storeName: 'products' });
// var quizesStore = localforage.createInstance({ name: 'bright_store', storeName: 'quizes' });
// var resourcesStore = localforage.createInstance({ name: 'bright_store', storeName: 'resources' });
var settingsStore = localforage.createInstance({ name: 'bright_store', storeName: 'settings' });
// var toolsStore = localforage.createInstance({ name: 'bright_store', storeName: 'tools' });
// var troubleshootingStore = localforage.createInstance({ name: 'bright_store', storeName: 'troubleshooting' });
// var variantsStore = localforage.createInstance({ name: 'bright_store', storeName: 'variants' });

var collectionStores = [];

// only for the logged in user. If a store of users is needed, it should be made separately, i.e. 'usersStore'
var userStore = localforage.createInstance({ name: 'bright_store', storeName: 'user' });
// var progressionStore = localforage.createInstance({ name: 'bright_store', storeName: 'progression' });

function filterObject(obj, key, val) {
  var aTheTempArray = Object.entries(obj);
  console.log(aTheTempArray);
  var aTheFiltered = aTheTempArray.filter((entry) => entry[1][key] === val);
  console.log(aTheFiltered);
  return Object.fromEntries(aTheFiltered);
}

function safeConsoleLog(data) {
  // console log lies! All cakes could be lies!
  console.log(JSON.parse(JSON.stringify(data)));
}

function findItemsInArrayByString(arr, string, hits = []) {
  var aTheHits = [];
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] instanceof Array) {
      aTheHits = aTheHits.concat(findItemsInArrayByString(arr[i], string, hits).filter(item => aTheHits.indexOf(item) < 0));
    } else if (typeof arr[i] === 'object') {
      aTheHits = aTheHits.concat(findItemsInObjectByString(arr[i], string, hits).filter(item => aTheHits.indexOf(item) < 0));
    } else {
      // ?
    }
  }
  return hits.concat(aTheHits.filter((item) => hits.indexOf(item) < 0));
}

function findItemsInObjectByString(obj, string, hits = []) {
  var aTheHits = [];
  for (var prop in obj) {
    if (obj[prop] instanceof Array) {
      aTheHits = aTheHits.concat(findItemsInArrayByString(obj[prop], string, hits).filter(item => aTheHits.indexOf(item) < 0))
    } else if (typeof obj[prop] === 'object') {
      aTheHits = aTheHits.concat(findItemsInObjectByString(obj[prop], string, hits).filter(item => aTheHits.indexOf(item) < 0))
    } else if (typeof obj[prop] === 'string') {
      if (obj[prop].indexOf(string) !== -1) {
        if (aTheHits.includes(obj[prop]) === false) {
          aTheHits.push(obj[prop])
        }
      }
    }
  }
  return hits.concat(aTheHits.filter((item) => hits.indexOf(item) < 0));
}

function chunkArrayInGroups(arr, size) {
  var aTheArray = [];
  for (var i = 0; i < arr.length; i += size) {
    aTheArray.push(arr.slice(i, size));
  }
  return aTheArray
}

export default new Vuex.Store({
  state: {
    packageVersion: process.env.VUE_APP_VERSION || 0,
    token: null,
    dataLoaded: false,
    loginState: null,
    loginMessage: null,
    // restURL: 'https://repair.bright-products.com/',
    // restURL: 'https://prod.bright.webcore.no/',
    restURL: 'https://admin.bright-repair.kanonsuper.no/',
    baseUrl: `${process.env.BASE_URL}`,
    uploadPending: false,

    user: {
      id: null,
      username: null,
      password: null,
      // first_name: null,
      // last_name: null,
      locale: null,
      location: null,
      nationality: null,
      progress: null,
      created: null,
      modified: null,
      gender: null,
      date_of_birth: null,
      disclaimer_consent: 0,
    },
    // course progress. Unused, actually?
    progression: {},

    i18n: i18n.translations,
    settings: {
      locale: 'en',
      fallbackLocale: 'en',
      initialDataStatus: false,
    },

    dataIsLoading: false,
    // initialDataStatus: false,

    locale: {
      en: {
        id: 'en',
        label: 'English',
        strings: null,
      },
    },

    frontpage_content: null,

    // CPTs
    course: {},
    lesson: {},
    product: {},
    variant: {},
    tool: {},
    quiz: {},
    part: {},
    troubleshooting: {},

    videos: {},
    images: {},

    mediaManifest: [],
    missingMediaManifest: [],

    modals: {},

    courseMode: 'lesson',
    courseMenuOpen: false,
    courseSubviewTransitionName: 'fade',

    swipeGesture: 'none',

    activeProduct: null,
    activeVariant: null,
    activeCourse: null,
    activeLesson: null,

    activeQuiz: null,
    activeQuizTasks: null,
    showQuizResults: false,
    showQuizReview: false,

    activeTask: null,
    activePart: null,
    activeTool: null,

    activeTroubleshootingArticle: null,
    troubleshootingStack: [],

    activeVideoUrl: null,
    messageChannel: null,
    progress: { // this is for the download progressbar
      total: 0,
      current: 0,
      current_file: null,
      item: 0, // some data about "current" download, which makes little sense, as the process is asynchronous
    },
    spinner: 0,
    progressCurrent: 0,
    progressTotal: 0,
  },
  mutations: {
    SET_TOKEN: function(state, payload) {
      state.token = payload;
    },

    SET_DATA: function(state, payload) {
      state[payload.key] = payload.value;
    },

    SET_DATA_LOADED: function(state, payload) {
      state.dataLoaded = payload;
    },

    SET_LOGIN_STATE: function(state, payload) {
      state.loginState = payload;
    },

    SET_UPLOAD_PENDING: function(state, payload) {
      state.uploadPending = payload;
    },

    SET_PROGRESS_CURRENT: function(state, payload) {
      if (payload > 0) {
        state.progress.current = payload;
      } else {
        state.progress.current = 0;
      }
    },

    SET_PROGRESS_CURRENT_FILE: function(state, payload) {
      state.progress.current_file = payload;
    },

    SET_PROGRESS_ITEM: function(state, payload) {
      state.progress.item = payload;
    },

    SET_PROGRESS_TOTAL: function(state, payload) {
      state.progress.total = payload;
    },

    SET_SPINNER: function(state, payload) {
      console.log('SET_SPINNER', payload);
      if (payload > 0) {
        state.spinner = payload;
      } else {
        state.spinner = 0;
      }
    },

    // SET_INITIAL_DATA_STATUS: function(state, payload) {
    //   state.initialDataStatus = payload;
    // },

    SET_MESSAGE_CHANNEL: function(state, payload) {
      state.messageChannel = payload;
    },

    ADD_VIDEO: function(state, payload) {
      // state.videos.push({ key: payload.key, url: payload.url });
      Vue.set(state.videos, payload.key, { key: payload.key, url: payload.url });
      // console.log(state.videos);
    },

    ADD_IMAGE: function(state, payload) {
      // state.videos.push({ key: payload.key, url: payload.url });
      Vue.set(state.images, payload.key, { key: payload.key, url: payload.url });
      // console.log('ADD_IMAGE', state.images);
    },

    ADD_USER_DATA: function(state, payload) {
      state.user = payload;
      console.log('commit user data ', payload);
    },

    ADD_PRODUCT: function(state, payload) {
      Vue.set(state.product, payload.id, payload);
      // console.log('products: ', state.products);
    },

    ADD_TOOL: function(state, payload) {
      Vue.set(state.tool, payload.id, payload);
      // console.log('tools: ', state.products);
    },

    ADD_FRONTPAGE_CONTENT: function(state, payload) {
      console.log('ADD_FRONTPAGE_CONTENT', payload);
      Vue.set(state, 'frontpage_content', payload);
    },

    ADD_ITEM: function(state, payload) {
      // console.log('ADD_ITEM', payload);
      Vue.set(state[payload.label], payload.item.id, payload.item);
    },

    SET_USER_DATA: function(state, payload) {
      state.user[payload.key] = payload.value;
    },

    SET_COURSE_PROGRESS: function(state, payload) {
      Vue.set(state.user.progress, payload.key, payload.value);
      console.log(state.progression);
    },

    UPDATE_SETTINGS: function(state, payload) {
      Vue.set(state.settings, payload.key, payload.value)
    },

    SET_LOCALE: function(state, payload) {
      state.locale = payload;
      // Vue.set(state.locale, payload.id, payload)
    },

    SET_LOADING_STATE: function(state, payload) {
      state.dataIsLoading = payload;
    },

    SET_MODAL: function(state, payload) {
      Vue.set(state.modals, payload.id, payload);
    },

    SET_ACTIVE_PRODUCT: function(state, payload) {
      state.activeProduct = payload;
    },

    SET_ACTIVE_VARIANT: function(state, payload) {
      state.activeVariant = payload;
    },

    SET_ACTIVE_COURSE: function(state, payload) {
      state.activeCourse = payload;
    },

    SET_ACTIVE_LESSON: function(state, payload) {
      state.activeLesson = payload;
      console.log('SET_ACTIVE_LESSON', state.activeLesson);
    },

    SET_ACTIVE_PART: function(state, payload) {
      state.activePart = payload;
      console.log('SET_ACTIVE_PART', state.activePart);
    },

    SET_ACTIVE_TOOL: function(state, payload) {
      state.activeTool = payload;
      console.log('SET_ACTIVE_TOOL', state.activeTool);
    },

    SET_ACTIVE_QUIZ: function(state, payload) {
      console.log('SET_ACTIVE_QUIZ', payload);
      state.activeQuiz = payload;
    },

    LOAD_ACTIVE_QUIZ_TASKS: function(state, payload) {
      Vue.set(state, 'activeQuizTasks', null);
      Vue.set(state, 'activeQuizTasks', payload);
    },

    UPDATE_QUIZ_TASK: function(state, payload) {
      Vue.set(state.activeQuizTasks[payload.task_id], 'answer', payload.answer_index);
      Vue.set(state.activeQuizTasks[payload.task_id], 'correct', payload.is_correct);
    },

    SET_ACTIVE_TASK: function(state, payload) {
      state.activeTask = payload;
    },

    SHOW_QUIZ_RESULTS: function(state, payload) {
      state.showQuizResults = payload;
    },

    SHOW_QUIZ_REVIEW: function(state, payload) {
      state.showQuizReview = payload;
    },

    SET_COURSE_MODE: function(state, payload) {
      console.log('setting course mode');
      state.courseMode = payload;
    },

    SET_COURSE_MENU_MODE: function(state, payload) {
      state.courseMenuOpen = payload;
    },

    SET_ACTIVE_TROUBLESHOOTING: function(state, payload) {
      state.activeTroubleshootingArticle = payload;
    },

    SET_TROUBLESHOOTING_STACK: function(state, payload) {
      Vue.set(state, 'troubleshootingStack', payload);
    },

    SET_COURSE_SUBVIEW_TRANSITION_NAME: function(state, payload) {
      state.courseSubviewTransitionName = payload;
    },

    SET_SWIPE_GESTURE: function(state, payload) {
      state.swipeGesture = payload;
    },

    UPDATE_MEDIA_MANIFEST: function(state, payload) {
      Vue.set(state, 'mediaManifest', payload);
    },

    UPDATE_MISSING_MEDIA_MANIFEST: function(state, payload) {
      Vue.set(state, 'missingMediaManifest', payload);
    },
  },
  getters: {
    getUserIsAdmin: function(state) {
      if (state.user && state.user.username === 'ab9933') {
        return true
      }
      return false
    },
    getAppVersion: function(state) {
      return state.packageVersion
    },
    getSwipeGesture: function(state) {
      return state.swipeGesture
    },
    getFrontpageContent: function(state) {
      return state.frontpage_content
    },
    getVideoByUrl: (state) => (url) => {
      if (state.videos[url]) {
        return state.videos[url]
      } else {
        var urlFragment = url.split(state.restURL)[1];
        var videoUrl = state.restURL + 'media/get/' + urlFragment
        return state.videos[videoUrl]
      }
    },
    getImageByUrl: (state) => (url) => {
      if (state.images[url]) {
        return state.images[url]
      } else {
        var urlFragment = url.split(state.restURL)[1];
        var imageUrl = state.restURL + 'media/get/' + urlFragment
        return state.images[imageUrl]
      }
    },
    getUser: function(state) {
      return state.user
    },
    getToken: function(state) {
      return state.token
    },
    getLoginMessage: function(state) {
      return state.loginMessage
    },
    getDataLodaded: function(state) {
      return state.dataLoaded
    },
    getLoginState: function(state) {

    },
    getDisclaimerConsent: function(state) {
      return state.user.disclaimer_consent
      // if (state.user.disclaimer_consent === 1) {
      //   return true
      // }
      // return false
    },
    getProgress: function(state) {
      return state.progress
    },
    getSpinner: function(state) {
      return state.spinner
    },
    getMediaManifest: function(state) {
      return state.mediaManifest
    },
    getMissingMediaManifest: function(state) {
      return state.missingMediaManifest
    },
    // getCurrentDownload: function(state) {
    //   return state.progress.
    // },
    getSettings: function(state) {
      return state.settings
    },
    getInitialDataStatus: function(state) {
      return state.settings.initialDataStatus
    },
    getLocales: function(state) {
      return state.locale
    },
    getLocale: function(state) {
      return state.locale[state.settings.locale] || state.locale[state.settings.fallbackLocale]
    },
    // getLoadingState: function(state) {
    //   return state.dataIsLoading
    // },

    getLoadingState: function(state) {
      if (state.progress.current > 0) {
        return true
      }
      return false
      // return state.dataIsLoading
    },

    getCourseMode: function(state) {
      return state.courseMode
    },

    getTranslatedString: (state) => (str, vars = null) => {
      var iTheTranslation;
      if (state.locale[state.settings.locale] && state.locale[state.settings.locale].strings) {
        iTheTranslation = state.locale[state.settings.locale].strings[str];
      }
      if (iTheTranslation) {
        return iTheTranslation;
      } else if (state.i18n.en[str]) {
        console.log('MISSING i18n STRING, RESORT TO BACKUP ', state.settings.locale, ' ', str);
        return state.i18n.en[str]
      } else {
        console.log('MISSING i18n STRING ', state.settings.locale, ' ', str);
        return str
      }
    },

    getProducts: function(state) {
      return state.product
    },

    getCoursesByProductID: (state) => (id) => {
      var aTheCourses = [];
      var aTheCourseIDs = state.products.id.courses;
      console.log('course IDs ', aTheCourseIDs);
    },

    getCourseByID: (state) => (id) => {
      return state.course[id]
    },
    getLessonByID: (state) => (id) => {
      return state.lesson[id]
    },
    getPartByID: (state) => (id) => {
      return state.part[id]
    },
    getProductByID: (state) => (id) => {
      return state.product[id]
    },
    getVariantByID: (state) => (id) => {
      return state.variant[id]
    },
    getQuizByID: (state) => (id) => {
      return state.quiz[id]
    },
    getToolByID: (state) => (id) => {
      return state.tool[id]
    },
    getTroubleshootingByID: (state) => (id) => {
      return state.troubleshooting[id]
    },

    getTroubleshootingStack: function(state) {
      return state.troubleshootingStack;
    },

    getModalState: (state) => (id) => {
      if (state.modals[id]) {
        return state.modals[id].state
      }
    },

    getLessonStatus: (state) => (courseID, lessonID) => {
      if (state.user.progress) {
        if (state.user.progress[courseID]) {
          if (state.user.progress[courseID].lessons) {
            if (state.user.progress[courseID].lessons[lessonID]) {
              return state.user.progress[courseID].lessons[lessonID].completed
            }
            return false
          }
          return false
        }
        return false
      }
    },

    getQuizStatus: (state) => (courseID, quizID) => {
      if (state.user.progress) {
        if (state.user.progress[courseID]) {
          if (state.user.progress[courseID].quizes) {
            if (state.user.progress[courseID].quizes[quizID]) {
              return state.user.progress[courseID].quizes[quizID].passed
            }
            return false;
          }
          return false
        }
        return false
      }
    },

    getQuizResultsBool: function(state) {
      return state.showQuizResults
    },

    getQuizReviewBool: function(state) {
      return state.showQuizReview
    },

    getCourseMenuOpen: function(state) {
      return state.courseMenuOpen
    },

    getCourseProgress: (state) => (id) => {
      if (state.user.progress[id]) {
        return state.user.progress[id]
        // returnState = false;
      }
      return null
    },

    getCourseStatus: (state) => (id) => {
      var returnState = true;
      console.log('getCourseStatus', state.user);
      if (!state.user.progress) {
        return false
      }
      if (!state.user.progress[id]) {
        return false
      }
      if (state.course[id].quizes && state.course[id].quizes.length) {
        if (state.user.progress[id].quizes) {
          Object.entries(state.user.progress[id].quizes).forEach(([key, quiz]) => {
            if (quiz.passed !== true) {
              returnState = false;
            }
          });
        } else {
          returnState = false
        }
      }
      if (state.user.progress[id].lessons) {
        Object.entries(state.user.progress[id].lessons).forEach(([key, lesson]) => {
          if (lesson.completed !== true) {
            returnState = false;
          }
        });
      }
      return returnState;
    },

    getCourses: function(state) {
      return state.course
    },

    getActiveProduct: function(state) {
      return state.activeProduct
    },
    getActiveVariant: function(state) {
      return state.activeVariant
    },
    getActiveCourse: function(state) {
      return state.activeCourse
    },
    getActiveLesson: function(state) {
      return state.activeLesson
    },
    getActiveQuiz: function(state) {
      return state.activeQuiz
    },
    getActivePart: function(state) {
      return state.activePart
    },
    getActiveTool: function(state) {
      return state.activeTool
    },
    getActiveTaskIndex: function(state) {
      return state.activeTaskIndex
    },
    getActiveTask: function(state) {
      return state.activeTask
    },
    getActiveQuizTasks: function(state) {
      return state.activeQuizTasks
    },
    getActiveTroubleshooting: function(state) {
      return state.activeTroubleshootingArticle
    },
    getCourseSubviewTransitionName: function(state) {
      return state.courseSubviewTransitionName
    }
  },
  actions: {
    init: function({ commit, state, dispatch, getters }) {
      console.log('init');

      MicroModal.init();

      dispatch('loadResources').then(result => {
        return result
      }).then(result => {

        settingsStore.getItem('token').then(function(token) {
          if (token) {
            // commit('SET_TOKEN', token);
            dispatch('setToken', token);
          }
          // dispatch('authenticate').then(token => {
          return new Promise((resolve, reject) => {
            if (token) {
              console.log('token found', token);
              // return resolve(token);
              dispatch('setup', token).then(result => {
                console.log('setup done,', state);
                return resolve(token);
              }).catch(err => {
                console.log('setup failed?', err);
              })
            } else {
              console.log('token not found');
              console.log('TRIGGER LOGIN');
              // do the login, then
              dispatch('openModal', { id: 'modalGetStarted', timing: 300 });
              resolve(null);
              // reject(new Error('token not found'));
              // dispatch('setToken', token).then(result => {
              //   return resolve(result)
              // })
            }
          })
        }).then(token => {
          if (token) {
            dispatch('setup', token);
          }
        })
      })

      document.addEventListener('swReady', function(event) {
        console.log('SW READY');
        const messageChannel = new MessageChannel();
        messageChannel.port1.onmessage = (evt) => {
          console.log('got message from SW, ', evt);
        }

        commit('SET_MESSAGE_CHANNEL', messageChannel);
        // establish communication with serviceWorker
        event.detail.active.postMessage({
          type: 'INIT_PORT',
        }, [messageChannel.port2]);

        if (platform) {
          console.log(platform);
          navigator.serviceWorker.ready.then(worker => {
            worker.active.postMessage({
              type: 'PLATFORM',
              browser: platform.browser,
              pwa: platform.pwa,
            });
          })
        }

      });
    },
    initOld: function({ commit, state, dispatch, getters }) {
      console.log('init');

      // console.log(platform);

      MicroModal.init();

      // console.log('settingsStore', settingsStore);

      // dispatch('authenticate').then(result => {
      //   return result;
      // }).then(result => {
      //   dispatch('loadUser', result).then(stuff => {
      //     console.log('loadUser result', stuff);
      //     return 'user data loaded ' + stuff
      //   })
      // }).then(userLoaded => {
      //
      // })
      // localforage.ready().then(function() {

      dispatch('loadResources').then(result => {
        return result
      }).then(result => {
        settingsStore.getItem('token').then(function(token) {
          if (token) {
            // commit('SET_TOKEN', token);
            dispatch('setToken', token);
          }
          // dispatch('authenticate').then(token => {
          return new Promise((resolve, reject) => {
            if (token) {
              console.log('token found', token);
              // return resolve(token);
              dispatch('setup', token).then(result => {
                console.log('setup done,', state);
                return resolve(token);
              }).catch(err => {
                console.log('setup failed?', err);
              })
            } else {
              console.log('token not found');
              console.log('TRIGGER LOGIN');
              // do the login, then
              dispatch('openModal', { id: 'modalLogin', timing: 300 });
              resolve(null);
              // reject(new Error('token not found'));
              // dispatch('setToken', token).then(result => {
              //   return resolve(result)
              // })
            }
          })
        }).then(token => {
          if (token) {
            dispatch('setup', token);
          }
        })
      })

      document.addEventListener('swReady', function(event) {
        console.log('SW READY');
        const messageChannel = new MessageChannel();
        messageChannel.port1.onmessage = (evt) => {
          console.log('got message from SW, ', evt);
        }

        commit('SET_MESSAGE_CHANNEL', messageChannel);
        // establish communication with serviceWorker
        event.detail.active.postMessage({
          type: 'INIT_PORT',
        }, [messageChannel.port2]);

        if (platform) {
          console.log(platform);
          navigator.serviceWorker.ready.then(worker => {
            worker.active.postMessage({
              type: 'PLATFORM',
              browser: platform.browser,
              pwa: platform.pwa,
            });
          })
        }

      });
    },

    setup: function({ commit, dispatch, getters }, token) {
      // dispatch('loadResources');
      // localforage.ready().then(function() {
      console.log('begin setup', token);

      var oTheInitStatus = {
        token: token,
      };
      // dispatch('setLoadingState', true);

      // .then(result => {
      //   return new Promise((resolve, reject) => {
      //     if (getters.getInitialDataStatus) {
      //       dispatch('loadSettings').then(result => {
      //         return resolve(getters.getOptions);
      //       })
      //     } else if (oTheInitStatus.token) {
      //       dispatch('fetchSettings', oTheInitStatus.token).then(result => {
      //         return resolve(result);
      //       })
      //     } else {
      //       return resolve(oTheInitStatus)
      //     }
      //   });
      // })

      dispatch('loadSettings', token).then(result => {
        return new Promise((resolve, reject) => {
          if (result) {
            return resolve(getters.getSettings);
          } else {
            dispatch('fetchSettings', oTheInitStatus.token).then(result => {
              return resolve(result);
            }).catch(err => {
              console.log('no settings', err);
            })
          }
        })
      }).then(result => {
        // console.log('before loading data. ', 'settings: ', result, 'init status: ', oTheInitStatus);
        return new Promise((resolve, reject) => {
          console.log('about to load data', getters.getToken);
          console.log('loading state', getters.getLoadingState);
          if (getters.getInitialDataStatus) {
            console.log('getters.getInitialDataStatus true');
            dispatch('loadData').then(result => {
              console.log('data loaded', result);
              dispatch('setDataLoaded', true);
              return resolve(oTheInitStatus)
            }).catch(err => {
              console.log('loadData went to catch', err);
              dispatch('setInitialDataStatus', false);
              // dispatch('openModal', { id: 'modalUpdate', timing: 300 })
              return resolve(oTheInitStatus);
            })
          } else {
            console.log('now fetch data here');
            dispatch('fetchData', token).then(response => {
              console.log('fetchdata then', response);
              // dispatch('setLoadingState', false)
              if (response === 'token_expired') { // TODO: dette gir jo ingen mening. Men bare se hva som skjer
                dispatch('authenticate').then(token => {
                  if (token) {
                    return resolve('try_download_again')
                  } else {
                    return resolve('login_again')
                  }
                })
              } else {
                console.log('fetched data', response);

                if (response === 'media_loaded') {
                  dispatch('setDataLoaded', true);
                  return resolve(response);
                } else {
                  return resolve('try_download_again');
                }

              }
            }).catch(err => {
              console.log('fetch failed', err);
              // dispatch('setLoadingState', false)
            })
          }
        })
      }).then(result => {
        console.log('load/fetch data, then: ', result);
        if (result === 'token_expired') {
          return result
        } else if (result === 'try_download_again') {
          commit('SET_PROGRESS_CURRENT', 0);
          commit('SET_PROGRESS_TOTAL', 0);
          return result
        } else {
          return new Promise((resolve, reject) => {
            if (result.token) {
              dispatch('loadUser', result.token).then(oTheUser => {
                if (oTheUser) {
                  dispatch('setLocale', oTheUser.locale);
                  return resolve('user_loaded')
                } else {
                  return resolve('no_user_found');
                }
              })
            } else {
              return resolve(oTheInitStatus)
            }
          });
        }
      }).then(result => {
        console.log('end of setup. Consider a reload');
        console.log(result);
        if (result === 'try_download_again' && getters.getToken) {
          // dispatch('closeModal', { id: 'modalLogin', timing: 300 })
          // dispatch('openModal', { id: 'modalRetry', timing: 300 })

        } else if (result === 'user_loaded' || result.token || result === 'media_loaded') {
          dispatch('setInitialDataStatus', true);
          if (!getters.getDisclaimerConsent) {
            dispatch('openModal', { id: 'modalDisclaimer', timing: 300 })
          }
        } else {
          dispatch('setInitialDataStatus', false);
          // dispatch('openModal', { id: 'modalLogin', timing: 300 })
          dispatch('openModal', { id: 'modalGetStarted', timing: 300 })
        }
        // dispatch('setLoadingState', false)
      });
      // })
    },

    login: function({ state, commit, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        fetch(state.restURL + 'user/login/?username=' + payload.username + '&password=' + payload.password)
          .then(response => response.json())
          .then(json => {
            console.log('login attempted', json);
            if (json.result === 'success') {
              dispatch('updateUserData', { key: 'password', value: payload.password, send: false });
              dispatch('setToken', json.token).then(result => {
                dispatch('setup', json.token).then(result => {
                  console.log('setup done,', state);
                  return resolve(json)
                })
              })
            } else if (json.result && json.message) {
              return resolve(json);
            } else {
              reject(new Error('API unresponsive'));
            }
          }).catch(err => {
            reject(new Error(err));
          })
      })
    },
    setLoginState({ state, commit, dispatch }, payload) {
      commit('SET_LOGIN_STATE', payload)
    },
    // handle authentication here, somehow
    authenticate: function({ state, commit, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        console.log('called authenticate');
        settingsStore.getItem('token').then(function(val) {
          console.log('settings (token)', val);
          if (val) {
            fetch(state.restURL + 'user/get?token=' + val)
              .then(response => response.json())
              .then(json => {
                if (json.result === 'success') {
                  dispatch('setToken', val).then(result => {
                    // console.log('token set', val);
                    return resolve(val)
                  })
                } else {
                  dispatch('setToken', null).then(result => {
                    return resolve(null);
                  })
                }
              })
            // console.log('found token,', val);

            // resolve(val)
          } else {
            console.log('no token');
            resolve(null);
            // reject(new Error('no token'));
            // resolve('5161569287144377')
          }
        })
        // make authentication happen, and then:
      });
    },

    setDataLoaded: function({ commit }, bool) {
      commit('SET_DATA_LOADED', bool);
    },

    onVisibilityChange: function({ commit, state, dispatch }, visible) {
      if (state.uploadPending) {
        // dispatch('uploadUserData');
      }
    },

    setInitialDataStatus: function({ commit, dispatch }, bool) {
      // commit('SET_INITIAL_DATA_STATUS', payload);
      return new Promise((resolve, reject) => {
        dispatch('updateSettings', { key: 'initialDataStatus', value: bool }).then(result => {
          return resolve(result)
        }).catch(err => {
          console.log('initialDataStatus error', err);
          reject(new Error(err))
        })
      })
    },

    setToken: function({ commit, dispatch }, token) {
      return new Promise((resolve, reject) => {
        commit('SET_DATA', { key: 'token', value: token });
        dispatch('updateSettings', { key: 'token', value: token }).then(result => {
          return resolve(result)
        }).catch(err => {
          console.log('setToken error', err);
        })
      })
    },

    setLoginMessage: function({ commit, dispatch }, message) {
      commit('SET_DATA', { key: 'loginMessage', value: message });
    },

    loadOptions: function({ commit, dispatch, getters }, token) {

    },

    setSpinner: function({ commit, getters }, payload) {
      commit('SET_SPINNER', payload);
    },

    async fetchMedia({ state, commit, dispatch, getters }, items) {
      return new Promise((resolve, reject) => {
        var aThePromises = [];
        // commit('SET_PROGRESS_CURRENT', items.length);
        // commit('SET_LOADING_STATE', true);
        console.log('fetchMedia', items);
        // commit('SET_PROGRESS_TOTAL', items.length);
        commit('SET_PROGRESS_TOTAL', getters.getProgress.total + items.length);

        items.forEach(async (item, i) => {

          var itemUrlFragment = item.split(state.restURL)[1];
          if (itemUrlFragment) {
            aThePromises.push(new Promise((resolve, reject) => {
              // var sTheFetchUrl = state.restURL + 'media/get/' + itemUrlFragment;

              return dispatch('parseUrl', item).then(async (parsed) => {

                var sTheType;
                if (parsed.extension === 'mp4') {
                  sTheType = 'video/mp4';
                } else {
                  sTheType = 'image/' + parsed.extension;
                }

                commit('SET_PROGRESS_CURRENT', getters.getProgress.current + 1);

                var sTheFetchUrl = state.restURL + 'media/get/' + itemUrlFragment;

                // const buffer = await dispatch('fetchRequest', { url: sTheFetchUrl, type: sTheType });

                // const response = await fetch(sTheFetchUrl);
                const response = await ky(sTheFetchUrl, {
                  retry: {
                    // limit: 40,
                    timeout: 600000,
                  },
                  onDownloadProgress: (progress, chunk) => {
                    commit('SET_PROGRESS_ITEM', progress.percent * 100);
                    var aTheUrl = items[i].split('/');
                    // commit('SET_PROGRESS_CURRENT_FILE', items[i]);
                    commit('SET_PROGRESS_CURRENT_FILE', aTheUrl.slice(-1).pop());

                    if (progress.percent === 0) {
                      // commit('SET_PROGRESS_CURRENT_FILE', items[i - 1]);
                      // commit('SET_PROGRESS_CURRENT', getters.getProgress.current + 1);
                      // console.log('started download', sTheFetchUrl);
                    } else if (progress.percent === 100) {

                    }
                    // Example output:
                    // `0% - 0 of 1271 bytes`
                    // `100% - 1271 of 1271 bytes`
                    // console.log(`${progress.percent * 100}% - ${progress.transferredBytes} of ${progress.totalBytes} bytes`);
                  }
                });

                const arrayBuffer = await response.arrayBuffer();
                const bytes = new Uint8Array(arrayBuffer);
                var blob = new Blob([bytes], { type: sTheType });
                var buffer = await blob.arrayBuffer();

                return dispatch('storeItemInDB', { parsed: parsed, url: sTheFetchUrl, buffer: buffer }).then(result => {

                  commit('SET_PROGRESS_CURRENT', getters.getProgress.current - 1);
                  commit('SET_PROGRESS_CURRENT_FILE', items[i - 1]);
                  return resolve(item);
                }).catch(err => {
                  console.log('storeItemInDB error', err, item);
                  commit('SET_PROGRESS_CURRENT', getters.getProgress.current - 1);
                  reject(new Error(err));
                  // commit('SET_LOADING_STATE', false);
                })

              }).catch(err => {
                console.log('parseUrl error + ', err, item);
                dispatch('addMissingMedia', item);
                reject(new Error(err));
                // commit('SET_LOADING_STATE', false);
              })
            }));
          }
        })

        // var aThePromiseChunks = chunkArrayInGroups(aThePromises, 10);
        // for (var i = 0; i < aThePromiseChunks.length; i++) {
        //   Promise.all(aThePromiseChunks[i]).then(result => {
        //
        //   })
        // }
        // var iThePromiseIndex = 0;
        // var aTheResults = [];
        // while (iThePromiseIndex < aThePromiseChunks.length) {
        //   console.log('aThePromiseChunks', aThePromiseChunks[iThePromiseIndex]);
        //   Promise.allSettled(aThePromiseChunks[iThePromiseIndex]).then(result => {
        //     aTheResults.push(result);
        //     iThePromiseIndex += 1;
        //   }).catch(err => {
        //     iThePromiseIndex += 1;
        //     reject(new Error(err));
        //   })
        // }
        //
        // for (var i = 0; i < aThePromiseChunks.length; i++) {
        //
        // }
        //
        // console.log(aTheResults);
        //
        // return resolve(aTheResults);

        Promise.allSettled(aThePromises).then(result => {
          console.log('media load result', result);
          console.log(getters.getMissingMediaManifest);
          var returnValue = 'media_loaded';

          for (var i = 0; i < result.length; i++) {
            if (result[i].status === 'rejected') {
              console.log('media missing');
              returnValue = 'media_missing';
              // break;
            } else {
              if (getters.getMissingMediaManifest.indexOf(result[i].value) > -1) {
                dispatch('removeMissingMedia', result[i].value);
              }
            }
          }

          if (returnValue === 'media_loaded' || returnValue === 'media_missing') {
            commit('SET_PROGRESS_CURRENT', 0);
            commit('SET_PROGRESS_TOTAL', 1);
          }

          console.log('result of media download allSettled', result);
          console.log(returnValue);

          return resolve(returnValue);
        }).catch(err => {
          commit('SET_PROGRESS_CURRENT', 0);
          commit('SET_PROGRESS_TOTAL', 0);
          reject(new Error(err));
        })
      });
    },

    // async fetchRequest({ state, commit, dispatch, getters }, payload) {
    //   // { url, type }
    //
    //   // TODO: legg inn parameter for typen request. Med mindre vi ikke får til å legge in headere på andre end points, da.
    //   // i så fall, pakk denne funksjonen, og den andre, i en ny funksjon som bruker den ene eller den andre etter behov
    //   // var itemUrlFragment = payload.url.split(state.restURL)[1];
    //   // state.restURL + 'media/get/' + itemUrlFragment
    //   // if (itemUrlFragment) {
    //   // var sTheFetchUrl = state.restURL + 'media/get/' + itemUrlFragment;
    //   commit('SET_PROGRESS_CURRENT', getters.getProgress.current + 1);
    //   const response = await fetch(payload.url);
    //
    //   // kan det hende at response.arrayBuffer(); er bedre? Kan kanskje brukes til å lage en progressbar for
    //   // flere requests på én gang – bare tell hvor mange requests som gjenstår, i stedet for å telle hvor mye av hver fil
    //   // const arrayBuffer = await response.arrayBuffer();
    //   // const bytes = new Uint8Array(arrayBuffer);
    //   const length = response.headers.get('Content-length');
    //   if (!length) {
    //     // return await response.arrayBuffer();
    //     console.log('no length here');
    //     return await response.arrayBuffer();
    //   }
    //   const array = new Uint8Array(length);
    //   let at = 0;
    //   const reader = response.body.getReader();
    //   while (true) {
    //     const { done, value } = await reader.read();
    //     // commit('SET_PROGRESS_CURRENT', (at / length).toFixed(2));
    //     if (done) {
    //       break;
    //     }
    //     array.set(value, at);
    //     at += value.length;
    //     // }
    //
    //     var newBlob = new Blob([array], { type: payload.type });
    //     var newArrayBuffer = await newBlob.arrayBuffer();
    //     commit('SET_PROGRESS_CURRENT', getters.getProgress.current - 1);
    //     return newArrayBuffer
    //     // console.log('blob?', newBlob);
    //
    //     // dispatch('arrayBufferToBlob', {})
    //
    //     // response.blob().then(blob => {
    //     //   console.log('blob?', blob);
    //     // });
    //     // return array;
    //   }
    // },

    fetchSettings: function({ commit, state, dispatch, getters }, token) {
      return new Promise((resolve, reject) => {
        ky(state.restURL + 'options/get?token=' + token)
          .then(response => response.json())
          .then(json => {
            console.log('SETTINGS', json);
            return resolve(json);
            // locales
          }).catch(err => {
            reject(new Error(err));
          })
      })
    },

    loadSettings: function({ commit, dispatch, getters }, token) {
      console.log('loadSettings', token);
      return new Promise((resolve, reject) => {
        settingsStore.getItems().then(settings => {
          if (settings) {
            var aTheSettings = [];
            Object.entries(settings).forEach(([key, value]) => {
              aTheSettings.push(dispatch('updateSettings', { key: key, value: value }));
            });
            Promise.all(aTheSettings).then(result => {
              console.log('settings saved', result);
              return resolve(result);
            }).catch(err => {
              console.log('loadSettings error');
              reject(new Error(err))
            })
          } else {
            return resolve(null)
          }
        })
      });
    },

    loadUser: function({ state, commit, dispatch, getters }, token) {
      console.log('loadUser', token);
      return new Promise((resolve, reject) => {
        userStore.getItems().then(user => {
          console.log('user found locally', user);
          // look for user locally
          // var theResponse = [];
          if (user.id) { // user found
            Object.entries(user).forEach(([key, value]) => {
              dispatch('updateUserData', { key: key, value: value, send: false });
              if (key === 'progress') {
                console.log(key, value);
                if (value) {
                  dispatch('updateCourseProgress', value).then(response => {
                    console.log(response);
                  })
                } else {
                  dispatch('updateCourseProgress', {}).then(response => {
                    console.log(response);
                  })
                }
              }
            })
            resolve(user);
          } else { // no user found/not logged in
            console.log('user not found locally');
            fetch(state.restURL + 'user/get?token=' + token)
              .then(response => response.json())
              .then(json => {
                console.log('USER', json);
                if (json.result === 'success') {
                  console.log('USER FORTHCOMMING');
                  for (var key in json.data) {
                    // if (key === 'progress') {
                    //   if (state.user.progress) {
                    //     console.log('UPDATE USER PROGRESS?');
                    //     console.log(state.user.progress);
                    //     console.log(ob);
                    //   }
                    //   // updateCourseProgress
                    // } else {
                    dispatch('updateUserData', { key: key, value: json.data[key], send: false });
                    // commit('SET_USER_DATA', { key: key, value: json.data[key] });
                    // }
                  }
                  userStore.getItem('password').then(password => {
                    commit('SET_USER_DATA', { key: 'password', value: password });
                    // dispatch('updateUserData', { key: 'password', value: password, send: false });
                  });
                  return resolve(json.data);
                } else {
                  return resolve(null);
                }
              });
            // dispatch('updateUserData', { key: 'created', value: new Date() });

          }
        }).catch(err => {
          console.log('no user found?');
          reject(new Error(err));
        })
      })
    },
    // deleteAllData: function({ state }) {
    //   return new Promise((resolve, reject) => {
    //     localforage.clear().then(function() {
    //       return resolve('EMPTY');
    //     })
    //   })
    // },
    deleteUserData: function({ state, commit }) {
      return new Promise((resolve, reject) => {
        var clearancePromises = [];

        Object.keys(state.user).forEach((key, i) => {
          commit('SET_USER_DATA', { key: key, value: null });
          clearancePromises.push(userStore.removeItem(key));
        });

        Promise.all(clearancePromises).then(result => {
          console.log(result);
          console.log('user:', state.user);
          return resolve(result);
        }).catch(err => {
          console.log(err);
          reject(new Error(err));
        })
      });
    },
    hardAppRefresh: function({ state }) {
      console.log('store > hardAppRefresh');
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ type: 'HARD_REFRESH' });
      }
    },

    setLoadingState: function({ commit }, bool) {
      console.log('setLoadingState', bool);
      commit('SET_LOADING_STATE', bool);
    },

    setSwipeGesture: function({ commit }, str) {
      console.log('setSwipeGesture', str);
      commit('SET_SWIPE_GESTURE', str);
      // setTimeout(() => {
      //   commit('SET_SWIPE_GESTURE', 'none');
      // }, 250);
    },

    getSwipeGesture: function(state) {
      return state.swipeGesture
    },

    // remove any lesson ids that don't correspond to extant lessons
    updateCourseLessons: function({ state, commit, getters }, oTheCourse) {
      return new Promise((resolve) => {
        var course = JSON.parse(JSON.stringify(oTheCourse));
        if (course.lessons && course.lessons.length) {
          course.lessons.forEach((id, i) => {
            var oTheLesson = getters.getLessonByID(id);
            if (oTheLesson && !oTheLesson.id) {
              delete course.lessons[id];
            }
          });
        }
        return resolve(course);
      })
    },
    updateProductVariants: function({ state, commit, getters }, oTheProduct) {
      return new Promise((resolve) => {
        var product = JSON.parse(JSON.stringify(oTheProduct));
        if (product.variants && product.variants.length) {
          product.variants.forEach((id, i) => {
            var oTheVariant = getters.getVariantByID(id);
            if (oTheVariant && !oTheVariant.id) {
              delete product.variants[id];
            }
          });
        }
        return resolve(product);
      })
    },
    updateVariantCourses: function({ state, commit, getters }, oTheVariant) {
      return new Promise((resolve) => {
        var variant = JSON.parse(JSON.stringify(oTheVariant));
        if (variant.courses && variant.courses.length) {
          variant.courses.forEach((id, i) => {
            var oTheCourse = getters.getCourseByID(id);
            if (oTheCourse && !oTheCourse.id) {
              delete variant.courses[id];
            }
          });
        }
        return resolve(variant);
      })
    },

    updateCourseProgress: function({ commit, state, dispatch, getters }, aTheOldProgress = {}) {
      return new Promise((resolve, reject) => {
        // progress
        var oTheNewProgress = JSON.parse(JSON.stringify(aTheOldProgress));

        var progressEntries = Object.entries(oTheNewProgress);
        if (progressEntries.length > 0) {
          progressEntries.forEach(([courseID, course]) => {
            if (course.lessons && state.course[courseID]) {
              var aTheProgressLessons = Object.keys(course.lessons);
              var aTheLessonsToRemove = aTheProgressLessons.filter(id => !state.course[courseID].lessons.includes(parseInt(id)));
              var aTheLessonsToAdd = state.course[courseID].lessons.filter(id => !aTheProgressLessons.includes(id.toString()));

              if (aTheLessonsToRemove.length) {
                aTheLessonsToRemove.forEach((id, i) => {
                  console.log('removing this from progress', course.lessons[id]);
                  delete course.lessons[id];
                });
              }
            } else {
              course.lessons = {};
            }
          });
        } else { // no prior progress
          oTheNewProgress = {};
        }

        // TODO: add any missing lessons? Maybe we don't need to

        // commit('SET_USER_DATA', { key: 'progress', value: oTheNewProgress });
        dispatch('updateUserData', { key: 'progress', value: oTheNewProgress, send: false });
        resolve(oTheNewProgress);
      })
    },
    // load data from database to state
    loadData: function({ commit, dispatch, getters }) {
      console.log('loadData');
      return new Promise((resolve, reject) => {
        Promise.all([
          // dispatch('loadCollection', { label: 'settings', store: settingsStore }),
          dispatch('loadFrontpageContent'),
          dispatch('loadMissingMediaManifest'),
          dispatch('loadCollection', { label: 'part', plural: 'parts' }),
          dispatch('loadCollection', { label: 'product', plural: 'products' }),
          dispatch('loadCollection', { label: 'variant', plural: 'variants' }),
          dispatch('loadCollection', { label: 'course', plural: 'courses' }),
          dispatch('loadCollection', { label: 'lesson', plural: 'lessons' }),
          dispatch('loadCollection', { label: 'locale', plural: 'locales' }),
          dispatch('loadCollection', { label: 'tool', plural: 'tools' }),
          dispatch('loadCollection', { label: 'quiz', plural: 'quizes' }),
          dispatch('loadCollection', { label: 'troubleshooting', plural: 'troubleshooting' }),
        ]).then(result => {
          console.log('loaded some data', result);
          return resolve(result)
        }).catch(err => {
          console.log(err);
          reject(new Error(err))
        })
      })
    },

    updateLesson: function({ commit, state, dispatch, getters }, payload) {
      // { course: this.activeCourse.id, lesson: this.activeLesson.id, completed: false }
      console.log('update lesson', payload);
      var course = getters.getCourseByID(payload.course);
      if (course) {
        var oTheNewProgress;
        if (state.user.progress) {
          oTheNewProgress = JSON.parse(JSON.stringify(state.user.progress));
        } else {
          oTheNewProgress = {};
        }
        if (course.lessons.find(el => el === payload.lesson)) {
          var oTheCourseToUpdate;
          console.log('inside course.lessons', course.lessons.find(el => el === payload.lesson));
          // if the course is not in the progress object already, add it now
          if (!oTheNewProgress[course.id]) {
            oTheNewProgress[course.id] = {
              id: course.id,
              lessons: {}
            };
          }
          // define a reference to the course we are updating
          oTheCourseToUpdate = oTheNewProgress[course.id];

          var oTheProgressLessons = {};
          // make sure to add any missing lessons to the progress object
          course.lessons.forEach((id, i) => {
            if (!oTheCourseToUpdate.lessons[id]) {
              oTheCourseToUpdate.lessons[id] = {
                id: id,
                completed: false,
              };
            }
          });
          // now do the update
          oTheCourseToUpdate.lessons[payload.lesson] = {
            id: payload.lesson,
            completed: payload.completed,
          };
          oTheCourseToUpdate.updated = new Date();

          console.log('updateUserData about tot fire', oTheNewProgress);
          dispatch('updateUserData', { key: 'progress', value: oTheNewProgress, send: true })
        }
      }
    },

    // keeping this for posterity – it could be useful in the future.
    // The idea is that every update to the course is dated, so you can compare local and server data, to decide which to keep.
    // But a new install is a blank slate, so any prior progress fetched from the server
    // can simply be allowed to overwrite the local progress (which will be null, anyway).
    updateCourse: function({ commit, dispatch, getters }, payload) {
      // { fetched: course, found: item, courses: courses }
      console.log('updateCourse', payload);
      return new Promise((resolve, reject) => {
        // the course already exists, and updates/dates need to be compared
        var oThefoundCourseClone = JSON.parse(JSON.stringify(payload.found));
        console.log('FOUND', oThefoundCourseClone);

        if (payload.courses[oThefoundCourseClone.id]) {
          // check if the course has all lessons loaded, and add/update them if not/as necessary
          Object.entries(payload.courses[oThefoundCourseClone.id].lessons).forEach(([ind, id]) => {
            if (!oThefoundCourseClone.lessons[id]) {
              console.log(id);
              var oTheLesson = getters.getLessonByID(id);
              console.log('GOT LESSON BY ID', oTheLesson);

              if (oTheLesson) {
                oThefoundCourseClone.lessons[id] = oTheLesson;
              }
            } else {
              // lesson has been completed. Lets update it anyway, but keep (re-add) the completed state
              if (oThefoundCourseClone.lessons[id].completed) {
                oThefoundCourseClone.lessons[id] = payload.courses[oThefoundCourseClone.id]
                oThefoundCourseClone.lessons[id].completed = true;
              } else {
                oThefoundCourseClone.lessons[id] = payload.courses[oThefoundCourseClone.id]
              }
            }
          });

          Object.entries(payload.courses[oThefoundCourseClone.id].quizes).forEach(([id, i]) => {
            // quiz not found in progress. define it now
            if (!oThefoundCourseClone.quizes[id]) {
              var oTheQuiz = getters.getQuizByID(id);
              oThefoundCourseClone.quizes[id] = oTheQuiz;
            } else {
              // quiz has been completed. Lets update it anyway, but keep (re-add) the completed state
              if (oThefoundCourseClone.quizes[id].passed) {
                oThefoundCourseClone.quizes[id] = payload.courses[oThefoundCourseClone.id]
                oThefoundCourseClone.quizes[id].passed = true;
              } else {
                oThefoundCourseClone.lessons[id] = payload.courses[oThefoundCourseClone.id]
              }
            }
          });
        }

        oThefoundCourseClone.updated = new Date();

        console.log('after', oThefoundCourseClone);

        commit('SET_COURSE_PROGRESS', { key: oThefoundCourseClone.id, value: oThefoundCourseClone });

        console.log('local item is newer (or at least not older)');

        resolve(oThefoundCourseClone);
      });
    },

    // fetch data from the REST API,
    // store it in the database,
    // and load it to state
    fetchData: function({ commit, dispatch, getters }, token) {
      console.log('fired download data function');

      return new Promise((resolve, reject) => {
        var aTheCollectionPromises = [];

        aTheCollectionPromises.push(
          new Promise((resolve, reject) => {
            return dispatch('fetchSettings', token).then(response => {
              console.log('fetchSettings', response);
              if (response.result === 'success') {
                Promise.all([
                  dispatch('storeCollection', { label: 'locale', data: response.locales }),
                  dispatch('storeFrontpageContent', response.frontpage_content)
                ]).then(result => {
                  return resolve(result);
                })
              } else if (response.result === 'failure') {
                return resolve(response.result)
              } else {
                reject(new Error('API unresponsive'))
              }
            })
          })
        );

        var aTheCollectionLabels = ['product', 'variant', 'course', 'lesson', 'quiz', 'tool', 'part', 'troubleshooting'];

        // for (var i = 0; i < aTheCollectionLabels.length; i++) {
        //   var label = aTheCollectionLabels[i];
        //   console.log('store this, label:', label);
        //   aTheCollectionPromises.push(
        //     new Promise((resolve, reject) => {
        //       dispatch('fetchCollection', { label: label, token: token }).then(response => {
        //         console.log('fetch ' + label, response);
        //         if (response.result === 'success') {
        //           var items = Object.values(response.data);
        //           console.log(label, items);
        //           return dispatch('storeCollection', { label: label, data: response.data }).then(stored => {
        //             console.log('storeCollection got to then', stored);
        //             return resolve(response.result)
        //           }).catch(err => {
        //             console.log('storeCollection error', err);
        //             reject(new Error(err))
        //           })
        //         } else if (response.result === 'failure') {
        //           return resolve(response)
        //         } else {
        //           return resolve('API unresponsive')
        //           // reject(new Error('API unresponsive'))
        //         }
        //       })
        //     })
        //   );
        // }

        aTheCollectionLabels.forEach((label, i) => {
          console.log('LABEL', label);
          commit('SET_PROGRESS_CURRENT', getters.getProgress.current + 1);
          commit('SET_PROGRESS_TOTAL', getters.getProgress.current + 1);

          aTheCollectionPromises.push(
            new Promise((resolve, reject) => {
              console.log('LABEL AGAIN', label);

              dispatch('fetchCollection', { label: label, token: token }).then(response => {
                console.log('fetch ' + label, response);

                if (response.result === 'success') {
                  var items = Object.values(response.data);
                  console.log(label, items);

                  return dispatch('storeCollection', { label: label, data: response.data }).then(stored => {
                    console.log('storeCollection got to then', stored);
                    commit('SET_PROGRESS_CURRENT', getters.getProgress.current - 1);

                    return resolve(response.result)
                  }).catch(err => {
                    console.log('storeCollection error', err);
                    reject(new Error(err))
                  })
                } else if (response.result === 'failure') {
                  commit('SET_PROGRESS_CURRENT', getters.getProgress.current - 1);
                  return resolve(response)
                } else {
                  commit('SET_PROGRESS_CURRENT', getters.getProgress.current - 1);
                  return resolve('API unresponsive')
                  // reject(new Error('API unresponsive'))
                }
              })
            })
          );
        });

        console.log('fetchdata promises', aTheCollectionPromises);

        Promise.all(aTheCollectionPromises).then((result) => {
          console.log('fetchData result', result);

          var i = 0;
          while (i < result.length) {
            // if (result[i] === undefined) {
            //   console.log('end early, no_connection');
            //   return resolve('no_connection');
            // } else
            if (result[i] === 'failure') {
              console.log('end early. token_expired, or gremlins in the API');
              return resolve('token_expired');
            }
            i++;
          }

          console.log('Promise.all while loop ended', result);

          // var aTheJpegManifest = getters.getMediaManifest.filter(item => item.indexOf['.jpeg'] !== -1);
          // console.log(aTheJpegManifest);

          dispatch('fetchMedia', getters.getMediaManifest).then(response => {
            return resolve(response);
          }).catch(err => {
            reject(new Error(err));
          })
        }).catch(err => {
          console.log('fetchdata error', err);
          reject(new Error(err));
        })
      })
    },

    fetchCollection: function({ state, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        ky(state.restURL + payload.label + '/get?token=' + payload.token)
          .then(response => response.json())
          .then(json => {
            var aTheJpgs = findItemsInObjectByString(json.data, '.jpg');
            var aTheJpegs = findItemsInObjectByString(json.data, '.jpeg');
            var aThePngs = findItemsInObjectByString(json.data, '.png');
            var aTheMovies = findItemsInObjectByString(json.data, '.mp4');

            var aTheImages = [...aTheJpgs, ...aTheJpegs, ...aThePngs];

            // dispatch('updateMedia', aTheJpgs.concat(aThePngs));
            dispatch('updateMedia', aTheImages);
            dispatch('updateMedia', aTheMovies);

            return resolve(json)
          }).catch(err => {
            console.log('fetch failed', payload);
            reject(new Error(err));
          })
      })
    },

    updateMedia: function({ state, commit, dispatch }, items) {
      var aTheManifest = [...state.mediaManifest];
      items.forEach((item, i) => {
        if (item.indexOf(state.restURL) !== -1) {
          if (!aTheManifest.includes(item)) {
            aTheManifest.push(item);
          }
        }
      });
      commit('UPDATE_MEDIA_MANIFEST', aTheManifest);

      // dispatch('fetchResources', state.mediaManifest).then(mediaLoaded => {
      //   dispatch('loadResources').then(res => {
      //     console.log(res, state.images);
      //   })
      // })
    },

    saveMissingMediaManifest: function({ state, commit, dispatch }, aTheManifest) {
      commit('UPDATE_MISSING_MEDIA_MANIFEST', aTheManifest);
      return new Promise((resolve, reject) => {
        return localforage.setItem('missing_media_manifest', aTheManifest).then((res) => {
          return localforage.getItem('missing_media_manifest').then((got) => {
            return resolve(got);
          })
        })
      });
    },

    addMissingMedia: function({ state, commit, dispatch }, item) {
      var aTheManifest;
      if (item) {
        aTheManifest = [...state.missingMediaManifest];
        console.log('add missing media', item, aTheManifest);
        if (aTheManifest.indexOf(item) === -1) {
          aTheManifest.push(item);
          dispatch('saveMissingMediaManifest', aTheManifest);
        }
      }
    },

    removeMissingMedia: function({ state, commit, dispatch }, item) {
      var aTheManifest;
      if (item) {
        aTheManifest = [...state.missingMediaManifest];
        console.log('remove missing media', item, aTheManifest);
        var i = aTheManifest.indexOf(item);
        if (i > -1) {
          aTheManifest.splice(i, 1);
          dispatch('saveMissingMediaManifest', aTheManifest);
        }
      }
    },

    fetchResources: function({ state, commit, dispatch, getters }, items) {
      console.log('fetchResources', items);
      // https://stage.repair.bright-products.com/media/get/wp-content/uploads/sites/2/2021/08/pliers.jpg <- DENNE
      // https://stage.repair.bright-products.com/wp-content/uploads/sites/2/2021/08/01-unscrew-battery-box.jpg
      // https://stage.repair.bright-products.com/media/get/wp-content/uploads/sites/2/2021/08/pliers.jpg

      return new Promise((resolve, reject) => {
        var promises = [];
        items.forEach((item, i) => {
          promises.push(new Promise((resolve, reject) => {
            var itemUrlFragment;
            if (typeof item === 'string') {
              itemUrlFragment = item.split(state.restURL)[1];
            }
            // } else if (item.url) {
            //   itemUrlFragment = item.url.split(state.restURL)[1];
            // }
            if (getters.getImageByUrl(item)) {
              resolve('already added');
            } else if (itemUrlFragment) {
              // console.log('fetch an image', itemUrlFragment);
              return fetch(state.restURL + 'media/get/' + itemUrlFragment).then(response => {
                // TODO: ressursene må lagres. Gjør SW det?
                // console.log('image fetch response', response.clone());
                dispatch('saveToDB', { url: response.url, response: response.clone() }).then(saved => {
                  return resolve(saved);
                }).catch(err => {
                  console.log('problem saving', err);
                  reject(new Error(err));
                })
                // return resolve(response);
              }).catch(err => {
                console.log('sth wrong in fetchResources', err);
                reject(new Error(err));
              })
            } else {
              console.log('no url fragment', item);
              resolve('no url fragment');
            }
            // fetch(item).then(response => {
            //   console.log(response);
            //   return response;
            // })
          }))
        });
        Promise.allSettled(promises).then(result => {
          console.log('fetchResources result', result);
          return resolve(result);
        }).catch(err => {
          console.log('fetchResources error', err);
          reject(new Error(err));
        })
      });
    },

    loadResources: function({ state, commit, dispatch, getters }) {

      console.log('TODO: rework when API operational');
      // console.log('LOAD RESOURCES');
      return new Promise((resolve, reject) => {
        var images = [];
        var videos = [];

        localforage.iterate((item, key, i) => {
          if (item.extension) {
            // console.log('localforage.iterate', item);
            switch (item.extension) {
              case 'mp4':
                videos.push(item);
                break;
              case 'png':
              case 'jpg':
              case 'jpeg':
                images.push(item);
                break;
            }
          }
        }).then(result => {
          console.log('IMAGES', images);
          console.log('VIDEOS', images);
          dispatch('loadImages', images).then(msg => {
            console.log('images loaded', msg);
            console.log(state.images);
            return msg;
            // return resolve('images loaded');
          }).then(result => {
            dispatch('loadVideos', videos).then(msg => {
              console.log('videos loaded', msg);
              return resolve(msg);
            });
          })
        }).catch(err => {
          console.log(err);
        })
      })
    },

    loadFrontpageContent: function({ commit }) {
      return new Promise((resolve, reject) => {
        localforage.getItem('frontpage_content').then((data) => {
          if (data) {
            commit('ADD_FRONTPAGE_CONTENT', data);
            return resolve(data);
          } else {
            reject(new Error('no frontpage_content found'));
          }
        }).catch(err => {
          reject(new Error('no frontpage_content found' + err));
        })
      })
    },

    loadMissingMediaManifest: function({ commit }) {
      return new Promise((resolve, reject) => {
        localforage.getItem('missing_media_manifest').then((data) => {
          if (data) {
            commit('UPDATE_MISSING_MEDIA_MANIFEST', data);
            return resolve(data);
          } else {
            return resolve('no missing media manifest found');
          }
        })
      })
    },

    loadCollection: function({ commit, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        localforage.keys().then(function(keys) {
          if (keys.length) {
            var aTheHits = keys.filter((key) => key.startsWith(payload.label));
            var aThePromises = [];

            if (aTheHits.length) {
              console.log('loadCollection hits', aTheHits);
              aTheHits.forEach((key, i) => {
                aThePromises.push(new Promise((resolve, reject) => {
                  return localforage.getItem(key).then((item) => {
                    var oTheTempObject = {
                      label: payload.label,
                      item: item
                    }
                    commit('ADD_ITEM', oTheTempObject);
                    return resolve(oTheTempObject);
                  })
                }))
              });
              Promise.all(aThePromises).then(result => {
                return resolve('loaded ' + payload.label);
              }).catch(err => {
                console.log('failed to load collection ' + payload.label);
                reject(new Error(err))
              })

            } else {
              // reject(new Error('no hits ' + keys + ' ' + payload.plural))
              return resolve('no hits', payload.label)
            }
          } else {
            resolve(null)
          }
        })
      })
    },

    storeFrontpageContent: function({ state, commit, dispatch }, data) {
      console.log('store frontpage content', data);
      commit('ADD_FRONTPAGE_CONTENT', data);

      return new Promise((resolve, reject) => {
        return localforage.setItem('frontpage_content', data).then((res) => {
          return localforage.getItem('frontpage_content').then((got) => {

            var aTheImages = findItemsInObjectByString(data, '.jpg');
            aTheImages = aTheImages.concat(findItemsInObjectByString(data, '.png'));

            if (aTheImages.length) {
              dispatch('updateMedia', aTheImages).then(done => {
                return resolve(done);
              })
            } else {
              return resolve(got);
            }
          })
        })
      });
    },

    storeCollection: function({ state, commit, dispatch, getters }, payload) {
      console.log('storeCollection', payload);
      var sThePrefix = payload.label + '_';
      return new Promise((resolve, reject) => {
        var itemPromises = [];
        for (var id in payload.data) {
          var item = JSON.parse(JSON.stringify(payload.data[id]))
          var sTheCollectionLabel = sThePrefix + item.id;
          var oTheTempObject = {
            label: payload.label,
            item: item
          }

          console.log(oTheTempObject);

          commit('ADD_ITEM', oTheTempObject);

          var pTheItemPromise = new Promise((resolve, reject) => {
            console.log('begin promise', payload.label);
            return localforage.setItem(sTheCollectionLabel, item).then((result) => {
              console.log('payload.store.setItem', result);
              return localforage.getItem(sTheCollectionLabel).then((thing) => {
                console.log('stored this', thing);
                return resolve(thing);
              })
            })
          });
          itemPromises.push(pTheItemPromise);
        }

        // delete any stored items which have been deleted in WP
        localforage.keys().then(function(keys) {
          if (keys.length) {
            // local item IDs
            var aTheHits = keys.filter((key) => key.startsWith(payload.label));
            console.log('DELETE local hits', aTheHits);
            aTheHits.forEach((item, i) => {
              aTheHits[i] = item.split(sThePrefix)[1];
            });
            console.log('DELETE local hits modified', aTheHits);

            // fetched item IDs
            var aTheFetchedIDs = [];
            for (var id in payload.data) {
              var iTheID = payload.data[id].id;
              if (iTheID) {
                aTheFetchedIDs.push(iTheID.toString());
              }
            }
            console.log('DELETE payload IDs', aTheFetchedIDs);
            // now compare the two arrays, and weed out any items only occurring locally
            var aTheFilteredHits = aTheHits.filter((key) => aTheFetchedIDs.indexOf(key) === -1);

            console.log('DELETE', aTheFilteredHits);

            if (aTheFilteredHits.length) {
              aTheFilteredHits.forEach((id, i) => {
                localforage.getItem(sThePrefix + id).then((thing) => {
                  if (thing) {
                    itemPromises.push(new Promise((resolve, reject) => {
                      return localforage.removeItem(sThePrefix + id).then(() => {
                        return resolve('item_deleted');
                      }).catch(err => {
                        console.log(err);
                      })
                    }))
                  }
                })
              });

            }

            // Object.entries(payload.data).forEach((arr, i) => {
            //   if (arr[1].id) {
            //     aTheFetchedIDs.push(arr[1].id)
            //   }
            // });

          }
          // if (aTheHits.length) {
          //   aTheHits.forEach((key, i) => {
          //
          //   })
          // }
        });

        // console.log('storeCollection itemPromises', itemPromises);

        // return new Promise((resolve, reject) => {
        Promise.all(itemPromises).then(result => {
          console.log('storeCollection itemPromises about to resolve', result);
          return resolve(payload.label);
        }).catch(err => {
          console.log('itempromises all failed');
          reject(new Error(err));
        })
        // })
      });
    },

    loadVideos: function({ commit, getters }, videos) {
      return new Promise((resolve, reject) => {
        videos.forEach((video, i) => {
          var b = new Blob([video.data], { type: 'video/mp4' });
          var objectUrl = window.URL.createObjectURL(b);
          commit('ADD_VIDEO', { key: video.url, url: objectUrl });
        });
        return resolve('videos loaded');
      });
    },

    loadImages: function({ commit, getters }, images) {
      console.log('loadImages', images);
      return new Promise((resolve, reject) => {
        var aTheImages = [];
        images.forEach((image, i) => {
          var type = 'image/' + image.extension;
          var b = new Blob([image.data], { type: type });
          var objectUrl = window.URL.createObjectURL(b);
          commit('ADD_IMAGE', { key: image.url, url: objectUrl });
        });
        return resolve('images loaded');
      });
    },

    updateUserData: function({ commit, state, getters, dispatch }, payload) {
      // { key, value, send }
      console.log('UPDATE USER DATA ', payload);
      commit('SET_USER_DATA', payload);

      if (state.token && payload.send) {
        // dispatch('uploadUserData');
      }

      userStore.setItem(payload.key, payload.value).then(res => {
        userStore.getItem(payload.key).then(got => {
          // console.log('saved user data', got);
        })
      })
    },

    uploadUserData: function({ commit, state, dispatch }) {
      fetch(state.restURL + 'user/save?token=' + state.token, {
        method: 'post',
        mode: 'cors',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(state.user),
      }).then(response => response.json())
        .then(json => {
          console.log('as json', json);
          if (json.result && json.result === 'failure') {
            console.log('do reauthenticate');
            dispatch('setToken', null);
            dispatch('setLoginMessage', 'Login expired. You need to log in again.');
            // dispatch('openModal', { id: 'modalLogin', timing: 300 });
            dispatch('openModal', { id: 'modalGetStarted', timing: 300 });
            // dispatch('setUploadPending', true);
          } else if (json.result && json.result === 'success') {
            dispatch('setUploadPending', false);
          }
        }).catch(err => {
          console.log(new Error(err));
          dispatch('setUploadPending', true);
        })
    },

    updateSettings: function({ commit, state, getters }, payload) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_SETTINGS', payload);
        settingsStore.setItem(payload.key, payload.value).then(() => {
          settingsStore.getItem(payload.key).then(item => {
            console.log('set this setting,', item)
            return resolve(item)
          });
        })
      })
    },

    setUploadPending: function({ commit }, bool) {
      commit('SET_UPLOAD_PENDING', bool);
    },

    saveCourse: function({ commit, state, dispatch, getters }, payload) {
      console.log('saveCourse ', payload);
      dispatch('getCourseByID', payload.course).then(oTheCourse => {
        if (oTheCourse.updated) {
          console.log(oTheCourse.updated);
          if (oTheCourse.updated < payload.updated) {
            console.log('payload is newer');
            console.log(oTheCourse);
            console.log(payload);
          } else {
            console.log('payload is older');
            console.log(oTheCourse);
            console.log(payload);
          }
        }
      })
    },

    setLocale: function({ commit, state, dispatch, getters }, id) {
      console.log('setLocale', id);
      console.log(state);
      if (state.locale[id]) {
        dispatch('updateSettings', { key: 'locale', value: id })
        dispatch('updateUserData', { key: 'locale', value: id, send: true })
      }
    },

    setActiveProduct: function({ commit, state, getters, dispatch }, id) {
      return new Promise((resolve, reject) => {
        var oTheProduct = getters.getProductByID(id);
        if (oTheProduct) {
          dispatch('updateProductVariants', oTheProduct).then(product => {
            commit('SET_ACTIVE_PRODUCT', product)
            resolve('success')
          })
        } else {
          reject(new Error('not found ' + 'id: ' + id + 'state: ' + state))
        }
      })
    },

    setActiveVariant: function({ commit, state, getters, dispatch }, payload) {
      // { productID: to.params.productID, variantID: to.params.variantID }
      return new Promise((resolve, reject) => {
        var product = getters.getProductByID(payload.productID);
        var oTheVariant = getters.getVariantByID(payload.variantID);
        // var aTheFiltered = product.variants.filter((variant) => variant.id === payload.variantID);
        if (product && oTheVariant && product.variants.indexOf(payload.variantID) !== -1) {
        // if (aTheFiltered) {
          // commit('SET_ACTIVE_VARIANT', aTheFiltered[0])
          dispatch('updateVariantCourses', oTheVariant).then(variant => {
            commit('SET_ACTIVE_VARIANT', variant)
            resolve('success')
          })

        } else {
          reject(new Error('not found ' + 'payload: ' + payload + 'state: ' + state))
        }
      })
    },

    setActiveTroubleshooting: function({ commit, dispatch, state, getters }, id) {
      return new Promise((resolve, reject) => {
        if (id === null) {
          commit('SET_ACTIVE_TROUBLESHOOTING', null);
          commit('SET_TROUBLESHOOTING_STACK', []);
          resolve('deactivated troubleshooters');
        } else {
          var article = getters.getTroubleshootingByID(id);
          if (article) {
            commit('SET_ACTIVE_TROUBLESHOOTING', article);
            resolve('success');
          } else {
            reject(new Error('not found ' + 'id: ' + id + 'state: ' + state));
          }
        }
      });
    },

    setActiveCourse: function({ commit, state, getters, dispatch }, id) {
      return new Promise((resolve, reject) => {
        if (id === null) {
          commit('SET_ACTIVE_COURSE', null)
          resolve('deactivated course')
        } else {
          var oTheCourse = getters.getCourseByID(id);
          if (oTheCourse) {
            dispatch('updateCourseLessons', oTheCourse).then(course => {
              commit('SET_ACTIVE_COURSE', course);
              resolve(id);
            })
          } else {
            reject(new Error('not found ' + 'id: ' + id + 'state: ' + state))
          }
        }
      })
    },

    setActiveLesson: function({ commit, dispatch, state, getters }, payload) {
      // { to: id , from: id }
      return new Promise((resolve, reject) => {
        if (payload && payload.to) {
          var lesson = getters.getLessonByID(payload.to);
          payload.type = 'lesson';
          if (lesson) {
            dispatch('setCourseTransitionName', payload).then(result => {
              commit('SET_ACTIVE_LESSON', lesson)
              resolve(payload.to)
            })
          } else {
            commit('SET_ACTIVE_LESSON', null)
            resolve('lesson not found');
          }
        } else {
          commit('SET_ACTIVE_LESSON', null)
          resolve('active lesson null');
        }
      })
    },

    setActiveTask: function({ commit, dispatch, state, getters }, payload) {
      // { to: id , from: id }
      return new Promise((resolve, reject) => {
        if (payload && payload.to) {
          var oTheTasks = getters.getActiveQuizTasks;
          var oTheTask = oTheTasks[payload.to];
          payload.type = 'task';
          // var oTheTask = state.activeQuizTasks.find(item => item.id === id);
          if (oTheTask) {
            dispatch('setCourseTransitionName', payload).then(result => {
              commit('SET_ACTIVE_TASK', oTheTask)
              resolve(payload.to)
            })
            // commit('SET_ACTIVE_TASK', oTheTask)
          } else {
            commit('SET_ACTIVE_TASK', null)
            resolve('task not found');
          }
        } else {
          commit('SET_ACTIVE_TASK', null)
          resolve('active task null');
        }
      })
    },

    setActivePart: function({ commit, state, getters }, id) {
      if (id) {
        var oThePart = getters.getPartByID(id);
        if (oThePart) {
          commit('SET_ACTIVE_PART', oThePart);
        }
      }
    },

    setActiveTool: function({ commit, state, getters }, id) {
      if (id) {
        var oTheTool = getters.getToolByID(id);
        if (oTheTool) {
          commit('SET_ACTIVE_TOOL', oTheTool);
        }
      }
    },

    setActiveQuiz: function({ commit, state, dispatch, getters }, payload) {
      return new Promise((resolve, reject) => {
        console.log('setActiveQuiz', payload.to);
        if (payload && payload.to) {
          var quiz = getters.getQuizByID(payload.to);
          payload.type = 'quiz';
          if (quiz) {
            dispatch('loadActiveQuizTasks', quiz).then(() => {
              var oTheTasks = getters.getActiveQuizTasks;
              console.log('loadActiveQuizTasks .then', oTheTasks);
              // return result;
              // tasks[Object.keys(tasks)[0]
              // dispatch('setActiveTask', Object.keys(oTheTasks)[0]).then(res => {
              //   resolve(id)
              // })
            }).then(() => {
              dispatch('setCourseTransitionName', payload).then(() => {
                commit('SET_ACTIVE_QUIZ', quiz)
                resolve('quiz all set');
              })
            })

          } else {
            commit('SET_ACTIVE_QUIZ', null)
            resolve('quiz not found');
          }
        } else {
          commit('SET_ACTIVE_QUIZ', null)
          resolve('active quiz null');
        }

      })
    },

    loadActiveQuizTasks: function({ commit, state }, quiz) {
      return new Promise((resolve, reject) => {
        var aTheTasks = {};
        quiz.tasks.forEach((item, i) => {
          var itemID = 'task_' + i;
          var oTheTaskObject = {
            id: itemID,
            task: item,
            answer: null,
            correct: null,
          };
          aTheTasks[itemID] = oTheTaskObject;
          // aTheTasks.push(oTheTask);
        });
        commit('LOAD_ACTIVE_QUIZ_TASKS', aTheTasks)
        resolve(quiz)
      })
    },

    updateTroubleshootingStack: function({ commit, getters }, stack) {
      commit('SET_TROUBLESHOOTING_STACK', stack);
    },

    setCourseTransitionName: function({ commit, state, getters }, oThePayload) {
      console.log(oThePayload);
      return new Promise((resolve, reject) => {
        var transitionName = 'fade';
        var activeCourse = getters.getActiveCourse;
        var fromIndex = null;
        var toIndex = null;
        var aTheItems;
        var courseMode = getters.getCourseMode;

        // if (oThePayload.type !== courseMode) {
        //   switch (oThePayload.type) {
        //     case 'lesson':
        //       transitionName = 'fade-right';
        //       break;
        //     case 'quiz':
        //       transitionName = 'fade-left';
        //       break;
        //   }
        // }
        //
        // if (oThePayload.from && oThePayload.to) {
        //
        //   if (oThePayload.type === courseMode) {
        //     if (oThePayload.type === 'lesson') {
        //       aTheItems = activeCourse.lessons;
        //     } else if (oThePayload.type === 'quiz') {
        //       aTheItems = activeCourse.quizes;
        //     }
        //
        //   } else if (oThePayload.type === 'task') {
        //     aTheItems = Object.keys(getters.getActiveQuizTasks);
        //   }
        //
        //   if (aTheItems) {
        //     fromIndex = aTheItems.indexOf(oThePayload.from);
        //     toIndex = aTheItems.indexOf(oThePayload.to);
        //
        //     if (aTheItems && typeof fromIndex === 'number' && fromIndex >= 0 && typeof toIndex === 'number' && toIndex >= 0) {
        //       if (fromIndex > toIndex) {
        //         transitionName = 'fade-right';
        //       } else {
        //         transitionName = 'fade-left';
        //       }
        //     }
        //   }
        // }

        console.log(transitionName);

        commit('SET_COURSE_SUBVIEW_TRANSITION_NAME', transitionName)
        resolve()
      })
    },

    updateQuizAnswer: function({ commit, state, getters }, payload) {
      var oTheTask = getters.getActiveQuizTasks[payload.task];
      var oTheAnswer = oTheTask.task.options[payload.answer];
      if (oTheTask) {
        commit('UPDATE_QUIZ_TASK', { task_id: oTheTask.id, answer_index: payload.answer, is_correct: oTheAnswer.is_correct });
      }
    },

    finishQuiz: function({ commit, state, dispatch, getters }) {
      var oTheQuiz = getters.getActiveQuiz;
      var oTheTasks = getters.getActiveQuizTasks;
      var aTheTaskIDs = Object.keys(oTheTasks);
      var iTheWeight = 100 / aTheTaskIDs.length;
      var iTheCorrectAnswers = 0;

      // hvis vi skal lagre hva brukeren svarte på hvert spørsmål, gjør noe med det her (eller bare vis svarene fra oTheTasks)
      // aTheAnswersGiven = {};

      aTheTaskIDs.forEach((id, i) => {
        // aTheAnswersGiven[i] =
        if (oTheTasks[id].correct) {
          iTheCorrectAnswers += 1;
        }
      });

      console.log(iTheCorrectAnswers);

      var iTheGrade = iTheWeight * iTheCorrectAnswers;

      var oTheNewProgress = JSON.parse(JSON.stringify(state.user.progress))
      var oTheCourse = oTheNewProgress[getters.getActiveCourse.id];
      if (!oTheCourse.quizes) {
        oTheCourse.quizes = {};
      }

      if (!oTheCourse.quizes[oTheQuiz.id]) {
        oTheCourse.quizes[oTheQuiz.id] = {};
      }

      var oTheQuizToUpdate = oTheCourse.quizes[oTheQuiz.id];

      if (oTheQuizToUpdate) {
        oTheQuizToUpdate.grade = iTheGrade;
        if (iTheGrade >= oTheQuiz.passing_grade) {
          oTheQuizToUpdate.passed = true;
          console.log('you passed');
          // console.log(state.user.progress);
        } else {
          oTheQuizToUpdate.passed = false;
          console.log('you failed');
        }
        oTheQuizToUpdate.correct_answers = iTheCorrectAnswers;
        oTheCourse.updated = new Date();
        dispatch('updateUserData', { key: 'progress', value: oTheNewProgress, send: true })
        dispatch('setQuizResultsBool', true);
      }
    },

    setQuizResultsBool: function({ commit }, bool) {
      commit('SHOW_QUIZ_RESULTS', bool);
    },

    setQuizReviewBool: function({ commit }, bool) {
      commit('SHOW_QUIZ_REVIEW', bool);
    },

    setCourseMode: function({ commit, state, dispatch, getters }, payload) {
      commit('SET_COURSE_MODE', payload.mode);
      // if (payload.mode === 'quiz') {
      //   var activeQuiz = getters.getActiveQuiz;
      //   var tasks = activeQuiz.tasks;
      //   var firstTask = tasks[Object.keys(tasks)[0]];
      //   console.log('QUIZQUIZ');
      //   console.log(firstTask);
      //   dispatch('setActiveTaskIndex', firstTask).then(result => {
      //     commit('SET_COURSE_MODE', payload.mode);
      //   })
      //
      //   // dispatch('setActiveTask', firstTask).then(result => {
      //   //
      //   // })
      // }
      // if (payload.mode === 'lesson') {
      //   commit('SET_COURSE_MODE', payload.mode);
      // }
    },

    setCourseMenuOpen: function({ commit, getters }, bool) {
      commit('SET_COURSE_MENU_MODE', bool);
    },

    registerModal: function({ commit, state, getters }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_MODAL', { id: payload, state: 'closed' });
        // console.log('modal set');
        resolve('modal set');
      })
    },

    openModal: function({ commit, state, dispatch, getters }, payload) {
      var oTheOptions = { disableFocus: true };
      // console.log(payload);
      if (payload.id) {
        if (!state.modals[payload.id]) {
          // console.log('modal missing');
          dispatch('registerModal', payload.id).then(result => {
            // console.log('modal registered');
            commit('SET_MODAL', { id: payload.id, state: 'open' });
            window.MicroModal.show(payload.id, oTheOptions);
          })
        } else {
          commit('SET_MODAL', { id: payload.id, state: 'open' });
          window.MicroModal.show(payload.id, oTheOptions);
        }
      }
    },

    closeModal: function({ commit, state, dispatch, getters }, payload) {
      // console.log(payload);
      if (payload.id) {
        if (!state.modals[payload.id]) {
          dispatch('registerModal', payload.id).then(result => {
            commit('SET_MODAL', { id: payload.id, state: 'closing' });
            setTimeout(() => {
              window.MicroModal.close(payload.id);
              commit('SET_MODAL', { id: payload.id, state: 'closed' });
            }, payload.timing);
          })
        } else {
          commit('SET_MODAL', { id: payload.id, state: 'closing' });
          setTimeout(() => {
            window.MicroModal.close(payload.id);
            commit('SET_MODAL', { id: payload.id, state: 'closed' });
          }, payload.timing);
        }
      }
    },

    setMetaThemeColor: function({ state }, payload) {
      var metaThemeColor = document.querySelector('meta[name=theme-color]');
      if (payload === 'course') {
        metaThemeColor.setAttribute('content', '#FED141');
      } else {
        metaThemeColor.setAttribute('content', '#405C6A');
      }
    }
  },
  modules: {
    cachingFunctions: cachingFunctions,
  }
})

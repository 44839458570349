export default {
  data: function() {
    return {}
  },
  methods: {
    getMediaSource: function(str) {
      // return new Promise((resolve, reject) => {})
      var sTheLocalPath = 'https://repair.bright-products.com/';
      var imgKey = sTheLocalPath + str;
      var srcObj = this.$store.getters.getImgByUrl(imgKey);
      if (srcObj) {
        return srcObj.url
      } else {
        return null
      }
    }
  }
}

<template>
  <header class="app-header" :class="{ '-course': activeCourse }">
    <nav class="navbar">
      <!-- <button type="button" class="navbar-toggle" ref="navbarToggle" id="navbarToggle" name="navbarToggle" @click="toggleMenu">
        <span class="sr-only">menu </span>
        <i class="icon">
          <span v-if="mainMenuOpen">O</span>
          <span v-else>X</span>
        </i>
      </button> -->

      <div class="navbar-inner -course" v-if="activeCourse">
        <div class="navbar-left">
          <router-link class="back-link" :to="{ name: 'Variant', params: { productID: activeProduct.id, variantID: activeVariant.id } }">
            <span class="sr-only">Bright</span>
            <icon graphic="chevron" class="-left"></icon>
          </router-link>
        </div>

        <h1 class="title">{{ _(activeCourse.title) | capitalise }}</h1>

        <div class="navbar-right">
          <button type="button" class="course-menu-toggle" @click="toggleCourseMenu">
            <span class="sr-only">menu </span>
            <icon graphic="course-hamburger"></icon>
          </button>
        </div>
      </div>

      <div class="navbar-inner -default" v-if="!activeCourse">
        <div class="navbar-left">
          <router-link class="home-link" :to="{ name: 'Home', params: {} }">
            <span class="sr-only">Bright</span>
            <icon graphic="logo"></icon>
          </router-link>
          <span class="tag-line">Repair & refurbishment</span>
        </div>

        <div class="navbar-right">
          <div class="modal menu-container" :class="menuClass" id="mainMenu" aria-hidden="true">
            <button type="button" class="navbar-toggle" @click="showUpdateModal" v-if="$route.name !== 'Profile'">
              <span class="sr-only">menu </span>
              <icon :graphic="hamburgerIcon"></icon>
            </button>
            <!-- <div class="modal__overlay" tabindex="-1" @click="toggleMainMenu">
            </div> -->
            <div class="modal__containe navbar-content" role="dialog" aria-modal="true" aria-labelledby="navbarToggle">
              <div class="menu">

                <router-link @click="closeMenu" :to="{ name: 'Home', params: {} }">Home</router-link>
                <!-- <router-link :to="{ name: 'Video', params: {} }">Video</router-link> -->
                <router-link @click="closeMenu" :to="{ name: 'Profile', params: {} }">Profile</router-link>

                <a href="#" class="modal-open-link" @click="showUpdateModal">Update</a>

              </div>
            </div>
          </div>

          <!-- <router-link @click="closeMenu" v-if="$route.name !== 'Profile'" class="user-link" :to="{ name: 'Profile', params: {} }">
            <span class="sr-only">{{ _('profile') }}</span>
            <icon graphic="user"></icon>
          </router-link> -->
          <!-- <router-link v-else class="user-link" :to="{ name: 'Home', params: {} }">
            <span class="sr-only">{{ _('home') }}</span>
            <icon graphic="hamburger-menu-open"></icon>
          </router-link> -->
        </div>
      </div>

      <!-- <div class="navbar-inner subnavbar" v-if="routeName === 'Troubleshooting'">
        <transition :name="subnavbarTransition">

          <router-link class="back-link" :to="{ name: 'Product', params: { id: activeProduct.id, variantID: activeVariant.id } }">
            <icon graphic="chevron" class="-left"></icon>
            <span class="link-text">{{ _(activeVariant.title) | capitalise }}</span>
          </router-link>

        </transition>
      </div> -->
    </nav>

  </header>
</template>

<script type="text/javascript">
import ModalMixin from '@/mixins/modal.js'
import TranslationMixin from '@/mixins/translation.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'

// import Icon from '@/components/Icon.vue'

export default {
  name: 'AppHeader',
  data: function() {
    return {
      courseMenuIsOpen: false,
      // mainMenuOpen: false,
    }
  },
  mixins: [ModalMixin, TranslationMixin, ActiveItemsMixin],
  // components: { Icon },
  computed: {
    menuState: function() {
      return this.$store.getters.getModalState('mainMenu');
    },
    hamburgerIcon: function() {
      if (this.menuState === 'open') {
        return 'hamburger-menu-open'
      }
      return 'hamburger-menu-closed'
    },
    courseMenuOpen: function() {
      return this.$store.getters.getCourseMenuOpen;
    },
    menuClass: function() {
      if (this.menuState === 'closing') {
        return '-closing'
      }
      return ''
    },
    backgroundColor: function() {
      if (this.mainMenuOpen) {
        return '#669900'
        // return '#99e600'
      } else {
        return '#669900'
      }
    },
    foregroundColor: function() {
      return 'white'
    },
    headerStyles: function() {
      return {
        '--color-header-foreground': this.foregroundColor,
        '--color-header-background': this.backgroundColor,
      }
    },
    routeName: function() {
      console.log('ROUTE NAME', this.$route.name);
      return this.$route.name;
    },
    subnavbarTransition: function() {
      if (this.routeName === 'Troubleshooting') {
        return 'fade-left';
      } else {
        return 'fade-right';
      }
    },
    // activeProduct: function() {
    //   return this.$store.getters.getActiveProduct
    // },
    // activeVariant: function() {
    //   return this.$store.getters.getActiveVariant
    // },
    // activeCourse: function() {
    //   return this.$store.getters.getActiveCourse
    // },
    courseOngoing: function() {
      if (this.activeCourse) {
        return true
      }
      return false
    },
    activeTroubleshooting: function() {
      return this.$store.getters.getActiveTroubleshooting
    },
    troubleshootingStack: function() {
      return this.$store.getters.getTroubleshootingStack
    },
  },
  methods: {
    openMenu: function() {
      // this.mainMenuOpen = true;
      this.openModal('mainMenu');
    },
    closeMenu: function() {
      // this.mainMenuOpen = false;
      this.closeModal('mainMenu');
    },
    toggleMenu: function() {
      if (this.menuState === 'open') {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    },
    toggleCourseMenu: function() {
      this.$store.dispatch('setCourseMenuOpen', !this.courseMenuOpen)
      // this.courseMenuIsOpen = !this.courseMenuIsOpen;
      // this.$store.dispatch('setCourseMenuOpen', this.courseMenuIsOpen)
    },
    showUpdateModal: function() {
      this.closeMenu();
      this.openModal('modalUpdate');
    },
    getItemKey(prefix = 'item_opt_', item) {
      return prefix + item.id
    },
  },
  created() {
    // this.$store.dispatch('registerModal', 'mainMenu');
    // this.$router.beforeEach((to, from, next) => {
    //   this.closeModal('mainMenu');
    //   next();
    // })
  }
}
</script>

<style lang="scss">
.app-header {
  width: 100%;
  background-color: var(--color-blue-2);
  color: var(--color-bright-green-1);
  // padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  transition: background-color .3s ease 0s;
  // &:before {
  //   content: '';
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  // }
  &.-course {
    background-color: var(--color-yellow-1);
    color: var(--color-white);
    min-height: 0;
    transition: min-height .3s ease 0s;
  }
  // &.-troubleshooting {
  //   --subnavbar-height: 4rem;
  //   &:before {
  //     // content: '';
  //     // position: fixed;
  //     // top: 0;
  //     // left: 0;
  //     width: 100vw;
  //     min-height: calc(var(--navbar-height) + var(--subnavbar-height));
  //     z-index: 1;
  //     background-color: var(--color-blue-3);
  //   }
  // }
  .navbar {
    pre {
      font-size: .4em;
    }
    display: flex;
    flex-direction: column;
    font-size: 2.2rem;
    @include media-sm {
      font-size: 2.8rem;
    }
    .navbar-inner {
      // flex: 1 0 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      .navbar-left {
        @include media-sm {
          display: flex;
          .home-link {
            padding-right: var(--gutter-width);
          }
          .tag-line {
            padding-top: .3em;
            padding-left: var(--gutter-width);
            line-height: .9;
          }
        }
      }
      .navbar-right {
        display: flex;
      }
      .back-link,
      .home-link {
        .svg-stroke {
          stroke: var(--color-white);
        }
      }
      .logo {
        display: flex;
        flex-direction: column;
        width: 12rem;
        .svg-fill {
          fill: var(--color-bright-green-1);
        }
      }
      .title {
        margin: 0;
        line-height: 1;
        padding-top: .3em;
      }
      .back-link,
      .home-link,
      .tag-line {
        align-items: center;
        padding: 0 1rem;
      }
      .back-link,
      .home-link {
        display: flex;
      }
      .tag-line {
        display: none;
        @include media-sm {
          display: flex;
          border-left: .1rem solid;
        }
      }
      .navbar-toggle,
      .user-link,
      .course-menu-toggle {
        position: relative;
        z-index: 301;
        appearance: none;
        background: transparent;
        border: 0px none;
        padding: 0 1rem;
        height: 4rem;
        display: flex;
        align-items: center;
        .svg-stroke {
          stroke: var(--color-white);
          stroke-width: 2;
        }
        .svg-fill {
          fill: var(--color-white);
        }
      }
      &.subnavbar {
        position: relative;
        min-height: 4rem;
        color: var(--color-white);
        background-color: var(--color-blue-3);
        .back-link {
          display: flex;
          align-items: center;
          color: var(--color-white);
          text-decoration: none;
          .icon {
            width: .9rem;
            margin-right: 1rem;
          }
          .link-text {
            padding-top: .3em;
          }
          .svg-stroke {
            stroke: var(--color-white);
            stroke-width: 4.5;
          }
        }
      }
    }
  }
  .modal__overlay {
    z-index: 80;
  }
  .menu-container {
    .icon {

    }
    &[aria-hidden="true"] {

    }
    &[aria-hidden="false"] {

    }
    .navbar-content {
      z-index: 90;
      position: absolute;
      top: 0;
      left: 100%;
      width: 100vw;
      max-width: 40rem;
      transition: transform .3s ease 0s;
      height: 100vh;
      overflow-y: auto;
      background-color: var(--color-header-background);
      padding: 10rem 2rem;
      will-change: transform;
      transform: translateX(0);
      display: block;
      visibility: hidden;
      .menu {
        display: flex;
        flex-direction: column;
      }
    }
    &.is-open {
      .navbar-content {
        visibility: visible;
        transform: translateX(-100%);
      }
    }
    &.-closing {
      .navbar-content {
        visibility: visible;
        transform: translateX(0%);
      }
    }
  }
  .menu-inner {
    z-index: 90;
    position: relative;
    background-color: var(--color-header-background);
    color: var(--color-header-foreground);
  }
}
</style>

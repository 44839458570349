<template>
  <main class="view profile">
    <div class="view-inner">

      <nav class="subnavbar">
        <router-link v-if="activeProduct && activeVariant" class="back-link" :to="{ name: 'Variant', params: { productID: activeProduct.id, variantID: activeVariant.id } }">
          <icon graphic="chevron" class="-left"></icon>
          <span class="link-text">{{ _(activeVariant.title) | capitalise }}</span>
        </router-link>

        <router-link v-else-if="activeProduct" class="back-link" :to="{ name: 'Product', params: { productID: activeProduct.id } }">
          <icon graphic="chevron" class="-left"></icon>
          <span class="link-text">{{ _(activeProduct.title) | capitalise }}</span>
        </router-link>

        <router-link v-else class="back-link" :to="{ name: 'Home', params: {} }">
          <icon graphic="chevron" class="-left"></icon>
          <span class="link-text">{{ _('home') | capitalise }}</span>
        </router-link>
      </nav>

      <header class="view-header">
        <h1 class="title">{{ _('profile_heading') }}</h1>
      </header>

      <section class="content-block personalia">
        <dl>
          <div class="list-item">
            <dt class="key">{{ _('username') | capitalise }}:</dt> <dd class="value">{{ user.username }}</dd>
          </div>
          <div class="list-item">
            <dt class="key">{{ _('password') | capitalise }}:</dt> <dd class="value">{{ user.password }}</dd>
          </div>
        </dl>

        <!-- <div class="item">
          <label for="userFirstname">{{ _('firstname') | capitalise }}</label>
          <input type="text" name="userFirstname" :value="user.first_name" data-key="first_name" @input="updateUserData">
        </div>
        <div class="item">
          <label for="userLastname">{{ _('lastname') | capitalise }}</label>
          <input type="text" name="userLastname" :value="user.last_name" data-key="last_name" @input="updateUserData">
        </div>
        <div class="item">
          <label for="userCountry">{{ _('country') | capitalise }}</label>
          <select class="" name="userCountry" data-key="country" @change="updateUserData">
            <option disabled value="Select country">{{ _('select_country') | capitalise }}</option>
            <option :value="country.name" :selected="user.country === country.name" v-for="country in countries" :key="getItemKey('country_', country)">{{ country.label }}</option>
          </select>
        </div> -->

        <!-- <div class="item">
          <label for="appLanguageSetting">{{ _('language') | capitalise }}</label>
          <select class="" name="appLanguageSetting" data-key="language" @change="setLocale">
            <option disabled value="Select country">Select language</option>
            <option :value="lang.id" :selected="lang.id === locale.id" v-for="lang in locales" :key="getItemKey('language_ ', lang)">{{ lang.label }}</option>
          </select>
        </div>

        <div class="item">
          <label for="userBirthYearSetting">{{ _('year_of_birth') | capitalise }}</label>
          <select class="" name="userBirthYearSetting" data-key="birthYear" @change="updateUserData">
            <option disabled value="Select year of birth">Select year of birth</option>
            <option v-for="year in yearOptions" :value="year" :selected="year === user.birthYear" :key="'year_' + year">{{ year }}</option>
          </select>
        </div> -->

      </section>

      <section class="content-block">
        <button type="button" class="course-button -outline" @click="openDosAndDontsModal">
          <span>{{ _('dos_and_donts') | capitalise }}</span>
        </button>
      </section>

      <section class="content-block course-progress" v-if="userHasProgress">
        <h2 class="title">{{ _('user_courses') | capitalise }}</h2>

        <div class="items">
          <template v-for="product in products">

            <template v-for="courseid in product.variant.courses">
              <course-link v-if="checkUserProgress(courseid)"
              :key="'course_link_' + product.variant.id + '_' + courseid"
              :productid="product.productID"
              :variantid="product.variant.id"
              :courseid="courseid"></course-link>
            </template>

          </template>
        </div>
      </section>

      <section class="content-block">
        <button class="course-button -outline" @click="openLogoutModal">
          <span>{{ _('log_out') | capitalise }}</span>
        </button>
      </section>

    </div>
  </main>
</template>

<script>
import ModalMixin from '@/mixins/modal.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'
import TranslationMixin from '@/mixins/translation.js'
import CourseLink from '@/components/fragments/CourseLink.vue'

export default {
  name: 'profile',
  data: function() {
    return {
      countries: [
        {
          id: 'no',
          label: 'Norway',
          name: 'norway',
        },
        {
          id: 'us',
          label: 'US of States',
          name: 'united states',
        },
        {
          id: 'dk',
          label: 'Denmark',
          name: 'denmark',
        },
        {
          id: 'na',
          label: 'Narnia',
          name: 'narnia',
        },
        {
          id: 'sp',
          label: 'Space',
          name: 'space',
        },
      ],
    }
  },
  mixins: [ModalMixin, ActiveItemsMixin, TranslationMixin],
  components: {
    CourseLink,
  },
  computed: {
    user: function() {
      return this.$store.getters.getUser
    },
    yearOptions: function() {
      var firstYear = 1900;
      var aTheYears = [];
      var currentYear = new Date().getFullYear();
      while (currentYear >= firstYear) {
        aTheYears.push(currentYear);
        currentYear -= 1;
      }
      return aTheYears;
    },
    allProducts: function() {
      return this.$store.getters.getProducts
    },
    allCourses: function() {
      return this.$store.getters.getCourses
    },
    products: function() {
      var aTheVariants = [];
      Object.keys(this.allProducts).forEach((id, i) => {
        if (this.allProducts[id].variants) {
          this.allProducts[id].variants.forEach((variant, i) => {
            var oTheVariant = this.$store.getters.getVariantByID(variant);
            if (oTheVariant) {
              aTheVariants.push({ productID: id, variant: oTheVariant });
            }
          });
        }
      });
      return aTheVariants
    },
    userHasProgress: function() {
      let ret = false;
      if (this.user && this.user.progress) {
        Object.keys(this.allCourses).forEach((id, i) => {
          if (this.user.progress[id]) {
            ret = true;
          }
        });
      }
      return ret
    }
  },
  methods: {
    checkUserProgress: function(id) {
      if (this.user.progress[id]) {
        return true
      } else {
        return false
      }
    },
    updateUserData: function(event) {
      console.log(event.target);
      console.log(event.target.value);
      this.$store.dispatch('updateUserData', { key: event.target.getAttribute('data-key'), value: event.target.value })
    },
    getItemKey(prefix = 'item_opt_', item) {
      return prefix + item.id
    },
    openLogoutModal: function() {
      this.openModal('modalLogout');
    },
    openDosAndDontsModal: function() {
      this.openModal('dosAndDonts');
    }
  }
}

</script>

<style lang="scss">
  .view.profile {
    .view-header {
      --gutter-width: 2rem;
    }
    .personalia {
      .list-item {
        display: flex;
        flex-wrap: wrap;
        .key {
          margin-right: .4em;
        }
      }
    }
    .content-block {
      margin-right: var(--gutter-width);
      margin-left: var(--gutter-width);
      & + .content-block {
        margin-top: var(--gutter-width);
      }
    }
    .course-progress {
      > .title {
        color: var(--color-blue-1);
      }
      > .items {
        @include link-list-items;
        grid-gap: 0;
      }
    }
    .course-link {
      @include product-link-style;
      & + .course-link {
        border-top: .1rem solid var(--color-border-grey);
      }
    }
  }
</style>

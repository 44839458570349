import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Tekst from '../views/Tekst.vue'
import Video from '../views/Video.vue'
import Profile from '../views/Profile.vue'

import ScrollMixin from '../mixins/scroll.js'

Vue.use(VueRouter)

var sTheBaseUrl = '/index.html';
sTheBaseUrl = '';

const routes = [
  {
    path: sTheBaseUrl + '/video',
    name: 'Video',
    component: () => import(/* webpackChunkName: "video" */ '../views/Video.vue')
  },
  {
    path: sTheBaseUrl + '/',
    name: 'Home',
    meta: { transitionName: 'slide' },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    // beforeEnter: (to, from, next) => {
    //   store.dispatch('setSpinner', store.getters.getSpinner + 1);
    //   next();
    // }
  },
  {
    path: sTheBaseUrl + '/profile',
    name: 'Profile',
    meta: { transitionName: 'slide-left' },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: sTheBaseUrl + '/product/:productID',
    name: 'Product',
    meta: { transitionName: 'slide' },
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('setActiveProduct', to.params.productID).then(result => {
        next();
      }).catch(err => {
        console.log(err);
        next('/');
      })
    },
  },
  {
    path: sTheBaseUrl + '/product/:productID/variant/:variantID',
    name: 'Variant',
    meta: { transitionName: 'slide' },
    component: () => import(/* webpackChunkName: "product" */ '../views/Variant.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('setActiveProduct', to.params.productID).then(result => {
        store.dispatch('setActiveVariant', { productID: to.params.productID, variantID: to.params.variantID }).then(result => {
          next();
        })
      }).catch(err => {
        console.log(err);
        next('/');
      })
    },
  },
  {
    name: 'Parts',
    path: sTheBaseUrl + '/product/:productID/variant/:variantID/parts',
    component: () => import(/* webpackChunkName: "about" */ '../views/Parts.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('setActiveProduct', to.params.productID).then(result => {
        store.dispatch('setActiveVariant', { productID: to.params.productID, variantID: to.params.variantID }).then(result => {
          next();
        })
      }).catch(err => {
        console.log(err);
        next('/');
      })
    },
  },
  {
    path: sTheBaseUrl + '/product/:productID/variant/:variantID/troubleshooting',
    name: 'Troubleshooting',
    meta: { transitionName: 'slide' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Troubleshooting.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('setActiveProduct', to.params.productID).then(result => {
        store.dispatch('setActiveVariant', { productID: to.params.productID, variantID: to.params.variantID }).then(result => {
          return result
        })
        return result
      }).then(result => {
        next();
      }).catch(err => {
        console.log(err);
        next('/');
      })
    }
  },
  {
    path: sTheBaseUrl + '/product/:productID/variant/:variantID/course/:courseID',
    name: 'Course',
    meta: { transitionName: 'slide' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Course.vue'),
    children: [
      {
        name: 'LessonOverview',
        path: '',
        component: () => import(/* webpackChunkName: "product" */ '../views/Lesson.vue'),
      },
      {
        name: 'Lesson',
        path: 'lesson',
        // meta: { transitionName: 'slide' },
        // path: 'lesson/:lesson',
        component: () => import(/* webpackChunkName: "product" */ '../views/Lesson.vue'),
        // beforeEnter: (to, from, next) => {
        //   store.dispatch('setActiveLesson', to.params.lessonID).then(result => {
        //     var aTheLessons = store.getters.getCourseByID(to.params.courseID).lessons;
        //     console.log('lessons_ ', aTheLessons);
        //     store.dispatch('setCourseMenuOpen', false)
        //     next();
        //   });
        // },
        // beforeEnter: (to, from, next) => {
        //   console.log('LOAD LESSON', to.params.lesson);
        //   store.dispatch('setActiveLesson', to.params.lesson).then(result => {
        //     console.log('lesson active', to.params.lesson);
        //     next();
        //   }).catch(err => {
        //     console.log(err);
        //     next('/');
        //   })
        // },
      },
      // {
      //   name: 'QuizOverview',
      //   path: 'quiz',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/Quiz.vue'),
      // },
      {
        // path: sTheBaseUrl + '/quiz/:id',
        name: 'Quiz',
        path: 'quiz',
        // meta: { transitionName: 'slide-fade' },
        // path: 'quiz/:quiz',
        component: () => import(/* webpackChunkName: "about" */ '../views/Quiz.vue'),
        // beforeEnter: (to, from, next) => {
        //   console.log(to.params);
        //   store.dispatch('setActiveQuiz', to.params.quizID).then(result => {
        //     console.log('FUCK YOU!!!', result);
        //     next();
        //   }).catch(err => {
        //     console.log(err);
        //     next('/');
        //   })
        // },
      },
    ],
    beforeEnter: (to, from, next) => {
      store.dispatch('setActiveProduct', to.params.productID).then(result => {
        console.log('active Product set', to.params);
        return result
      }).then(() => {
        store.dispatch('setActiveVariant', { productID: to.params.productID, variantID: to.params.variantID }).then(result => {
          console.log('active variant set', to.params);
          // return result
        })
      }).then(() => {
        store.dispatch('setActiveCourse', to.params.courseID).then(result => {
          console.log('active course set', to.params);
          // return result
        });
      }).then(() => {
        store.dispatch('setActiveLesson', null).then(result => {
          // next();
        });
      }).then(() => {
        store.dispatch('setActiveTask', null).then(result => {
          // window.scrollTo(0, 0);
          next();
        });
      }).catch(err => {
        console.log(err);
        next('/');
      })
    },
  },
  {
    path: '*', // or '/index.html'
    beforeEnter: (to, from, next) => {
      next('/')
    }
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.NODE_ENV === 'production'
  //   ? '/'
  //   : '/',
  routes
})

router.onError((err) => {
  console.log(err);
  console.log('ROUTER ERROR');
})

// router.beforeEach((to, from, next) => {
//   // console.log('router doing routing');
//   // console.log(to);
//   next();
// })
router.afterEach((to, from) => {
  if (to && from) {
    // console.log('UNDEFINED, FUCKER', document.documentElement, document.body);
    ScrollMixin.methods.animateScroll(document.documentElement, 0, 500)
  }
  // window.scrollTo(0, 0);
})

export default router

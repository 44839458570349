<template>
  <section class="products section-block">
    <h2 class="title">{{ _('product_plural') | capitalise }}</h2>

    <div class="items">

      <router-link class="item product" v-for="product in products" :to="{ name: 'Product', params: { productID: product.id } }" :key="product.id">
        <div class="image-column">
          <div class="image-container">
            <image-component v-if="product.image" :url="product.image.url" alt="et bilde her, altså" v-on:image_loaded="resourceLoaded"></image-component>
          </div>
        </div>
        <div class="text-column">
          <div class="inner">
            <h3 class="title">{{ _(product.title) }}</h3>
            <p class="description" v-html="_(product.description)" v-if="product.description && product.description != 0"></p>
          </div>
          <div class="item-icon">
            <icon graphic="chevron"></icon>
          </div>
        </div>
        <!-- <div class="icon-column">
          <icon graphic="chevron"></icon>
        </div> -->
      </router-link>

    </div>

  </section>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'

export default {
  name: 'Products',
  data: function() {
    return {
      dataLoaded: false,
    }
  },
  mixins: [TranslationMixin],
  computed: {
    products: function() {
      var ret = this.$store.getters.getProducts;
      if (ret) {
        return ret
      }
      return false
    }
  },
  methods: {
    setItemKey(id) {
      return 'product_' + id
    },
    resourceLoaded(url) {
      console.log('PRODUCT image loaded', url);
    }
  },
  created() {
    console.log('PRODUCTS created', this.products);
  },
  mounted() {
    console.log('PRODUCTS mounted', this.products);
    this.$nextTick(() => {
      console.log('PRODUCTS mounted nexttick', this.products);
    })
  },
  updated() {
    this.$nextTick(() => {
      console.log('PRODUCTS updated nexttick', this.products);
    })
  }
}
</script>

<style lang="scss">
  .products {
    // padding: 0 var(--gutter-width);
    .items {
      @include link-list-items;
      .product {
        @include link-list-item;
        .image-column {
          padding: 1rem;
          @include media-sm {
            padding: 2rem 1rem;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="view video">
    <h1>Tester videofiler</h1>

    <video-component :url="src" controls="controls" posterImage="img/Poster.png"></video-component>

    <video-component url="videos/testvideo.mp4" controls="controls" posterImage="img/Poster.png"></video-component>

  </div>
</template>

<script>

import MediaMixin from '../mixins/media.js'

// import VideoElement from '../components/VideoElement.vue'

export default {
  name: 'VideoView',
  components: {
    // VideoElement,
  },
  mixins: [MediaMixin],
  data: function() {
    return {
      videoSrc: null,
      // src: require('../assets/videos/milk.mp4'),
      src: 'videos/milk.mp4',
      key: 'milk.mp4',
      keys: ['milk.mp4', 'testvideo.mp4'],
      // videoSources: [
      //   {
      //     key: 'milk.mp4',
      //     src: require('../assets/videos/milk.mp4'),
      //     type: 'video/mp4',
      //   },
      //   {
      //     key: 'testvideo.mp4',
      //     src: require('../assets/videos/testvideo.mp4'),
      //     type: 'video/mp4',
      //   },
      // ],
      // videoOptions: {
      //   sources: [{
      //     type: 'video/mp4',
      //     src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
      //   }],
      // }
    }
  },
  methods: {
    async checkStorageLimit() {
      if (navigator.storage && navigator.storage.estimate) {
        const quota = await navigator.storage.estimate();
        // quota.usage -> Number of bytes used.
        // quota.quota -> Maximum number of bytes available.
        const percentageUsed = (quota.usage / quota.quota) * 100;
        console.log(`You've used ${percentageUsed}% of the available storage.`);
        const remaining = quota.quota - quota.usage;
        console.log(`You can write up to ${remaining} more bytes.`);
      }
    },
    toggleVideo() {
      for (let i = 0; i < this.keys.length; i++) {
        if (this.keys[i] === this.key) {
          continue;
        } else {
          this.key = this.keys[i];
          this.$store.dispatch('setActiveVideo', this.key);
          return;
        }
      }
    },
  },
  mounted() {
    // this.checkStorageLimit();
    // this.$store.dispatch('setActiveVideo', this.key);
    console.log('mounted');

    // this.setVideoSrc();
    // console.log('sources: ', this.videoSources);
    // this.$store.dispatch('storeVideos', this.videoSources);

    // for (var i = 0; i < this.videoSources.length; i++) {
    //   this.$store.dispatch('saveVideo', this.videoSources[i]);
    // }

    // this.videosrc = this.$store.getters.getVideoByKey(this.key, this.src);
    // this.setVideoSrc();
    // if ('caches' in window) {
    //   caches.open('bright_app_cache').then((cache) => {
    //     console.log('found bright_app_cache: ', cache);
    //   }).catch((err) => {
    //     console.log(err);
    //   })
    // }
  },
  watch: {
    videoSrc: function() {

    }
  },
  computed: {
    videoSource: function() {
      // TODO: dropp dette tullet når vi flytter over til WP

      var sTheLocalPath = 'https://repair.bright-products.com/';
      var vidKey = sTheLocalPath + this.src;
      var srcObj = this.$store.getters.getVideoByUrl(vidKey);
      if (srcObj) {
        return srcObj.url
      } else {
        return null
      }
    },
    // videoPoster: function() {
    //   return require('../assets/images/Poster.png');
    // },
  },
}

</script>

<style lang="scss">
.video {
  // width: 100%;
  // max-width: 600px;
  // margin: 0 auto;
  .video-element {
    width: 100%;
  }
}
.video-container {
  height: 0;
  padding-bottom: 75%;
  position: relative;
  img {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>

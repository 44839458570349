export default {
  computed: {
    activeCourse: function() {
      return this.$store.getters.getActiveCourse
    },
    activeProduct: function() {
      return this.$store.getters.getActiveProduct
    },
    activeVariant: function() {
      return this.$store.getters.getActiveVariant
    },
    activeQuiz: function() {
      return this.$store.getters.getActiveQuiz
    },
    activeTask: function() {
      return this.$store.getters.getActiveTask
    },
    activeLesson: function() {
      return this.$store.getters.getActiveLesson
    },
  }
}

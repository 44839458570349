<template>
  <div class="modal slide-up micromodal-slid " :class="modalClasses" :id="modalID" aria-hidden="true">

    <div v-if="modalClass === 'discreet'" class="modal__container" role="dialog" aria-modal="true" :aria-labelledby="labelID" :key="'modal_' + modalID">
      <header class="modal__header">
        <h2 class="modal__title" :id="labelID">
          {{ modalTitle }}
        </h2>
        <button class="modal__close" aria-label="Close modal" @click="closeModal(modalID, transitionTiming)"></button>
      </header>

      <main class="modal__content" id="modal-1-content">
        <slot name="content"></slot>
      </main>

      <footer class="modal__footer">
        <button class="modal__btn" @click="closeModal(modalID, transitionTiming)" aria-label="Close this dialog window">Close</button>
      </footer>
    </div>

    <div v-else-if="modalClass === 'standard'" class="modal__overlay" tabindex="-1" @click="overlayCloseModal(modalID, $event)" :key="'modal_' + modalID">
      <div class="modal__container" role="dialog" aria-modal="true" :aria-labelledby="labelID">
        <header class="modal__header">
          <button class="modal__close" aria-label="Close modal" @click="closeModal(modalID, transitionTiming)" v-show="!preventModalClose" :disabled="preventModalClose"></button>
        </header>

        <main class="modal__content">
          <slot name="content"></slot>
        </main>

      </div>
    </div>

    <div v-else class="modal__overlay" tabindex="-1" :key="'modal_' + modalID" data-micromodal-close>
      <div class="modal__container" role="dialog" aria-modal="true" :aria-labelledby="labelID">
        <header class="modal__header">
          <h2 class="modal__title" :id="labelID">
            {{ modalTitle }}
          </h2>
          <button class="modal__close" aria-label="Close modal" @click="closeModal(modalID, transitionTiming)"></button>
        </header>

        <main class="modal__content" id="modal-1-content">
          <slot name="content"></slot>
        </main>

        <footer class="modal__footer">
          <button class="modal__btn" @click="closeModal(modalID, transitionTiming)" aria-label="Close this dialog window" v-if="!preventModalClose">Close</button>
        </footer>
      </div>
    </div>

  </div>
</template>

<script type="text/javascript">
import ModalMixin from '../mixins/modal.js'
export default {
  name: 'Modal',
  data: function() {
    return {
      transitionTiming: 300,
      closingClass: '',
    }
  },
  mixins: [ModalMixin],
  props: {
    modalID: String,
    modalClass: String,
    modalTitle: String,
    showModal: Boolean,
    allowClose: Boolean,
  },
  computed: {
    labelID: function() {
      return this.modalID + 'Title'
    },
    modalState: function() {
      var s = this.$store.getters.getModalState(this.modalID);
      console.log(s);
      return s;
    },
    modalClasses: function() {
      if (this.modalState === 'closing') {
        return this.modalClass + ' -closing'
      } else {
        return this.modalClass
      }
    },
    preventModalClose: function() {
      if (this.allowClose === false) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    overlayCloseModal: function(id, e) {
      if (this.preventModalClose && e.target.classList.contains('modal__overlay')) {
        e.preventDefault();
      } else if (e.target.classList.contains('modal__overlay')) {
        this.closeModal(id);
      }
      console.log(this.preventModalClose);
    }
  },
  created() {
    this.$store.dispatch('registerModal', this.modalID);
    // window.MicroModal.init();
  }
}
</script>

<style lang="scss">
  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.09);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal__container {
    background-color: #fff;
    // padding: 30px;

    max-width: 500px;
    max-height: calc(100vh - 8rem);
    // border-radius: 4px;
    overflow-y: auto;
    box-sizing: border-box;
    overscroll-behavior-y: contain;
  }

  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    color: #00449e;
    box-sizing: border-box;
  }

  /**************************\
  Demo Animation Style
  \**************************/
  @keyframes mmfadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes mmfadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }

  @keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
  }

  @keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
  }

  @keyframes modalSlideUp {
    from { transform: translateY(15%); }
    to { transform: translateY(0); }
  }

  @keyframes modalSlideDown {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  .micromodal-slide {
    display: none;
  }

  .micromodal-slide.is-open {
    display: block;
  }

  // .micromodal-slide[aria-hidden="false"] .modal__overlay {
  //   animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  // }
  //
  // .micromodal-slide[aria-hidden="false"] .modal__container {
  //   animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  // }
  //
  // .micromodal-slide[aria-hidden="true"] .modal__overlay {
  //   animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  // }
  //
  // .micromodal-slide[aria-hidden="true"] .modal__container {
  //   animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  // }

  .micromodal-slide .modal__container,
  .micromodal-slide .modal__overlay {
    will-change: transform;
  }

  .modal {
    z-index: 300;
    .modal-button {
      @include course-button-style;
      --border-color: var(--color-blue-1);
      --color-background: var(--color-blue-1);
      --color-foreground: var(--color-white);
    }
    .modal__header {
      .modal__close {
        z-index: 10;
        appearance: none;
        background-color: transparent;
        border: 0px none;
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: 2.5rem;
        top: 2rem;
        display: flex;
        &:before,
        &:after {
          content: '';
          width: 2rem;
          height: .2rem;
          position: absolute;
          top: 50%;
          left: 50%;
          background-color: var(--color-blue-1);
        }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
    &.-backdrop-blur {
      .modal__overlay {
        backdrop-filter: blur(2rem);
      }
    }
    &.standard {
      &[aria-hidden="false"] {
        display: block;
        .modal__container {
          animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
        }
        .modal__overlay {
          animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
        }
      }
      &[aria-hidden="true"] {
        display: none;
        .modal__container {
          animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
        }
        .modal__overlay {
          animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
        }
      }
      .modal__container {
        position: relative;
        border-radius: 2rem;
        box-shadow: 0 3.2rem 2.4rem -3rem rgba(113,93,74,0.50);
        background-color: var(--color-pale-yellow);
        color: var(--color-blue-1);
        width: 100%;
        max-width: calc(100vw - (var(--gutter-width) * 2));
        @include media-md {
          max-width: 60rem;
        }
      }
      .modal__content {
        >.image-container {
          z-index: 5;
          height: 0;
          width: 100%;
          padding-bottom: 66.66666%;
          position: relative;
          background-color: var(--color-white);
          > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        >.inner {
          padding: var(--gutter-width);
        }
      }
    }

    &.-image-unconstrained {
      .modal__content {
        >.image-container {
          height: auto;
          padding-bottom: 0;

          img {
            position: relative;
            width: 100%;
            height: auto;
          }
        }
      }
    }

    &.discreet {
      display: block;
      visibility: hidden;
      position: fixed;
      bottom: 2rem;
      left: 2rem;
      .modal__container {
        padding: 1.5rem;
        box-shadow: 0 3.2rem 2.4rem -3rem rgba(113,93,74,0.50);
        // background-color: var(--color-pale-yellow);
        background-color: var(--color-white);
        color: var(--color-blue-1);
        border-radius: 1rem;
        display: block;
        visibility: inherit;
        transition: transform .3s ease 0s, opacity .3s ease 0s;
      }
      // &.is-open {
      //   visibility: visible;
      //   .modal__container {
      //     animation: modalSlideUp .3s cubic-bezier(0, 0, .2, 1);
      //   }
      // }
      // &.-closing {
      //   visibility: visible;
      //   .modal__container {
      //     animation: modalSlideDown .3s cubic-bezier(0, 0, .2, 1);
      //   }
      // }
      &[aria-hidden="false"] {
        visibility: visible;
        .modal__container {
          transform: translateY(0);
          opacity: 1;
        }
        &.-closing {
          .modal__container {
            transform: translateY(100%);
            opacity: 0;
          }
        }
      }
      &[aria-hidden="true"] {
        visibility: hidden;
        .modal__container {
          opacity: 0;
          transform: translateY(100%);
        }
      }
    }
    .modal__container,
    .modal__overlay {
      will-change: transform;
    }
  }
</style>

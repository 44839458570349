<template>
  <i class="icon" :class="graphic">
    <svg v-if="graphic === 'logo'" version="1.1" viewBox="0 0 119.8 20.6">
      <path class="svg-fill" d="M0,0.4h7.7c3.3,0,5.9,2.1,5.9,5.1c0.1,1.8-1,3.4-2.7,4c2.8,0.5,4.3,2.4,4.3,4.9
        c0,3.4-2.7,5.9-6.4,5.9H0V0.4z M7.1,8.4c1.6,0,2.7-1,2.7-2.4c0-1.3-1.1-2.3-2.7-2.3H3.8v4.8H7.1z M8.1,17c1.9,0,3.3-1.2,3.3-2.8
        S10,11.3,8,11.3H3.8V17L8.1,17L8.1,17z M21.7,0.4h8.2c3.8,0,6.9,2.7,6.9,6.3c0,2.5-1.5,4.8-3.9,5.7l5.1,7.9h-4.3l-4.5-7.1h-3.8v7
        h-3.8V0.4z M29.6,10c2.1,0,3.5-1.3,3.5-3.2c0-1.7-1.3-3.1-3-3.1h-4.6V10H29.6z M46.1,15.8c1.3,0,2.5,1,2.5,2.3
        c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5C43.7,16.8,44.8,15.8,46.1,15.8z M45.1,12l-1.2-7.9V0.8c0-0.3,0.2-0.6,0.6-0.6h3.3
        c0.3,0,0.6,0.2,0.6,0.6V4l-1.2,8c-0.1,0.2-0.3,0.4-0.6,0.4h-1C45.4,12.5,45.2,12.3,45.1,12z M65.4,0c2.9,0,5.7,1,7.9,3l-2.5,2.2
        c-1.5-1.3-3.4-2-5.4-1.9c-4.3,0-7.5,3-7.5,7.1s3.2,7,7.5,7c1.8,0,3.6-0.5,5.1-1.7V12h-5.9V8.8h9.5v8c-2.2,2.4-5.4,3.8-8.7,3.7
        c-6.2,0-11.3-4.3-11.3-10.3S59.2,0,65.4,0z M81,0.4h3.8v7.8H95V0.4h3.8v19.8H95v-8.8H84.8v8.9H81V0.4z M110.2,3.6h-5.7V0.4h15.3v3.2
        H114v16.6h-3.8V3.6z"/>
    </svg>

    <!--<svg v-if="graphic === 'hamburger-menu-closed'" version="1.1" width="24.2" height="18" viewBox="0 0 24.2 18">
      <line class="svg-stroke" x1="23.2" y1="1" x2="5.02" y2="1"/>
      <line class="svg-stroke" x1="23.2" y1="9" x2="1" y2="9"/>
      <line class="svg-stroke" x1="23.2" y1="17" x2="7.96" y2="17"/>
    </svg>-->

    <svg v-if="graphic === 'hamburger-menu-closed'" version="1.1" viewBox="0 0 26 21" width="26" height="21">
      <polyline class="svg-stroke" points="9.3,15.8 13.5,20 17.7,15.8 "/>
      <line class="svg-stroke" x1="13.5" y1="10.5" x2="13.5" y2="20"/>
      <path class="svg-stroke" d="M22.8,16.9c1.9-1.3,2.7-3.7,2-5.9
      s-2.7-3.7-5-3.7h-1.3c-0.9-3.4-3.7-5.9-7.1-6.3S4.5,2.4,2.9,5.5S1.8,12.4,4.1,15"/>
    </svg>

    <svg v-if="graphic === 'hamburger-menu-open'" version="1.1" width="18" height="18" viewBox="0 0 18 18">
      <path class="svg-fill" d="M1.9,0.3l15.7,15.7c0.4,0.4,0.4,1.1,0,1.6c-0.4,0.4-1.1,0.4-1.6,0L0.3,1.9
      c-0.4-0.4-0.4-1.1,0-1.6C0.8-0.1,1.5-0.1,1.9,0.3z"/>
      <path class="svg-fill" d="M0.3,16.1L16.1,0.3c0.4-0.4,1.1-0.4,1.6,0c0.4,0.4,0.4,1.1,0,1.6L1.9,17.6
      c-0.4,0.4-1.1,0.4-1.6,0C-0.1,17.2-0.1,16.5,0.3,16.1z"/>
    </svg>

    <svg v-if="graphic === 'calendar'" version="1.1" viewBox="0 0 14 15">
      <path class="svg-stroke" d="M3,2.3h8c1.1,0,2,0.9,2,2v8c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2v-8C1,3.1,1.9,2.3,3,2.3z"/>
      <line class="svg-stroke" x1="9.7" y1="0.9" x2="9.7" y2="3.6"/>
      <line class="svg-stroke" x1="4.3" y1="0.9" x2="4.3" y2="3.6"/>
      <line class="svg-stroke" x1="1" y1="6.3" x2="13" y2="6.3"/>
    </svg>

    <svg v-if="graphic === 'chevron'" version="1.1" viewBox="0 0 13.8 25.1" style="--height-multiplier: 1.549;">
      <polyline class="svg-stroke" points="1.2,1.2 12.6,12.6 1.2,23.9 "/>
    </svg>

    <svg v-if="graphic === 'user'" version="1.1" viewBox="0 0 18 20" >
      <path class="svg-stroke" d="M17,19v-2c0-2.2-1.8-4-4-4H5c-2.2,0-4,1.8-4,4v2"/>
      <circle class="svg-stroke" cx="9" cy="5" r="4"/>
    </svg>

    <svg v-if="graphic === 'course-hamburger'" version="1.1" viewBox="0 0 27.1 20">
      <circle class="svg-fill" cx="2" cy="2" r="2"/>
      <circle class="svg-fill" cx="2" cy="10" r="2"/>
      <circle class="svg-fill" cx="2" cy="18" r="2"/>
      <line class="svg-stroke" x1="23.2" y1="2" x2="8.8" y2="2"/>
      <line class="svg-stroke" x1="26.1" y1="10" x2="9" y2="10"/>
      <line class="svg-stroke" x1="20.5" y1="18" x2="9" y2="18"/>
    </svg>

    <svg v-if="graphic === 'quiz-marker'" version="1.1" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;">
    <!-- <circle id="Oval-Copy-4" class="st0" cx="10" cy="10" r="9"/> -->
      <path class="svg-stroke" d="M7.7,7.3c0.4-1.2,1.7-2,3-1.7s2.2,1.3,2.2,2.6c0,1.8-2.7,2.7-2.7,2.7"/>
      <line class="svg-stroke" x1="10.3" y1="14.4" x2="10.3" y2="14.4"/>
    </svg>

    <svg v-if="graphic === 'checkmark'" version="1.1" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;">
      <polyline class="svg-stroke" points="5,11 9,14 15,7 "/>
    </svg>

    <svg v-if="graphic === 'bookmark'" version="1.1" viewBox="0 0 23.8 30">
      <path class="svg-fill svg-stroke" d="M22.8,29l-10.9-7.8L1,29V4.1C1,2.4,2.4,1,4.1,1h15.6c1.7,0,3.1,1.4,3.1,3.1V29z"/>
    </svg>

    <svg v-if="graphic === 'course'" version="1.1" viewBox="0 0 60.1 42.5">
      <polygon class="svg-stroke" points="1.2,13.6 30,1.2 58.8,13.6 30,28.3 "/>
      <path class="svg-stroke" d="M46.8,19.8v13.1c-4.6,5.6-10.2,8.4-16.7,8.4S18,38.5,13.3,32.9V19.8"/>
      <line class="svg-stroke" x1="6.2" y1="16.9" x2="6.2" y2="26"/>
      <circle class="svg-fill" cx="5.8" cy="31.8" r="1"/>
    </svg>

    <svg v-if="graphic === 'parts'" version="1.1" viewBox="0 0 44.5 44.5">
      <circle class="svg-stroke" cx="22.2" cy="22.2" r="5.7"/>
      <path class="svg-stroke" d="M36.4,28c-0.5,1.2-0.3,2.6,0.6,3.5l0.1,0.1c0.7,0.7,1.1,1.7,1.1,2.7s-0.4,2-1.1,2.7
      c-0.7,0.7-1.7,1.1-2.7,1.1c-1,0-2-0.4-2.7-1.1l-0.1-0.1c-0.9-0.9-2.3-1.1-3.5-0.6c-1.2,0.5-1.9,1.6-1.9,2.9v0.3
      c0,2.1-1.7,3.8-3.8,3.8s-3.8-1.7-3.8-3.8v-0.2c0-1.3-0.8-2.4-2.1-2.9C15.3,35.9,14,36.1,13,37l-0.1,0.1c-0.7,0.7-1.7,1.1-2.7,1.1
      s-2-0.4-2.7-1.1c-0.7-0.7-1.1-1.7-1.1-2.7c0-1,0.4-2,1.1-2.7l0.1-0.1c0.9-0.9,1.1-2.3,0.6-3.5c-0.5-1.2-1.6-1.9-2.9-1.9H5.1
      c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8h0.2c1.3,0,2.4-0.8,2.9-2.1C8.6,15.3,8.4,14,7.5,13l-0.1-0.1c-0.7-0.7-1.1-1.7-1.1-2.7
      s0.4-2,1.1-2.7c0.7-0.7,1.7-1.1,2.7-1.1s2,0.4,2.7,1.1l0.1,0.1c0.9,0.9,2.3,1.1,3.5,0.6h0.2c1.2-0.5,1.9-1.6,1.9-2.9V5.1
      c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8v0.2c0,1.3,0.8,2.4,1.9,2.9c1.2,0.5,2.6,0.3,3.5-0.6l0.1-0.1c0.7-0.7,1.7-1.1,2.7-1.1
      s2,0.4,2.7,1.1c0.7,0.7,1.1,1.7,1.1,2.7s-0.4,2-1.1,2.7l-0.1,0.1c-0.9,0.9-1.1,2.3-0.6,3.5v0.2c0.5,1.2,1.6,1.9,2.9,1.9h0.3
      c2.1,0,3.8,1.7,3.8,3.8s-1.7,3.8-3.8,3.8h-0.2C38,26.1,36.9,26.8,36.4,28z"/>
    </svg>

    <svg v-if="graphic === 'troubleshooting'" version="1.1" viewBox="0 0 45 42">
      <path class="svg-stroke" d="M27.2,10.3c-0.8,0.8-0.8,2.1,0,2.9l3.5,3.3c0.8,0.8,2.2,0.8,3,0l8.1-7.7c2.2,4.7,1.2,10.2-2.6,13.8
      s-9.6,4.6-14.5,2.5L9.8,39.2c-1.8,1.7-4.7,1.7-6.5,0s-1.8-4.5,0-6.2l14.9-14.2C16,14.2,17.1,8.7,20.9,5.1s9.6-4.6,14.5-2.5
      L27.2,10.3L27.2,10.3z"/>
    </svg>

    <svg v-if="graphic === 'instructions'" version="1.1" viewBox="0 0 54 41.5">
      <path class="svg-stroke" d="M1.2,1.2h13c4.8,0,8.7,3.9,8.7,8.7v30.3c0-3.6-2.9-6.5-6.5-6.5H1.2V1.2z"/>
      <circle class="svg-stroke" cx="44.6" cy="19.1" r="8.1"/>
      <line class="svg-stroke" x1="44.6" y1="22.4" x2="44.6" y2="19.1"/>
      <line class="svg-stroke" x1="44.6" y1="15.9" x2="44.6" y2="15.9"/>
      <path class="svg-stroke" d="M36.5,19.1c0-4.5,3.6-8.1,8.1-8.1V1.2h-13c-4.8,0-8.7,3.9-8.7,8.7v30.3c0-3.6,2.9-6.5,6.5-6.5h15.2v-6.5
        C40.1,27.2,36.5,23.6,36.5,19.1z"/>
    </svg>

    <svg v-if="graphic === 'lesson'" version="1.1" viewBox="0 0 23.6 30.6" >
      <path class="svg-fill" d="M23.6,9.1v20.1c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4H1.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1
        V1.4c0-0.4,0.1-0.7,0.4-1C0.7,0.1,1,0,1.4,0h13.1c0.4,0,0.8,0.1,1.1,0.4l7.7,7.8C23.5,8.4,23.6,8.8,23.6,9.1z M20.9,27.8V12.5h-8.4
        c-0.4,0-0.8-0.1-1-0.4c-0.2-0.3-0.4-0.6-0.4-1V2.8H2.8v25H20.9z M14,2.8h-0.1v6.9h7L14,2.8z"/>
    </svg>

    <svg v-if="graphic === 'arrow'" version="1.1" viewBox="0 0 14.9 11.4">
    <path class="svg-fill" d="M14.6,6.3l-4.9,4.9c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.6-0.2c-0.4-0.4-0.4-0.8,0-1.1l3.6-3.6H0.8
      c-0.2,0-0.4-0.1-0.5-0.2C0.1,6.1,0,5.9,0,5.7s0.1-0.4,0.2-0.5C0.4,5,0.6,5,0.8,5h11.4L8.6,1.4C8.3,1,8.3,0.7,8.6,0.3
      c0.4-0.4,0.7-0.4,1.1,0l4.9,4.9C15,5.5,15,5.9,14.6,6.3z"/>
    </svg>

    <svg v-if="graphic === 'screwdriver'" version="1.1" viewBox="0 0 44 17">
      <path class="svg-stroke" d="M6.4,3.1h10.9l0,0C17.3,1.7,18,1,19.4,1s2.1,0.7,2.1,2.1v10.7c0,1.4-0.7,2.1-2.1,2.1
        s-2.1-0.7-2.1-2.1H6.4c-3,0-5.4-2.4-5.4-5.4S3.4,3.1,6.4,3.1z"/>
      <path id="Rectangle-Copy" class="svg-stroke" d="M21.6,6.7h10.3c0.6-2,1.4-3,2.6-3c1.1,0,4,1,8.6,3v3.6c-4.6,2-7.4,3-8.6,3
        c-1.1,0-2-1-2.6-3H21.6V6.7z"/>
      <line id="Line" class="svg-stroke" x1="5.5" y1="6.4" x2="13.5" y2="6.4"/>
    </svg>

    <svg v-if="graphic === 'switch'" version="1.1" viewBox="0 0 24 16">
      <path id="Rectangle" class="svg-stroke" d="M8,1h8c3.9,0,7,3.1,7,7l0,0c0,3.9-3.1,7-7,7H8c-3.9,0-7-3.1-7-7l0,0C1,4.1,4.1,1,8,1z"/>
      <circle id="Oval" class="svg-stroke" cx="8" cy="8" r="3"/>
    </svg>

    <svg v-if="graphic === 'powercord'" version="1.1" viewBox="0 0 20.8 19.7">
      <path class="svg-stroke" d="M12.8,10.8h1.8l0,0v7.9h-1.8c-2.2,0-4-1.8-4-4l0,0l0,0C8.8,12.6,10.6,10.8,12.8,10.8z"/>
      <path class="svg-stroke" d="M5.8,14.2H4.5c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5h12.2c1.7,0,3.1-1.4,3.1-3.1S18.4,1,16.7,1H1 l0,0"/>
      <line class="svg-stroke" x1="17.6" y1="13" x2="19.6" y2="13"/>
      <line class="svg-stroke" x1="17.6" y1="17" x2="19.6" y2="17"/>
    </svg>

    <svg v-if="graphic === 'sun'" version="1.1" viewBox="0 0 24 24">
      <circle class="svg-stroke" cx="12" cy="12" r="5"/>
      <line class="svg-stroke" x1="12" y1="1" x2="12" y2="3"/>
      <line class="svg-stroke" x1="12" y1="21" x2="12" y2="23"/>
      <line class="svg-stroke" x1="4.2" y1="4.2" x2="5.6" y2="5.6"/>
      <line class="svg-stroke" x1="18.4" y1="18.4" x2="19.8" y2="19.8"/>
      <line class="svg-stroke" x1="1" y1="12" x2="3" y2="12"/>
      <line class="svg-stroke" x1="21" y1="12" x2="23" y2="12"/>
      <line class="svg-stroke" x1="4.2" y1="19.8" x2="5.6" y2="18.4"/>
      <line class="svg-stroke" x1="18.4" y1="5.6" x2="19.8" y2="4.2"/>
    </svg>

    <svg v-if="graphic === 'screw'" version="1.1" viewBox="0 0 17 21.9">
      <path class="svg-stroke" d="M12.6,10l0-2.8c0.1,0,0.1-0.1,0.1-0.1c0.4-0.3,0.7-0.5,1-0.9C14.7,5.4,15.4,3.6,16,1H1
      c0.6,2.6,1.3,4.4,2.2,5.3c0.3,0.3,0.7,0.6,1,0.9c0,0,0.1,0,0.1,0.1l0,3.5L12.6,10z"/>
      <line class="svg-stroke" x1="4.3" y1="14.3" x2="12.6" y2="13.6"/>
      <line class="svg-stroke" x1="4.3" y1="17.7" x2="12.6" y2="17"/>
      <path class="svg-stroke" d="M7.1,20.9l2.8-0.2L7.1,20.9z"/>
    </svg>

    <svg v-if="graphic === 'lightbulb'" version="1.1" viewBox="0 0 17 26">
      <path class="svg-stroke" d="M12.6,17.5l0-2.8c0.1,0,0.1-0.1,0.1-0.1c0.4-0.3,0.7-0.5,1-0.9c1.4-1.4,2.2-3.2,2.2-5.3
      C16,4.4,12.6,1,8.5,1S1,4.4,1,8.5c0,2.1,0.8,3.9,2.2,5.3c0.3,0.3,0.7,0.6,1,0.9c0,0,0.1,0,0.1,0.1l0,3.5L12.6,17.5z"/>
      <path class="svg-stroke" d="M12.9,8c-0.3-1.7-1.4-3-2.9-3.7"/>
      <line class="svg-stroke" x1="4.3" y1="21.8" x2="12.6" y2="21.1"/>
      <path class="svg-stroke" d="M7.1,25l2.8-0.2L7.1,25z"/>
    </svg>

    <svg v-if="graphic === 'lampshade'" version="1.1" viewBox="0 0 23.2 21.6">
      <path class="svg-stroke" d="M7.6,12.6c0.9,1.2,2.4,2,4,2s3.1-0.8,4-2"/>
      <path class="svg-stroke" d="M22.2,11.6C22.2,5.7,17.4,1,11.6,1S1,5.7,1,11.6H22.2z"/>
      <line class="svg-stroke" x1="11.6" y1="18.6" x2="11.6" y2="20.6"/>
      <line class="svg-stroke" x1="17.9" y1="15.9" x2="19.4" y2="17.4"/>
      <line class="svg-stroke" x1="3.8" y1="17.4" x2="5.2" y2="15.9"/>
    </svg>

    <svg v-if="graphic === 'no-light'" version="1.1" viewBox="0 0 24 26">
      <path class="svg-stroke" d="M18,14.1c0.1-0.1,0.3-0.2,0.4-0.4c1.4-1.4,2.2-3.2,2.2-5.3c0-4.1-3.4-7.5-7.5-7.5
      c-3,0-5.6,1.8-6.8,4.3 M5.8,10.1c0.3,1.4,1,2.7,2,3.6c0.3,0.3,0.7,0.6,1,0.9c0,0,0.1,0,0.1,0.1l0,3.5l7-0.6"/>
      <path class="svg-stroke" d="M17.5,8c-0.3-1.7-1.4-3-2.9-3.7"/>
      <line class="svg-stroke" x1="8.9" y1="21.8" x2="17.3" y2="21.1"/>
      <path class="svg-stroke" d="M11.7,25l2.8-0.2L11.7,25z"/>
      <line class="svg-stroke" x1="1" y1="1.3" x2="23" y2="18.3"/>
    </svg>

    <svg v-if="graphic === 'quiz'" version="1.1" viewBox="0 0 32.5 28.4">

      <path class="svg-stroke" d="M10.3,20.1c0,1.3,1,2.3,2.3,2.3h14l4.7,4.7V8.5c0-1.3-1-2.3-2.3-2.3H12.6
      c-1.3,0-2.3,1-2.3,2.3V20.1z"/>
      <path class="svg-stroke svg-fill" d="M22.2,15.2c0,1.3-1,2.3-2.3,2.3h-14l-4.7,4.7V3.6c0-1.3,1-2.3,2.3-2.3h16.3c1.3,0,2.3,1,2.3,2.3V15.2
      z"/>
      <path style="stroke-width: 2;" class="svg-stroke" d="M9.1,6.7c0.4-1.2,1.7-2,3-1.7c1.3,0.2,2.2,1.3,2.2,2.6c0,1.8-2.7,2.7-2.7,2.7"/>
      <line style="stroke-width: 2;" class="svg-stroke" x1="11.7" y1="13.8" x2="11.7" y2="13.8"/>
    </svg>

    <svg v-if="graphic === 'quiz-overview'" version="1.1" viewBox="0 0 155.5 137.5" style="--height-multiplier: 0.884;">

      <linearGradient id="quizOverviewGradient" gradientUnits="userSpaceOnUse" x1="71.9916" y1="73.9527" x2="72.7352" y2="73.2634" gradientTransform="matrix(140 0 0 -122 -10061.5 9046.5)">
        <stop  offset="0" style="stop-color:#FFFFFF;stop-opacity:0"/>
        <stop  offset="0.9991" style="stop-color:#CFE8F2"/>
        <stop  offset="1" style="stop-color:#CFE8F2"/>
      </linearGradient>
      <path style="fill: url(#quizOverviewGradient);" d="M102.6,15.5c6,0,10.9,4.9,10.9,10.9v13.1h31.1c5.9,0,10.7,4.7,10.9,10.6l0,0.3v87.1
      l-21.8-21.8H68.4c-6,0-10.9-4.9-10.9-10.9l0,0V91.7l-20.2,0l-21.8,21.8V26.4c0-6,4.9-10.9,10.9-10.9H102.6z"/>
      <path id="Path" class="svg-stroke" d="M45.5,80.1c0,2.8,0,7.1,0,12.7c0,6,4.9,10.9,10.9,10.9h65.3l21.8,21.8V38.4c0-6-4.9-10.9-10.9-10.9
      c-13.9,0-24.2,0-31.2,0"/>
      <path class="svg-stroke" d="M101.5,68.8c0,6-4.9,10.9-10.9,10.9H25.3L3.5,101.5V14.4c0-6,4.9-10.9,10.9-10.9h76.2
      c6,0,10.9,4.9,10.9,10.9V68.8z"/>
      <path class="svg-stroke" d="M39.5,29.8c2.1-5.7,8.1-9.2,14.3-8.2S64.5,27.9,64.5,34c0,8.3-12.9,12.5-12.9,12.5"/>
      <line class="svg-stroke" x1="52" y1="62" x2="52" y2="62"/>
    </svg>

    <svg v-if="graphic === 'eye'" version="1.1" viewBox="0 0 17.5 13.5">
      <path class="svg-stroke" d="M0.8,6.8c0,0,2.9-6,8-6s8,6,8,6s-2.9,6-8,6S0.8,6.8,0.8,6.8z"/>
      <circle class="svg-stroke" cx="8.8" cy="6.8" r="2"/>
    </svg>

    <svg v-if="graphic === 'repeat'" version="1.1" viewBox="0 0 14.5 17.5">
      <polyline class="svg-stroke" points="10.8,0.8 13.8,3.8 10.8,6.8 "/>
      <path class="svg-stroke" d="M0.8,7.8V6.4c0-1.5,1.3-2.7,2.9-2.7h10.1"/>
      <polyline class="svg-stroke" points="3.8,16.8 0.8,13.8 3.8,10.8 "/>
      <path class="svg-stroke" d="M13.8,9.8v1.3c0,1.5-1.3,2.7-2.9,2.7H0.8"/>
    </svg>

    <svg v-if="graphic === 'home'" version="1.1" viewBox="0 0 16.5 18.2">
      <path class="svg-stroke" d="M0.8,6.6l7.5-5.8l7.5,5.8v9.2c0,0.9-0.7,1.7-1.7,1.7H2.4c-0.9,0-1.7-0.7-1.7-1.7V6.6z"/>
      <polyline class="svg-stroke" points="5.8,17.4 5.8,9.1 10.8,9.1 10.8,17.4 "/>
    </svg>

    <svg v-if="graphic === 'quiz-excellent'" version="1.1" viewBox="0 0 122 122">
      <circle class="svg-stroke" cx="61" cy="61" r="57.5"/>
      <path class="svg-stroke" d="M31.5,71.5c0,0,4.6,20,30,20s30-20,30-20H31.5z"/>
      <line class="svg-stroke -thick" x1="43.5" y1="43.5" x2="43.5" y2="43.5"/>
      <line class="svg-stroke -thick" x1="78.5" y1="43.5" x2="78.5" y2="43.5"/>
    </svg>

    <svg v-if="graphic === 'quiz-fail'" version="1.1" viewBox="0 0 122 122">
      <circle class="svg-stroke" cx="61" cy="61" r="57.5"/>
      <line class="svg-stroke" x1="37.5" y1="72.5" x2="83.5" y2="72.5"/>
      <line class="svg-stroke -thick" x1="43.5" y1="43.5" x2="43.5" y2="43.5"/>
      <line class="svg-stroke -thick" x1="78.5" y1="43.5" x2="78.5" y2="43.5"/>
    </svg>

    <svg v-if="graphic === 'quiz-good'" version="1.1" viewBox="0 0 122 122">
      <circle class="svg-stroke" cx="61.5" cy="61.5" r="57.5"/>
      <path class="svg-stroke" d="M38,71c0,0,8.6,12,23,12s23-12,23-12"/>
      <line class="svg-stroke -thick" x1="44" y1="44" x2="44" y2="44"/>
      <line class="svg-stroke -thick" x1="79" y1="44" x2="79" y2="44"/>
    </svg>

    <svg v-if="graphic === 'power-button'" version="1.1" viewBox="0 0 20 22">
      <path class="svg-stroke" d="M16.4,5.6c3.5,3.5,3.5,9.2,0,12.7s-9.2,3.5-12.7,0s-3.5-9.2,0-12.7"/>
      <line class="svg-stroke" x1="10" y1="1" x2="10" y2="11"/>
    </svg>

    <svg v-if="graphic === 'power-button-broken'" version="1.1" viewBox="0 0 25 22">
      <path class="svg-stroke" d="M18.9,5.6c3.2,3.2,3.5,8.1,1,11.6 M17.1,19.7c-3.5,2.1-8,1.6-11-1.4C3.6,15.9,2.9,12.3,4,9.1 M5.7,6.1C5.8,5.9,6,5.8,6.1,5.6"/>
      <line class="svg-stroke" x1="2" y1="3.5" x2="24" y2="20.5"/>
      <line class="svg-stroke" x1="12.5" y1="1" x2="12.5" y2="8"/>
    </svg>

    <svg v-if="graphic === 'runtime'" version="1.1" viewBox="0 0 22 22">
      <circle class="svg-stroke" cx="11" cy="11" r="10"/>
      <polyline class="svg-stroke" points="11,5 11,11 15,13 "/>
    </svg>

    <svg v-if="graphic === 'no-charging'" version="1.1" viewBox="0 0 24 14">
      <path class="svg-stroke" d="M3,1h14c1.1,0,2,0.9,2,2v8c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2V3C1,1.9,1.9,1,3,1z"/>
      <line class="svg-stroke" x1="4.5" y1="4.1" x2="4.5" y2="10.1"/>
      <line class="svg-stroke" x1="23" y1="8" x2="23" y2="6"/>
    </svg>

    <svg v-if="graphic === 'low-light'" version="1.1" viewBox="0 0 27 30.2">
      <path class="svg-stroke" d="M17.8,21.7l0-2.8c0.1,0,0.1-0.1,0.1-0.1c0.4-0.3,0.7-0.5,1-0.9c1.4-1.4,2.2-3.2,2.2-5.3
      c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5c0,2.1,0.8,3.9,2.2,5.3c0.3,0.3,0.7,0.6,1,0.9c0,0,0.1,0,0.1,0.1l0,3.5L17.8,21.7z"/>
      <path class="svg-stroke" d="M18,12.2c-0.3-1.7-1.4-3-2.9-3.7"/>
      <line class="svg-stroke" x1="9.4" y1="26" x2="17.8" y2="25.3"/>
      <path class="svg-stroke" d="M12.2,29.2L15,29L12.2,29.2z"/>
      <circle class="svg-fill" cx="26" cy="12" r="1"/>
      <circle class="svg-fill" cx="1" cy="12" r="1"/>
      <circle class="svg-fill" cx="13" cy="1" r="1"/>
      <circle class="svg-fill" cx="4" cy="5" r="1"/>
      <circle class="svg-fill" cx="4" cy="19" r="1"/>
      <circle class="svg-fill" cx="23" cy="5" r="1"/>
      <circle class="svg-fill" cx="23" cy="19" r="1"/>
    </svg>

    <svg v-if="graphic === 'tool-cable'" version="1.1" viewBox="0 0 48 48">
      <path class="svg-stroke -thin" d="M40.3,14.5c-1.8-1.2-3.4-2.2-4.9-3"/>
      <path class="svg-stroke -thin" d="M17.3,32c1.2,0.9,2.8,1.8,4.9,2.9"/>
      <path class="svg-stroke" d="M35.1,11.4c-3.3-2-7.1-2.8-10.8-2.8c-9.4,0-16.5,6.9-16.5,17.1s8.5,15.4,17.4,15.4
      s13.6-6.7,13.6-14.2s-4.9-13.4-14.5-13.4S9.3,25.3,17.1,31.9"/>
    </svg>

    <svg v-if="graphic === 'tool-knife'" version="1.1" viewBox="0 0 48 48">
      <path class="svg-stroke" d="M7.7,18h32.7c0.6,0,1,0.4,1,1c0,0.2-0.1,0.4-0.2,0.6l-7.2,10c-0.2,0.3-0.5,0.4-0.8,0.4H7.7
      c-3.3,0-6-2.7-6-6S4.3,18,7.7,18z"/>
      <polygon class="svg-stroke -thin" points="41,20 46.3,29 34.7,29 "/>
      <circle class="svg-stroke" cx="7" cy="24" r="1"/>
    </svg>

    <svg v-if="graphic === 'tool-pliers'" version="1.1" viewBox="0 0 48 48">
      <path class="svg-stroke" d="M3,16.9c4-1.4,8.4-2.1,13.1-1.9c4.7,0.2,9.1,1.2,13.2,3c0.5,0.2,0.9,0.8,0.9,1.4v1.4
      c0,0.8-0.7,1.5-1.5,1.5c-0.2,0-0.4,0-0.5-0.1c-4.3-1.6-8.3-2.5-12.1-2.6c-3.8-0.2-7.8,0.4-12.2,1.7c-0.8,0.2-1.6-0.2-1.9-1
      C2,20,2,19.9,2,19.7v-1.4C2,17.6,2.4,17.1,3,16.9z"/>
      <path class="svg-stroke" d="M3,31.1c4,1.4,8.4,2.1,13.1,1.9c4.7-0.2,9.1-1.2,13.2-3c0.5-0.2,0.9-0.8,0.9-1.4v-1.4
      c0-0.8-0.7-1.5-1.5-1.5c-0.2,0-0.4,0-0.5,0.1c-4.3,1.6-8.3,2.5-12.1,2.6c-3.8,0.2-7.8-0.4-12.2-1.7c-0.8-0.2-1.6,0.2-1.9,1
      C2,28,2,28.1,2,28.3v1.4C2,30.3,2.4,30.9,3,31.1z"/>
      <path class="svg-stroke" d="M33.6,19.5c2.9-0.5,6.8,0.2,11.8,2c0.4,0.1,0.7,0.5,0.7,0.9v3.1c0,0.4-0.3,0.8-0.7,0.9
      c-4.9,1.8-8.8,2.5-11.8,2c0-2.3-0.7-3.8-2-4.5C32.9,23.2,33.6,21.7,33.6,19.5z"/>
      <path class="svg-stroke -thin" d="M44.5,24.3h-6.1c-0.1,0.7-0.4,1.2-0.9,1.4"/>
      <path class="svg-stroke -thin" d="M44.5,23.7h-6.1c-0.1-0.7-0.4-1.2-0.9-1.4"/>
    </svg>

    <svg v-if="graphic === 'tool-screwdriver'" version="1.1" viewBox="0 0 48 48">
      <path class="svg-stroke" d="M6.5,18.5h11.3l0,0c0-1.3,0.7-2,2.1-2s2.1,0.7,2.1,2v10c0,1.3-0.7,2-2.1,2s-2.1-0.7-2.1-2H6.5
      c-2.8,0-5-2.2-5-5S3.7,18.5,6.5,18.5z"/>
      <path class="svg-stroke" d="M22.1,21.7h13.3c0.6-2,1.4-3,2.6-3c1.1,0,4,1,8.6,3v3.6c-4.6,2-7.4,3-8.6,3
      c-1.1,0-2-1-2.6-3H22.1V21.7z"/>
      <line class="svg-stroke -thin" x1="6" y1="21.4" x2="14" y2="21.4"/>
    </svg>

    <svg v-if="graphic === 'tool-solderingiron'" version="1.1" viewBox="0 0 48 48">
      <path class="svg-stroke" d="M5.8,19.2h12l0,0c0-1.1,0.7-1.7,2.1-1.7s2.1,0.6,2.1,1.7v8.6c0.1,1.1-0.6,1.7-2.1,1.7
      s-2.1-0.6-2.1-1.7h-12c-2.4,0-4.3-1.9-4.3-4.3S3.4,19.2,5.8,19.2z"/>
      <polygon class="svg-stroke" points="22,21.7 40,21.7 40,25.3 22,25.3 "/>
      <line class="svg-stroke -thin" x1="6" y1="22.4" x2="14" y2="22.4"/>
      <polyline class="svg-stroke" points="40,23.5 44.1,23.5 46,25.7 "/>
    </svg>

    <svg v-if="graphic === 'tool-solderingpaste'" version="1.1" viewBox="0 0 48 48">
      <path class="svg-stroke" d="M19.7,21.9C21.1,22,22.5,22,24,22s2.9,0,4.3-0.1"/>
      <path class="svg-stroke" d="M40,16v6c0,1.8-7.2,3.2-16,3.2S8,23.8,8,22v-6"/>
      <path class="svg-stroke" d="M39,23.4V34c0,1.7-6.7,3-15,3S9,35.7,9,34l0,0V23.4"/>
      <ellipse class="svg-stroke" cx="24" cy="16" rx="16" ry="3.2"/>
    </svg>

    <svg v-if="graphic === 'tool-solderingtin'" version="1.1" viewBox="0 0 48 48">
      <path class="svg-fill" d="M12.5,29c-0.2,0.1-0.5,0.1-0.7,0.2l-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1l-0.3,0.1c0,0-0.1,0-0.1,0.1
      l-0.2,0.1l-0.2,0.1c0,0,0,0-0.1,0l-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0.1,0.1,0.1l0.1,0.1l0.2,0.1l0.2,0.1l0.3,0.1l0.3,0.1l0.3,0.1
      c0.1,0,0.1,0,0.2,0.1l0.4,0.1c0.3,0.1,0.6,0.2,0.9,0.2c2.3,0.5,5.5,0.9,9,0.9s6.6-0.3,9-0.9c0.2,0,0.3-0.1,0.5-0.1l0.5-0.1
      c0.1,0,0.3-0.1,0.4-0.1l0.4-0.1l0.3-0.1l0.3-0.1l0.2-0.1l0.2-0.1c0.1,0,0.1-0.1,0.2-0.1l0,0l-0.1,0l-0.1,0c-0.2-0.2-0.6-0.4-1.2-0.6
      c-0.2-0.1-0.6-0.2-0.8-0.3c-0.3-0.1,0.6-0.6,0.7-0.9c0.2-0.5,0.3-0.9,0.8-0.7C35,28.1,36,28.9,36,30c0,1.3-1.4,2.2-3.8,2.9L31.7,33
      l-0.5,0.1c-0.2,0-0.4,0.1-0.6,0.1L30,33.4c-0.1,0-0.2,0-0.3,0.1l-0.6,0.1l-0.6,0.1l-0.7,0.1c-0.1,0-0.2,0-0.3,0l-0.7,0.1l-0.7,0.1
      l-0.7,0c-0.1,0-0.2,0-0.4,0l-0.7,0l-0.8,0l-0.8,0L22,34l-0.8,0l-0.8,0l-0.8,0l-0.7,0l-0.7,0c-0.1,0-0.2,0-0.4,0l-0.7-0.1l-0.7-0.1
      l-0.7-0.1c-0.1,0-0.2,0-0.3,0l-0.6-0.1l-0.6-0.1c-0.1,0-0.2,0-0.3-0.1l-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1L12.3,33l-0.5-0.1
      C9.4,32.2,8,31.3,8,30c0-1.2,1.1-2.1,3.1-2.7c0.1,0,0.2,0.5,0.6,0.9C12.1,28.6,12.8,28.9,12.5,29z"/>
      <path class="svg-stroke -thin" d="M33,24c4.6,0.4,8,1.5,8,2.9c0,0.4-0.2,0.7-0.6,1"/>
      <path class="svg-stroke -thin" d="M24.3,25.3c4.7,0,8.7-0.9,8.7-2.3c0-0.3-0.1-0.5-0.3-0.7"/>
      <path class="svg-stroke -thin" d="M24.3,28.3c4.7,0,8.7-0.9,8.7-2.3c0-0.3-0.1-0.5-0.3-0.8"/>
      <path class="svg-stroke -thin" d="M24.3,26.8c4.7,0,8.7-0.9,8.7-2.3c0-0.3-0.1-0.5-0.4-0.8"/>
      <path class="svg-stroke -thin" d="M24.3,23.8c4.7,0,8.7-0.9,8.7-2.3c0-0.4-0.2-0.7-0.6-1"/>
      <path class="svg-stroke -thin" d="M24.3,29.8c4.7,0,8.7-0.9,8.7-2.3c0-0.3-0.1-0.5-0.3-0.7"/>
      <path class="svg-stroke" d="M35,16v3c0,1.7-5.8,3-13,3S9,20.7,9,19l0,0v-3"/>
      <ellipse class="svg-stroke" cx="22" cy="16" rx="13" ry="3"/>
      <ellipse class="svg-stroke" cx="22" cy="15.9" rx="5.1" ry="1"/>
      <path class="svg-stroke" d="M17,25.2c-3.3-0.3-5.7-1.1-5.7-2.2c0-0.3,0.1-0.5,0.3-0.7"/>
      <path class="svg-stroke" d="M17,28.2c-3.3-0.3-5.7-1.1-5.7-2.2c0-0.3,0.1-0.5,0.3-0.8"/>
      <path class="svg-stroke" d="M17,26.7c-3.3-0.3-5.7-1.1-5.7-2.2c0-0.3,0.1-0.5,0.4-0.8"/>
      <path class="svg-stroke" d="M17,23.7c-3.3-0.3-5.7-1.1-5.7-2.2c0-0.4,0.2-0.7,0.6-1"/>
      <path class="svg-stroke" d="M17,29.7c-3.3-0.3-5.7-1.1-5.7-2.2c0-0.3,0.1-0.5,0.3-0.7"/>
      <path class="svg-stroke" d="M35,30v3c0,1.7-5.8,3-13,3S9,34.7,9,33l0,0v-3"/>
    </svg>

    <svg v-if="graphic === 'tool-tape'" version="1.1" viewBox="0 0 48 48">
      <path class="svg-stroke" d="M34,23c5.1-2.2,7.8-4.2,8-6v12c-0.5,1.6-3.1,3.6-8,6C34,32.8,34,26.6,34,23z"/>
      <path class="svg-stroke" d="M34.1,32.3C31.2,32.7,27.7,33,24,33c-9.9,0-18-1.8-18-4V17.2"/>
      <ellipse class="svg-stroke" cx="24" cy="17.2" rx="18" ry="3.6"/>
      <ellipse class="svg-stroke" cx="24" cy="16.9" rx="7.4" ry="1"/>
    </svg>

    <svg v-if="graphic === 'tool-wirecutters'" version="1.1" viewBox="0 0 48 48">
      <path class="svg-stroke" d="M3,16.9c4-1.4,8.4-2.1,13.1-1.9c4.7,0.2,9.1,1.2,13.2,3c0.5,0.2,0.9,0.8,0.9,1.4v1.4
      c0,0.8-0.7,1.5-1.5,1.5c-0.2,0-0.4,0-0.5-0.1c-4.3-1.6-8.3-2.5-12.1-2.6c-3.8-0.2-7.8,0.4-12.2,1.7c-0.8,0.2-1.6-0.2-1.9-1
      C2,20,2,19.9,2,19.7v-1.4C2,17.6,2.4,17.1,3,16.9z"/>
      <path class="svg-stroke" d="M3,31.1c4,1.4,8.4,2.1,13.1,1.9c4.7-0.2,9.1-1.2,13.2-3c0.5-0.2,0.9-0.8,0.9-1.4v-1.4
      c0-0.8-0.7-1.5-1.5-1.5c-0.2,0-0.4,0-0.5,0.1c-4.3,1.6-8.3,2.5-12.1,2.6c-3.8,0.2-7.8-0.4-12.2-1.7c-0.8-0.2-1.6,0.2-1.9,1
      C2,28,2,28.1,2,28.3v1.4C2,30.3,2.4,30.9,3,31.1z"/>
      <path class="svg-stroke" d="M33.6,19.5C38,19,42.1,20.5,46,24c-3.9,3.5-8,5-12.4,4.5c0-2.3-0.7-3.8-2-4.5
      C32.9,23.2,33.6,21.7,33.6,19.5z"/>
      <path class="svg-stroke -thin" d="M45.9,23.9h-8.8c-0.3-1.9-1.2-3-2.8-3.3"/>
    </svg>

    <svg v-if="graphic === 'tool-wirestripper'" version="1.1" viewBox="0 0 48 48">
      <path class="svg-stroke" d="M3,16.8c3.7-1.4,7.7-2.1,12-1.9c4.4,0.2,8.4,1.2,12.1,3c0.5,0.2,0.9,0.8,0.9,1.4v1.3
      c0,0.8-0.7,1.5-1.5,1.5c-0.2,0-0.4,0-0.5-0.1c-3.9-1.5-7.6-2.4-11-2.5c-3.4-0.1-7.1,0.4-11.1,1.6c-0.8,0.2-1.6-0.2-1.9-1
      C2,20,2,19.8,2,19.7v-1.4C2,17.6,2.4,17.1,3,16.8z"/>
      <path class="svg-stroke" d="M3,31.1c3.7,1.4,7.7,2.1,12,1.9c4.4-0.2,8.4-1.2,12.1-3c0.5-0.2,0.9-0.8,0.9-1.4v-1.3
      c0-0.8-0.7-1.5-1.5-1.5c-0.2,0-0.4,0-0.5,0.1c-3.9,1.5-7.6,2.4-11,2.5c-3.4,0.1-7.1-0.4-11.1-1.6c-0.8-0.2-1.6,0.2-1.9,1
      C2,28,2,28.2,2,28.3v1.4C2,30.4,2.4,30.9,3,31.1z"/>
      <path class="svg-stroke" d="M31.6,19.5c2.9-0.5,7.5,0.2,13.7,2c0.4,0.1,0.7,0.5,0.7,1v3c0,0.4-0.3,0.8-0.7,1
      c-6.2,1.8-10.7,2.5-13.7,2c0-2.3-0.7-3.8-2-4.5C30.9,23.2,31.6,21.7,31.6,19.5z"/>
      <line class="svg-stroke -thin" x1="45.5" y1="24" x2="35.4" y2="24"/>
      <circle class="svg-stroke -thin" cx="35" cy="24" r="2"/>
      <circle class="svg-stroke -thin" cx="39.5" cy="24" r="1.5"/>
      <circle class="svg-stroke -thin" cx="43" cy="24" r="1"/>
    </svg>

    <svg v-if="graphic === 'warning-fire'" version="1.1" viewBox="0 0 59.5 52">
      <path class="svg-fill" d="M29.7,0c-2.6,0-4.9,1.3-6.3,3.5L1,41c-1.3,2.3-1.3,5,0,7.3S4.6,52,7.2,52h45
      c2.6,0,5-1.4,6.3-3.7s1.3-5,0-7.3L36,3.5C34.7,1.3,32.3,0,29.7,0z M29.7,4c1.2,0,2.2,0.6,2.8,1.6l22.5,37.5c0.6,1,0.6,2.2,0,3.3
      S53.4,48,52.2,48h-45c-1.2,0-2.2-0.6-2.8-1.7s-0.6-2.3,0-3.3L26.9,5.6C27.5,4.6,28.6,4,29.7,4z"/>
      <path class="svg-fill" d="M26.5,43.6c-4.4-2.1-6.6-5.2-6.6-9.4c0-7.9,8.1-10.5,7.4-18.7c5,2.1,7.8,8.2,7.3,12.5
      c1.3-0.9,1.5-1.9,1.6-2.9c1.5,1.8,3.1,4.3,3.1,9.2c0,4.2-2.3,7.3-6.8,9.4c1.8-1.6,2.8-3.5,2.8-5.5c0-3.1-4.9-5.3-4.6-10.1
      c-1.1,0-3.3,3.3-3.3,5.8s0,2,0.2,3.4c-1.5-0.7-2.3-2-2.2-3.7c-1.1,1.2-1.7,2.7-1.7,4.7S24.8,42,26.5,43.6z"/>
    </svg>

    <svg v-if="graphic === 'warning-general'" version="1.1" viewBox="0 0 59.5 52">
      <path class="svg-fill" d="M29.7,0c-2.6,0-4.9,1.3-6.3,3.5L1,41c-1.3,2.3-1.3,5,0,7.3S4.6,52,7.2,52h45
      c2.6,0,5-1.4,6.3-3.7s1.3-5,0-7.3L36,3.5C34.7,1.3,32.3,0,29.7,0z M29.7,4c1.2,0,2.2,0.6,2.8,1.6l22.5,37.5c0.6,1,0.6,2.2,0,3.3
      S53.4,48,52.2,48h-45c-1.2,0-2.2-0.6-2.8-1.7s-0.6-2.3,0-3.3L26.9,5.6C27.5,4.6,28.6,4,29.7,4z"/>
      <path class="svg-fill" d="M31,18h-2c-1.2,0-2.1,1-2,2.2l1,9.5c0.3,2.4,3.7,2.4,4,0l1-9.5C33.1,19,32.2,18,31,18z"/>
      <circle class="svg-fill" cx="30" cy="38" r="3"/>
    </svg>

    <svg v-if="graphic === 'warning-water'" version="1.1" viewBox="0 0 59.5 52">
      <path class="svg-fill" d="M29.7,0c-2.6,0-4.9,1.3-6.3,3.5L1,41c-1.3,2.3-1.3,5,0,7.3S4.6,52,7.2,52h45
      c2.6,0,5-1.4,6.3-3.7s1.3-5,0-7.3L36,3.5C34.7,1.3,32.3,0,29.7,0z M29.7,4c1.2,0,2.2,0.6,2.8,1.6l22.5,37.5c0.6,1,0.6,2.2,0,3.3
      S53.4,48,52.2,48h-45c-1.2,0-2.2-0.6-2.8-1.7s-0.6-2.3,0-3.3L26.9,5.6C27.5,4.6,28.6,4,29.7,4z"/>
      <path class="svg-fill" d="M26.3,24.1c0.6,1,1.2,1.9,1.8,2.9l0.5,0.7c2,3,4,5.8,4,8.5c0,3.8-2.8,5.8-6.3,5.8
      S20,40,20,36.2c0-2.7,2-5.6,4-8.5l0.5-0.7C25.1,26,25.7,25.1,26.3,24.1z M23.5,32c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5
      s1.5-0.7,1.5-1.5S24.3,32,23.5,32z"/>
      <path class="svg-fill" d="M35,20c1.3,2.4,3.5,4.7,3.5,6.8S36.9,30,35,30s-3.5-1.1-3.5-3.2S33.7,22.4,35,20z
       M33.8,24c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8S34.2,24,33.8,24z"/>
    </svg>

    <svg v-if="graphic === 'warning-circuit'" version="1.1" viewBox="0 0 59.5 52">
      <polygon class="svg-fill" points="29.5,30 31.2,21.6 32.9,22.5 35.6,17.2 34.1,25.4 32.1,24.6 "/>
      <polygon class="svg-fill" points="28.1,26.8 25.1,18.9 27,18.7 26.4,12.7 29.5,20.5 27.4,20.9 "/>
      <path class="svg-fill" d="M22.8,37.2l3.4,3.6c-0.4,0.4-0.8,0.8-1.3,1.3l-0.5,0.5L24,43.2l-0.5,0.6
      c-0.1,0.1-0.2,0.2-0.2,0.3l-0.5,0.7l-0.5,0.7l-0.5,0.7L21.2,47l0,0H15l0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.2l0.6-0.9
      c0.1-0.2,0.2-0.3,0.3-0.5l0.6-0.9c0.2-0.3,0.4-0.6,0.6-0.8l0.6-0.8c0.2-0.3,0.4-0.5,0.6-0.8l0.6-0.7l0.6-0.7
      c0.2-0.2,0.4-0.4,0.5-0.6l0.5-0.6c0.6-0.7,1.2-1.3,1.7-1.8L22.8,37.2z M36.2,37.2l0.2,0.2c0.4,0.4,0.9,0.9,1.4,1.5l0.4,0.4l0.5,0.6
      c0.2,0.3,0.5,0.6,0.7,0.9l0.4,0.5l0.6,0.8l0.6,0.8l0.6,0.8l0.2,0.2l0,0l0.2,0.2l0.6,0.9l0.2,0.2l0,0l0.2,0.2l0.7,1L44,47h-6.1
      l-0.3-0.5L37,45.8l-0.1-0.2l0,0l-0.1-0.2l-0.5-0.7l-0.5-0.6l-0.5-0.6l-0.5-0.6l-0.5-0.5c-0.4-0.5-0.8-0.9-1.2-1.3l-0.3-0.3
      L36.2,37.2z M35.2,31.3l0.6,1.4c-1.5,0.7-3,1.5-4.5,2.4c1.1,0.8,2.2,1.6,3.3,2.6c-0.4,0.5-0.8,0.9-1,1.1c-1.4-1-2.6-1.9-3.8-2.7
      c-1.4,1-2.8,2-4.1,3.2c-0.4-0.4-0.5-0.5-0.5-0.6l-0.1,0c0,0,0,0,0,0l-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c1.2-1.1,2.5-2.1,3.8-3
      c-1.6-1-2.9-1.8-4.1-2.3l-0.3-0.1l0.6-1.4l0.5,0.2c1.6,0.7,3.2,1.6,4.7,2.6c1.5-1,3.1-1.8,4.7-2.6L35.2,31.3z"/>
      <path class="svg-fill" d="M29.7,0c-2.6,0-4.9,1.3-6.3,3.5L1,41c-1.3,2.3-1.3,5,0,7.3S4.6,52,7.2,52h45
      c2.6,0,5-1.4,6.3-3.7s1.3-5,0-7.3L36,3.5C34.7,1.3,32.3,0,29.7,0z M29.7,4c1.2,0,2.2,0.6,2.8,1.6l22.5,37.5c0.6,1,0.6,2.2,0,3.3
      S53.4,48,52.2,48h-45c-1.2,0-2.2-0.6-2.8-1.7s-0.6-2.3,0-3.3L26.9,5.6C27.5,4.6,28.6,4,29.7,4z"/>
    </svg>

    <svg v-if="graphic === 'warning-shock'" version="1.1" viewBox="0 0 59.5 52">
      <path class="svg-fill" d="M39.5,19l4.4,7.4l-6.5,10.4c-0.7-0.5-1.1-1-1.1-1.7c0-1,1.1-4.5,1.1-5.6
      c0-1-7.3-2.1-8.8-1.6s-6.7,6.2-7.8,6.8c-0.7,0.4-1.4,0.4-2.1,0l4.9-6.4c-2.2,1.2-3.6,2-4.3,2.6c-0.7,0.5-1.6,0.4-2.7-0.4l5.2-4.2
      c-1.3,0.2-2.3,0.2-3.1,0c-0.8-0.2-1.1-0.7-1-1.6c3.1,0,5.7-0.3,7.8-1c1.2-0.4,3-0.6,4.9-0.9l0.7-0.1c2.9-0.3,5.8-0.6,6.4-1.2
      c0.4-0.4,1-1.2,1.8-2.3L39.5,19L39.5,19z"/>
      <path class="svg-fill" d="M29.7,0c-2.6,0-4.9,1.3-6.3,3.5L1,41c-1.3,2.3-1.3,5,0,7.3S4.6,52,7.2,52h45
      c2.6,0,5-1.4,6.3-3.7s1.3-5,0-7.3L36,3.5C34.7,1.3,32.3,0,29.7,0z M29.7,4c1.2,0,2.2,0.6,2.8,1.6l22.5,37.5c0.6,1,0.6,2.2,0,3.3
      S53.4,48,52.2,48h-45c-1.2,0-2.2-0.6-2.8-1.7s-0.6-2.3,0-3.3L26.9,5.6C27.5,4.6,28.6,4,29.7,4z"/>
      <polygon class="svg-fill" points="29.8,30.8 27.4,38.9 25.7,37.9 22.6,43 24.8,34.9 26.8,35.9 "/>
      <polygon class="svg-fill" points="32.1,32.2 34.4,40.3 32.5,40.4 32.5,46.3 30.1,38.3 32.3,38.1 "/>
    </svg>

  </i>
</template>

<script>
export default {
  name: 'icon',
  props: {
    graphic: String,
  }
}
</script>

<style lang="scss" scoped>
  .icon {
    display: flex;
    >svg {
      flex: auto;
    }
  }
  .svg-stroke {
    fill: none;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 2;
    stroke-linejoin: round;
    vector-effect: non-scaling-stroke;
    &.-thin {
      stroke-width: 1;
    }
  }
  .icon {
    &.course,
    &.parts,
    &.troubleshooting,
    &.instructions {
      .svg-stroke {
        stroke-width: 2.5;
        stroke: var(--color-blue-2);
      }
      .svg-fill {
        fill: var(--color-blue-2);
      }
    }
  }
  // .arrow {
  //   width: 1.49rem;
  //   height: 1.14rem;
  // }
  .app-hamburger {
    .svg-stroke {
      // stroke-width: 2px;
    }
  }
  .arrow {
    width: 1.49rem;
    height: 1.14rem;
  }
  .bookmark {
    width: 2.38rem;
    height: 3rem;
  }
  .calendar {
    width: 1.4rem;
    height: 1.5rem;
    .svg-stroke {
      stroke-width: 1;
    }
  }
  .checkmark {
    width: 2rem;
    height: 2rem;
  }
  .chevron {
    --icon-width: 1.38rem;
    width: var(--icon-width);
    // height: 2.51rem;
    > svg {
      overflow: visible;
      width: var(--icon-width);
      height: calc(var(--icon-width) * var(--height-multiplier));
    }
    .svg-stroke {
       // stroke: #EBEBEB;
      stroke-width: 2.5;
    }
    &.-left {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .course {
    width: 6.01rem;
  }
  .course-hamburger {
    width: 2.71rem;
    height: 2rem;
    .svg-stroke {
      stroke: var(--color-white);
    }
    .svg-fill {
      fill: var(--color-white);
    }
  }
  .eye {
    width: 1.75rem;
    height: 1.35rem;
  }
  .home {
    width: 1.65rem;
    height: 1.82rem;
  }
  .instructions {
    width: 5.4rem;
  }
  .lampshade {
    width: 2.32rem;
    height: 2.16rem;
  }
  .lesson {
    width: 2.36rem;
    height: 3.06rem;
  }
  .lightbulb {
    width: 1.7rem;
    height: 2.6rem;
  }
  .no-light {
    width: 2.4rem;
    height: 2.6rem;
  }
  .powercord {
    width: 2.08rem;
    height: 1.97rem;
  }
  .parts {
    width: 4.45rem;
  }
  .quiz {
    width: 3.2rem;
    height: 2.84rem;
    .svg-stroke {
      stroke: var(--color-blue-2);
    }
    .svg-fill {
      fill: var(--color-white);
    }
  }
  .quiz-excellent,
  .quiz-fail,
  .quiz-good {
    width: 12.2rem;
    height: 12.2rem;
    .svg-stroke {
      stroke-width: 7;
      &.-thick {
        stroke-width: 12;
      }
    }
  }

  .no-charging {
    width: 2.4rem;
    height: 1.4rem;
    // 24 14
  }
  .low-light {
    width: 2.7rem;
    height: 3.02rem;
    // 27 30.2
  }
  .power-button {
    width: 2rem;
    height: 2.2rem;
    // 20 22
  }
  .power-button-broken {
    width: 2.5rem;
    height: 2.2rem;
    // 25 22
  }
  .runtime {
    width: 2.2rem;
    height: 2.2rem;
  }
  // .quiz-excellent {
  //   width: 12.3rem;
  //   height: 12.3rem;
  // }
  // .quiz-fail {
  //   width: 12.2rem;
  //   height: 12.2rem;
  // }
  .quiz-marker {
    width: 2rem;
    .svg-stroke {
      stroke: var(--color-light-grey-1);
    }
  }
  .quiz-overview {
    --icon-width: 15.55rem;
    width: var(--icon-width);
    // height: 2.51rem;
    > svg {
      height: calc(var(--icon-width) * var(--height-multiplier));
    }
    .svg-stroke {
      stroke: var(--color-blue-1);
      stroke-width: 7;
    }
  }
  .repeat {
    width: 1.4rem;
    height: 1.75rem;
  }
  .screw {
    width: 1.7rem;
    height: 2.19rem;
  }
  .screwdriver {
    width: 4.4rem;
    height: 1.7rem;
  }
  .sun {
    width: 2.4rem;
    height: 2.4rem;
  }
  .switch {
    width: 2.4rem;
    height: 1.6rem;
  }
  .troubleshooting {
    width: 4.5rem;
  }
  .user {
    width: 1.8rem;
  }

  [class^="tool-"], i[class*=" tool-"] {
    width: 4.8rem;
    height: 4.8rem;
  }

  [class^="warning-"], i[class*=" warning-"] {
    width: 5.95rem;
    height: 5.2rem;
  }

  .warning-fire {
    // 59.5 52
  }
</style>

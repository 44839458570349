export default {
  data: function() {
    return {}
  },
  methods: {
    easeInOutQuad: function(t, b, c, d) {
      t /= d / 2;
      if (t < 1) {
        return c / 2 * t * t + b;
      }
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    },
    animateScroll: function(element, to, duration) {
      var that = this;
      var start = element.scrollTop;
      var change = to - start;
      var currentTime = 0;
      var increment = 20;

      var animateTheScroll = function() {
        currentTime += increment;
        var val = that.easeInOutQuad(currentTime, start, change, duration);

        element.scrollTop = val;
        if (currentTime < duration) {
          // console.log(currentTime);
          setTimeout(animateTheScroll, increment);
        }
      };
      animateTheScroll();
    }
  },
}

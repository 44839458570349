import localforage from 'localforage'
export default {
  actions: {
    arrayBufferToBlob: function(payload) {
      // buffer, type
      return new Blob([payload.buffer], { type: payload.type });
    },

    // blobToArrayBuffer: function(blob) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.addEventListener('loadend', (e) => {
    //       resolve(reader.result);
    //     });
    //     reader.addEventListener('error', reject);
    //     reader.readAsArrayBuffer(blob);
    //   });
    // },

    blobToArrayBuffer: function(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        }
        reader.onerror = reject;
        // reader.addEventListener('loadend', (e) => {
        //   resolve(reader.result);
        // });
        // reader.addEventListener('error', reject);
        reader.readAsArrayBuffer(blob);
      });
    },

    serializeHeaders: function({ state }, headers) {
      var serialized = {};
      for (var entry of headers.entries()) {
        serialized[entry[0]] = entry[1];
      }
      return serialized;
    },

    serializeResponse: function({ dispatch }, response) {
      var serialized = {
        headers: dispatch('serializeHeaders', response.headers),
        status: response.status,
        statusText: response.statusText
      };

      return new Promise((resolve, reject) => {
        response.clone().text().then(body => {
          serialized.body = body;
          resolve(serialized)
        })
      })
    },

    deserializeResponse: function({ state }, data) {
      return new Promise((resolve, reject) => {
        var init = {
          headers: data.headers
        }
        var res = new Response(data.body, init);
        resolve(res);
      });
    },

    parseUrl: function({ state }, url) {
      console.log('parse this', url);

      var sTheFileNamePattern = '[^\/]+(?=\/$|$)';
      // var sTheExtensionPattern = '[^\.]+(?=\/$|$)';

      var sTheFileName;
      var sTheExtension;
      var sTheHash = null;
      var aTheFileNameMatches = url.match(sTheFileNamePattern);
      var sTheFileUrl = url;

      if (aTheFileNameMatches && aTheFileNameMatches.length) {
        sTheFileName = aTheFileNameMatches[0];

        // console.log('DEBUG CACHING AGAIN');
        // console.log(sTheFileName);

        var aTheFileName = sTheFileName.split('.');

        // console.log('DEBUG CACHING AGAIN', aTheFileName);

        if (aTheFileName.length > 1) {

          // if url has a hash string, isolate it, to avoid storing duplicates.
          // the url, sans hash, will be used for a key in indexedDB
          if (aTheFileName.length > 2) {
            sTheHash = aTheFileName[aTheFileName.length - 2];
            sTheFileName = sTheFileName.replace('.' + sTheHash, '');
            sTheFileUrl = sTheFileUrl.replace('.' + sTheHash, '');
          }

          sTheExtension = aTheFileName.slice(-1).join();

          sTheExtension = sTheExtension.replace(/\/|#\//g, '');
          sTheFileName = sTheFileName.replace(/\/|#\//g, '');
          sTheFileUrl = sTheFileUrl.replace('#/', '');

          var ret = {
            name: sTheFileName,
            extension: sTheExtension,
            hash: sTheHash,
            key: sTheFileUrl,
          };

          return ret;
        } else {
          return false
        }
      }
    },

    storeItemInDB: function({ state }, payload) {
      var parsed = payload.parsed;
      var url = payload.url;
      var data = payload.buffer;
      // dispatch('storeItemInDB', { parsed: parsed, url: url, buffer: buffer });
      return new Promise((resolve, reject) => {
        // console.log('storeItemInDB promise', parsed);
        return localforage.setItem('resource_' + parsed.key, {
          name: parsed.name,
          extension: parsed.extension,
          // hash: parsed.hash,
          // revision: parsed.revision,
          key: parsed.key,
          url: url,
          data: data
        }).then((result) => {
          return localforage.getItem('resource_' + parsed.key).then(thing => {
            console.log('stored with localforage ', thing);
            return resolve(thing);
          }).catch(err => {
            console.log('fant ikke tingen jeg angivelig nettopp lagret, ', err);
            reject(new Error(err));
          })
        }).catch(error => {
          console.log('error in adding media, ', error);
          reject(new Error(error))
        });
      });
    },

    responseToBlob: function({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        payload.blob().then(res => {
          return resolve(res)
        })
      })
    },

    saveMediaToDB: function({ dispatch }, payload) {
      // parsed, url, response
      return new Promise((resolve, reject) => {
        payload.response.clone().blob()
          .then(blob => {
            return blob.arrayBuffer()
          }).then(buffer => {
            dispatch('storeItemInDB', { parsed: payload.parsed, url: payload.url, buffer: buffer }).then(result => {
              // console.log('storeItemInDB result', result);
              return resolve(result)
            })
          }).catch(err => {
            console.log(err);
            reject(new Error(err));
          })
        // var blob = new Promise((resolve, reject) => {
        //   var oTheBlob = payload.response.clone().blob();
        //   resolve(oTheBlob);
        // });
        // dispatch('responseToBlob', payload.response.clone()).then(blob => {
        // payload.response.clone().blob().then(blob => {
        //   console.log('blobbbb', blob);
        //   dispatch('blobToArrayBuffer', blob).then(buffer => {
        //     dispatch('storeItemInDB', { parsed: payload.parsed, url: payload.url, buffer: buffer }).then(result => {
        //       console.log('storeItemInDB result', result);
        //       return resolve(result)
        //     })
        //   })
        // })
      })
      // console.log('saveMediaToDB', payload.response.clone());
      // payload.response.clone().blob().then(blob => {
      //   // console.log('saveMediaToDB', JSON.parse(JSON.stringify(blob)));
      //   dispatch('blobToArrayBuffer', blob).then(buffer => {
      //     dispatch('storeItemInDB', { parsed: payload.parsed, url: payload.url, buffer: buffer }).then(result => {
      //       console.log('storeItemInDB result', result);
      //     })
      //   })
      // })
    },

    saveToDB: function({ dispatch }, payload) {
      // url, response, revision = null
      // console.log('save this ', url, ' ', response);
      console.log('saveToDB', payload);
      return new Promise((resolve, reject) => {
        dispatch('parseUrl', payload.url).then(oTheParsedUrl => {
          oTheParsedUrl.revision = payload.revision;
          console.log('saveToDB', oTheParsedUrl);
          // console.log('PARSED URL ', oTheParsedUrl);
          if (oTheParsedUrl) {
            switch (oTheParsedUrl.extension) {
              case 'mp4':
                // break;
              case 'png':
              case 'jpg':
              case 'jpeg':
                // console.log('saveToDB saveMediaToDB', oTheParsedUrl);
                dispatch('saveMediaToDB', { parsed: oTheParsedUrl, url: payload.url, response: payload.response });
                break;
              case 'ttf':
                // saveFontToDB(oTheParsedUrl, url, response);
              default:
                // saveDocumentToDB(oTheParsedUrl, url, response);
            }
            return resolve(payload.response);
          }
        });
        reject(payload.response);
      })
    },

    loadFromDB: function({ dispatch }, request) {
      var url = request.url;
      var oTheParsedUrl = dispatch('parseUrl', url);
      // console.log('loadFromDB, ', url);
      return new Promise((resolve, reject) => {
        localforage.getItem('resource_' + oTheParsedUrl.key).then(document => {
        // db.collection('resources').doc({ key: oTheParsedUrl.key }).get().then(document => {
          if (document) {
            // console.log('loadFromDB', oTheParsedUrl.hash, document.hash);
            switch (oTheParsedUrl.extension) {
              case 'mp4':
                resolve(new Response(dispatch('arrayBufferToBlob', { buffer: document.data, type: 'video/mp4' })))
                break;

              case 'png':
              case 'jpg':
              case 'jpeg':
                resolve(new Response(dispatch('arrayBufferToBlob', { buffer: document.data, type: 'image/' + oTheParsedUrl.extension })))
                break;

              case 'ttf':
                resolve(new Response(dispatch('arrayBufferToBlob', { buffer: document.data, type: 'application/font-ttf' })))

              default:
                if (document.data) {
                  return dispatch('deserializeResponse', document.data).then(result => {
                    resolve(result);
                  })
                }
            }
            // reject(url);
          } else {
            resolve(null);
          }
          // reject(url);
        }).catch(error => {
          console.log('fant ingenting ', error);
          reject(error);
        })
      });
    }
  }
}

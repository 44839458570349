<template>
  <img :src="mediaSource" :alt="altAttr" class="img" :class="{ '-loading': !mediaSource }"><!-- :key="'media_' + url" -->
</template>

<script type="text/javascript">
export default {
  name: 'ImageComponent',
  data: function() {
    return {
      localPath: 'https://repair.bright-products.com/',
      resourceLoaded: false,
      spinnerActive: false,
    }
  },
  props: {
    url: String,
    altAttr: String,
  },
  computed: {
    mediaSource: function() {
      // TODO: dropp dette tullet når vi flytter over til WP
      // og fiks fallback

      // var sTheLocalPath = 'https://repair.bright-products.com/';
      var sTheLocalPath = '';
      var key = sTheLocalPath + this.url;
      var srcObj = this.$store.getters.getImageByUrl(this.url);
      if (srcObj) {
        return srcObj.url
      } else {
        return false
      }
      // if (srcObj) {
      //   return srcObj.url
      // } else if (this.url) {
      //   return this.localPath + this.url
      // } else {
      //   return false
      // }
    },
    spinner: function() {
      return this.$store.getters.getSpinner
    }
  },
  created() {
    if (this.spinner < 1) {
      this.spinnerActive = true;
      // this.$store.dispatch('setSpinner', this.spinner + 1);
    }
  },
  mounted() {
    // console.log('spinner', this.spinner);
    // console.log('imagecomponent mounted');
    // this.$store.dispatch('setSpinner', this.spinner + 1);
    this.$nextTick(() => {
      // console.log('NEXTTICK');
      // this.resourceLoaded = true;
      // console.log('loaded. spinner: ', this.spinner);

    })
  },
  beforeUpdate() {
    // this.$store.dispatch('setSpinner', this.spinner + 1);
  },
  updated() {
    this.$nextTick(() => {
      if (this.mediaSource) {
        // this.$store.dispatch('setSpinner', this.spinner - 1);
        // this.spinnerActive = false;
        this.resourceLoaded = true;
        this.$emit('image_loaded', this.url);
      }
    })
  }
}
</script>

<style lang="scss">
.img {
  opacity: 1;
  transition: opacity .3s ease 0s;
  // &.-loading {
  //   opacity: 0;
  // }
}
</style>

<template lang="html">
  <router-link class="product-section-link course-link"
  :to="{ name: 'LessonOverview', params: { productID: productid, variantID: variantid, courseID: courseid } }">

    <div class="image-column">
      <div class="image-container">
        <icon graphic="course"></icon>
      </div>
    </div>

    <div class="text-column">

      <div class="inner">
        <icon graphic="bookmark" v-if="getCourseStatus(course.id)"></icon>
        <h3 class="title">{{ _(course.title) }}</h3>
        <p class="description">{{ _(course.description) }}</p>
        <div class="progress">

          <span class="lesson"
          v-for="id in course.lessons"
          :class="{ '-completed': getLessonStatus(course.id, id) }"
          :key="'course_' + course.id + 'lesson_marker_' + id">
            <icon v-if="getLessonStatus(course.id, id)" class="marker" graphic="checkmark"></icon>
            <i v-else class="icon marker"></i>
          </span>

          <span class="quiz"
          v-for="id in course.quizes"
          :class="{ '-completed': getQuizStatus(course.id, id) }"
          :key="'course_' + course.id + 'lesson_marker_' + id">
            <icon class="marker" graphic="quiz-marker"></icon>
          </span>

        </div>
      </div>

      <div class="item-icon">
        <icon graphic="chevron"></icon>
      </div>

    </div>

  </router-link>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
export default {
  name: 'CourseLink',
  props: {
    productid: [String, Number],
    variantid: [String, Number],
    courseid: [String, Number],
  },
  mixins: [TranslationMixin],
  computed: {
    product: function() {
      return this.$store.getters.getProductByID(this.productid)
    },
    variant: function() {
      return this.$store.getters.getVariantByID(this.variantid)
    },
    course: function() {
      console.log('couse link ', this.courseid);
      return this.$store.getters.getCourseByID(this.courseid)
    },
  },
  methods: {
    getLessonStatus: function(courseID, lessonID) {
      return this.$store.getters.getLessonStatus(courseID, lessonID);
    },
    getQuizStatus: function(courseID, lessonID) {
      return this.$store.getters.getQuizStatus(courseID, lessonID);
    },
    getCourseStatus: function(id) {
      console.log(this.$store.getters.getCourseStatus(id));
      return this.$store.getters.getCourseStatus(id);
    }
  },
  mounted() {
    console.log(this.courseid);
    console.log(this.$store.getters.getCourseByID(this.courseid));
  }
}
</script>

<style lang="scss">
</style>

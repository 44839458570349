export default {
  data: function() {
    return {
      xDown: null,
      yDown: null,
      swipeGesture: '',
    }
  },
  methods: {
    initSwipe: function() {
      document.addEventListener('touchstart', this.handleTouchStart, false);
      document.addEventListener('touchmove', this.handleTouchMove, false);
      document.addEventListener('touchend', this.handleTouchEnd, false);
    },
    getTouches(evt) {
      return evt.touches || evt.originalEvent.touches;
    },
    handleTouchStart(evt) {
      const firstTouch = this.getTouches(evt)[0];
      this.xDown = firstTouch.clientX;
      this.yDown = firstTouch.clientY;
    },
    handleTouchEnd(evt) {
      setTimeout(() => {
        this.$store.dispatch('setSwipeGesture', 'none');
      }, 250);
    },
    handleTouchMove(evt) {
      if (!this.xDown || !this.yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = this.xDown - xUp;
      var yDiff = this.yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          this.swipeGesture = 'left';
          /* right swipe */
        } else {
          this.swipeGesture = 'right';
          /* left swipe */
        }
      } else {
        if (yDiff > 0) {
          /* down swipe */
          this.swipeGesture = 'up';
        } else {
          /* up swipe */
          this.swipeGesture = 'down';
        }
      }
      this.$store.dispatch('setSwipeGesture', this.swipeGesture);
      /* reset values */
      this.xDown = null;
      this.yDown = null;
    }
  }
}

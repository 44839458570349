<template>
  <!-- <vue-plyr> -->
  <video class="video-element" :src="mediaSource" :controls="controls" :poster="posterSource"></video>
  <!-- </vue-plyr> -->
</template>

<script type="text/javascript">
export default {
  name: 'VideoComponent',
  data: function() {
    return {
      localPath: 'https://repair.bright-products.com/'
    }
  },
  props: {
    url: String,
    controls: String,
    posterImage: String,
  },
  computed: {
    mediaSource: function() {
      // TODO: dropp dette tullet når vi flytter over til WP
      // og fiks fallback
      // var key = this.localPath + this.url;
      var key = this.url;
      var srcObj = this.$store.getters.getVideoByUrl(key);
      if (srcObj) {
        return srcObj.url
      } else {
        return this.url
      }
    },
    posterSource: function() {
      if (this.posterImage) {
        // var key = this.localPath + this.url;
        var srcObj = this.$store.getters.getImageByUrl(this.posterImage);
        if (srcObj) {
          return srcObj.url
        } else {
          return this.posterImage
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
  .video-element {
    width: 100%;
    border-radius: .4rem;
  }
  .plyr {
    // --plyr-color-main: magenta;
  }
</style>
